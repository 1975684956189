import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import rightArrow from '../../assets/rightArrow.png';

import PhotoReviewFrame from '../../components/PhotoReviewFrame/PhotoReviewFrame';
import styles from './Review.module.scss';
import leftArrow02 from '../../assets/leftArrow02.png';
import rightArrow02 from '../../assets/rightArrow02.png';
import NormalReviewFrame from '../../components/NormalReviewFrame/NormalReviewFrame';
import axiosClient from '../../libs/axiosClient';
import { useParams } from 'react-router-dom';
import { IReviewDataType } from '../MyReview/MyReview';
import ReviewDetailModal from '../../components/ReviewDetailModal/ReviewDetailModal';
import WriteReviewModal from '../../components/WriteReviewModal/WriteReviewModal';
import { isDesktop } from 'react-device-detect';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
function Review() {
  //포토리뷰
  const [paginationList, setPaginationList] = useState([1, 2, 3, 4, 5]);
  const [pagination, setPagination] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [photoReviews, setPhotoReviews] = useState<IReviewDataType[]>([]);
  const [photoReviewCount, setPhotoReviewCount] = useState(0);
  //포토리뷰
  //일반리뷰
  const [paginationList2, setPaginationList2] = useState([1, 2, 3, 4, 5]);
  const [pagination2, setPagination2] = useState(1);
  const [isLoading2, setIsLoading2] = useState(false);
  const [normalReviews, setNormalReviews] = useState<IReviewDataType[]>([]);
  const [normalReviewCount, setNormalReviewCount] = useState(0);
  //일반리뷰
  const [reviewDetailData, setReviewDetailData] = useState<IReviewDataType>();
  const [reviewDetailOpen, setReviewDetailOpen] = useState(false);
  const params = useParams();
  //포토리뷰
  function getPhotoReview() {
    setIsLoading(true);
    let reviewPerView = isDesktop ? 9 : 6;
    let productId = `product_id=${params.productId}&`;
    if (productId == 'product_id=all&') productId = '';
    axiosClient
      .get(
        `/data/product_talk/photo-review-list?${productId}offset=${
          (pagination - 1) * reviewPerView
        }&limit=${reviewPerView}&talk_type=상품평가&order_by=order`,
      )
      .then((res) => {
        setPhotoReviews(res.data.data);
        setPhotoReviewCount(res.data.total_count);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getPhotoReview();
  }, [pagination]);
  useEffect(() => {
    let list: number[] = [];
    let reviewPerView = isDesktop ? 9 : 6;
    let startPage = parseInt(String(pagination / 5)) * 5 + 1;
    if (!(pagination % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      photoReviewCount / reviewPerView +
        (photoReviewCount % reviewPerView !== 0 ? 1 : 0)
        ? startPage + 4
        : photoReviewCount / reviewPerView +
          (photoReviewCount % reviewPerView !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    setPaginationList([...list]);
  }, [photoReviewCount, pagination]);
  function handlePaginationDown() {
    if (pagination == 1) return;
    setPagination((prev) => prev - 1);
  }
  function handlePaginationUp() {
    if (pagination >= photoReviewCount / (isDesktop ? 9 : 6)) return;
    setPagination((prev) => prev + 1);
  }
  //포토리뷰

  //일반리뷰
  function getNormalReview() {
    setIsLoading2(true);
    let productId = `product_id=${params.productId}&`;
    if (productId == 'product_id=all&') productId = '';
    let reviewPerView = isDesktop ? 5 : 3;

    axiosClient
      .get(
        `/data/product_talk/list?${productId}offset=${
          (pagination2 - 1) * reviewPerView
        }&limit=${reviewPerView}&talk_type=상품평가`,
      )
      .then((res) => {
        setNormalReviews(res.data.data);
        setNormalReviewCount(res.data.total_count);
        setIsLoading2(false);
      });
  }
  useEffect(() => {
    getNormalReview();
  }, [pagination2]);
  useEffect(() => {
    let list: number[] = [];
    let limit = 5;
    let startPage = parseInt(String(pagination2 / 5)) * 5 + 1;
    if (!(pagination2 % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      normalReviewCount / limit + (normalReviewCount % limit !== 0 ? 1 : 0)
        ? startPage + 4
        : normalReviewCount / limit + (normalReviewCount % limit !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    setPaginationList2([...list]);
  }, [normalReviewCount, pagination2]);
  function handlePaginationDown2() {
    if (pagination2 == 1) return;
    setPagination2((prev) => prev - 1);
  }
  function handlePaginationUp2() {
    if (pagination2 >= normalReviewCount / (isDesktop ? 5 : 3)) return;
    setPagination2((prev) => prev + 1);
  }
  //일반리뷰

  //리뷰작성모달
  const [writeReviewOpen, setWriteReviewOpen] = useState(false);
  //리뷰작성모달

  return (
    <div>
      <LoginHandler />
      <Header />
      <h3 className={styles.title}>
        <span>상품후기</span>
        <span>고객님들의 소중한 후기입니다.</span>
      </h3>
      <div className={styles.writeReviewWrap}>
        {!isDesktop && (
          <div
            className={styles.mobileWriteReviewBtn}
            onClick={() => setWriteReviewOpen(true)}
          >
            리뷰 작성하기
          </div>
        )}
        {isDesktop && (
          <div
            className={styles.writeReviewBtn}
            onClick={() => setWriteReviewOpen(true)}
          >
            리뷰 작성하기
          </div>
        )}
      </div>
      <div className={styles.photoReviewSection}>
        <h4>
          <span>포토리뷰</span>
          <span>({photoReviewCount})</span>
        </h4>
        <ul className={styles.photoReviewListWrap}>
          {photoReviews.map((review) => (
            <li
              key={review.id}
              onClick={() => {
                setReviewDetailOpen(true);
                setReviewDetailData(review);
              }}
            >
              <PhotoReviewFrame type="board" review={review} />
            </li>
          ))}
        </ul>
        <div className={styles.paginationWrap}>
          <div className={styles.pagination}>
            <img src={leftArrow02} alt="arrow" onClick={handlePaginationDown} />
            <div className={styles.number}>
              {paginationList.map((number) => (
                <span
                  key={number}
                  className={number == pagination ? styles.hit : ''}
                  onClick={() => setPagination(number)}
                >
                  {number}
                </span>
              ))}
            </div>
            <img src={rightArrow02} alt="arrow" onClick={handlePaginationUp} />
          </div>
        </div>
      </div>
      <div className={styles.normalReviewSection}>
        <div className={styles.head}>
          <span>전체 리뷰 ({normalReviewCount})</span>
          {/* <div className={styles.moreBtn}>
            <span>더보기</span>
            <img src={rightArrow} alt="arrow" />
          </div> */}
        </div>
        <ul className={styles.normalReviewList}>
          {normalReviews.map((review) => (
            <li key={review.id}>
              <NormalReviewFrame review={review} />
            </li>
          ))}
        </ul>

        <div className={styles.paginationAndBtn}>
          <div className={styles.pagination}>
            <img
              src={leftArrow02}
              alt="arrow"
              onClick={handlePaginationDown2}
            />
            <div className={styles.number}>
              {paginationList2.map((number) => (
                <span
                  key={number}
                  className={number == pagination2 ? styles.hit : ''}
                  onClick={() => setPagination2(number)}
                >
                  {number}
                </span>
              ))}
            </div>
            <img src={rightArrow02} alt="arrow" onClick={handlePaginationUp2} />
          </div>
        </div>
      </div>
      {reviewDetailOpen && (
        <ReviewDetailModal
          closer={setReviewDetailOpen}
          imageList={reviewDetailData!.images}
          userId={reviewDetailData!.member_id}
          age={reviewDetailData!.age}
          gender={reviewDetailData!.gender}
          weight={reviewDetailData!.weight}
          score={
            reviewDetailData!.stars0 +
            reviewDetailData!.stars1 +
            reviewDetailData!.stars2 +
            reviewDetailData!.stars3
          }
          content={reviewDetailData!.content}
          productImage={reviewDetailData!.product.list_image}
        />
      )}
      {writeReviewOpen && <WriteReviewModal closer={setWriteReviewOpen} />}
      <Footer />
    </div>
  );
}
export default Review;
