import styles from './MyOrder.module.scss';
import leftArrow from '../../assets/leftArrow02.png';
import rightArrow3 from '../../assets/rightArrow03.png';
import exclamation from '../../assets/exclamation.png';
import bottomArrow02 from '../../assets/bottomArrow02.png';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import OrderFrame from '../../components/OrderFrame/OrderFrame';
import { IOrderDataType } from '../OrderComplete/OrderComplete';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { IMyPageInfoType } from '../MyPage/MyPage';
import axiosClient from '../../libs/axiosClient';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
const dateList = ['전체', '최근 1개월', '최근 3개월', '최근 6개월'];
function MyOrder() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDateIdx, setSelectedDateIdx] = useState(0);
  const [guideOpen, setGuideOpen] = useState(false);
  const [orderData, setOrderData] = useState<IOrderDataType[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  function handleDateChange(idx: number) {
    setSelectedDateIdx(idx);
    let now = new Date();
    let startDate: Date;
    if (idx == 0) startDate = new Date(now.setFullYear(now.getFullYear() - 1));
    else if (idx == 1) startDate = new Date(now.setMonth(now.getMonth() - 1));
    else if (idx == 2) startDate = new Date(now.setMonth(now.getMonth() - 3));
    else startDate = new Date(now.setMonth(now.getMonth() - 6));
    setStartDate(startDate);
    setEndDate(new Date());
  }
  useEffect(() => {
    let now = new Date();
    setStartDate(new Date(now.setFullYear(now.getFullYear() - 1)));
  }, []);
  const [myPageInfo, setMyPageInfo] = useState<IMyPageInfoType>();
  useEffect(() => {
    axiosClient.get('/data/mypage').then((res) => {
      setMyPageInfo(res.data.data);
    });
  }, []);
  useEffect(() => {
    axiosClient
      .get(`/data/order?offset=${(pagination - 1) * 10}&limit=10`)
      .then((res) => {
        setOrderData(res.data.data);
        setTotalCount(res.data.total_count);
      });
  }, []);

  const loadMoreData = () => {
    if (loading || !hasMore) {
      return;
    }
    setLoading(true);
    let query = '';
    if (start) query = `started_date=${start}&ended_date=${end}`;
    else query = '';
    axiosClient
      .get(`/data/order?offset=${(pagination - 1) * 10}&limit=10&${query}`)
      .then((res) => {
        const newData = res.data.data;
        const existOrderNum = new Set(orderData.map((order) => order.order_id));
        const filterData = newData.filter(
          (order: any) => !existOrderNum.has(order.order_id),
        );
        setOrderData([...orderData, ...filterData]);
        setTotalCount(res.data.total_count);
        setPagination(pagination + 1);
        setLoading(false);
        if (newData.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
          document.documentElement.offsetHeight ||
        loading
      ) {
        return;
      }
      loadMoreData();
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, start, end]);

  function getOrderInfo() {
    let newStartDate =
      startDate.getFullYear() +
      '-' +
      ('0' + (startDate.getMonth() + 1)).substr(-2) +
      '-' +
      ('0' + startDate.getDate()).substr(-2);
    let newEndDate =
      endDate.getFullYear() +
      '-' +
      ('0' + (endDate.getMonth() + 1)).substr(-2) +
      '-' +
      ('0' + endDate.getDate()).substr(-2);
    axiosClient
      .get(`/data/order?started_date=${newStartDate}&ended_date=${newEndDate}`)
      .then((res) => {
        setOrderData(res.data.data);
        setTotalCount(res.data.total_count);
        setHasMore(true);
        setPagination(2);
        setStart(newStartDate);
        setEnd(newEndDate);
      });
  }
  return (
    <div className={`${styles.container} ${!isMobile ? styles.isPC : ''}`}>
      <LoginHandler />
      <Header />
      {isMobile ? (
        <div className={styles.header} onClick={() => navigate(-1)}>
          <img src={leftArrow} alt="arrow" />
          <span>주문 및 배송</span>
        </div>
      ) : (
        <div className={styles.pcHeader}>
          <span>주문 및 배송</span>
        </div>
      )}
      <div className={styles.orderStatus}>
        <h3>진행중인 주문</h3>
        <div className={styles.statusList}>
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.결제대기 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.결제대기}</span>
            <span>결제대기</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.결제완료 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.결제완료}</span>
            <span>결제완료</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송준비 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송준비}</span>
            <span>한약조제중</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송중 > 0 ? styles.hit : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송중}</span>
            <span>배송중</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송완료 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송완료}</span>
            <span>배송완료</span>
          </div>
        </div>
      </div>
      <div className={styles.orderDateForm}>
        <ul className={`${styles.dateList} ${isMobile ? styles.isMobile : ''}`}>
          {dateList.map((date, idx) => (
            <li
              key={date}
              className={idx == selectedDateIdx ? styles.hit : ''}
              onClick={() => handleDateChange(idx)}
            >
              {date}
            </li>
          ))}
        </ul>
        <div className={styles.dateChangeWrap}>
          <div className={styles.startDate}>
            <DatePicker
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              locale={ko}
              dateFormat="yyyy년 MM월 dd일"
              onChange={(date: Date) => setStartDate(date)}
            />
          </div>
          <span>~</span>
          <div className={styles.endDate}>
            <DatePicker
              selected={endDate}
              selectsEnd
              startDate={startDate}
              locale={ko}
              dateFormat="yyyy년 MM월 dd일"
              onChange={(date: Date) => setEndDate(date)}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
          <div className={styles.dateSubmit} onClick={getOrderInfo}>
            기간조회
          </div>
        </div>
        <div className={styles.text}>
          <span>최근 12개월 의 주문내역을 조회하실 수 있습니다.</span>
        </div>
      </div>
      <ul
        className={`${styles.orderListWrap} ${isMobile ? styles.isMobile : ''}`}
      >
        {orderData.map((order) => (
          <li key={order.order_id}>
            <OrderFrame orderData={order} />
          </li>
        ))}
        {loading && <li className={styles.loadWrap}>Loading</li>}
      </ul>
      {isMobile && (
        <div className={styles.orderGuide}>
          <div
            className={styles.head}
            onClick={() => setGuideOpen((prev) => !prev)}
          >
            <img src={exclamation} alt="exclamation" />
            <span>주문 /배송조회 안내</span>
            <img
              src={bottomArrow02}
              alt="arrow"
              className={guideOpen ? styles.isOpen : ''}
            />
          </div>
          <div
            className={`${styles.content} ${guideOpen ? styles.isOpen : ''}`}
          >
            <div>
              <span>최근 12개월 의 주문내역 조회가 가능합니다.</span>
              <span>상세한 주문내역은 해당 주문 상세보기를 선택 해주세요.</span>
            </div>
            <ul>
              <li>
                <h5>결제대기</h5>
                <span>고객님의 주문한 상품의 결제가 이루어지 않은 단계</span>
              </li>
              <li>
                <h5>결제완료</h5>
                <span>결제 및 입금이 완료</span>
              </li>
              <li>
                <h5>한약조제중</h5>
                <span>주문건의 상품을 발송하기 위한 상품을 준비 상태</span>
                <span>현 단계에서의 상품 취소/교환/배송지변경 요청 가능</span>
              </li>
              <li>
                <h5>배송중</h5>
                <span>
                  판매자 측에서 상품을 택배사로 전달 완료된 상태입니다.
                </span>
                <span>현 단계에서의 상품 취소/교환/배송지변경 불가능</span>
              </li>
              <li>
                <h5>배송완료</h5>
                <span>택배사에서 고객님께 전달 완료</span>
                <span>배송완료 후 7일까지 반품/교환 신청가능</span>
              </li>
            </ul>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
export default MyOrder;
