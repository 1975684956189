import axiosClient from '../libs/axiosClient';
export interface ITokenInfoType {
  email: string;
  exp: number;
  id: number;
  name: string;
  nickname: string;
  phone: string;
  profile: string;
  role: number;
  user_type: string;
  member_id: string;
}
export function loginCheck() {
  const token = localStorage.getItem('token');
  const tokenExpire = localStorage.getItem('token-expire');
  if (
    token == null ||
    token == 'null' ||
    token == '' ||
    (tokenExpire && new Date().getTime() >= new Date(tokenExpire).getTime())
  )
    return false;
  else return true;
}
export function refreshTokenCheck() {
  const refreshToken = localStorage.getItem('refresh-token');
  const refreshTokenExpire = localStorage.getItem('refresh-token-expire');
  if (
    !refreshToken ||
    refreshToken == 'null' ||
    (refreshTokenExpire &&
      new Date().getTime() >= new Date(refreshTokenExpire).getTime())
  )
    return false;
  else return true;
}

export const STORAGE_ITEMS = {
  TOKEN: 'token',
  TOKENEXPIRE: 'token-expire',
  RTOKEN: 'refresh-token',
  RTOKENEXPIRE: 'refresh-token-expire',
};
interface ITokenPropsType {
  access_token: string;
  access_token_expire: string;
  refresh_token: string;
  refresh_token_expire: string;
}
export function setToken(props: ITokenPropsType) {
  localStorage.setItem(STORAGE_ITEMS.TOKEN, props.access_token);
  localStorage.setItem(STORAGE_ITEMS.TOKENEXPIRE, props.access_token_expire);
  localStorage.setItem(STORAGE_ITEMS.RTOKEN, props.refresh_token);
  localStorage.setItem(STORAGE_ITEMS.RTOKENEXPIRE, props.refresh_token_expire);
  axiosClient.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    STORAGE_ITEMS.TOKEN,
  )}`;
}
export function logout() {
  localStorage.removeItem(STORAGE_ITEMS.TOKEN);
  localStorage.removeItem(STORAGE_ITEMS.RTOKEN);
  localStorage.removeItem(STORAGE_ITEMS.TOKENEXPIRE);
  localStorage.removeItem(STORAGE_ITEMS.RTOKENEXPIRE);
  localStorage.removeItem('naver-refresh-token-expire');
  localStorage.removeItem('naver-refresh-token');
  delete axiosClient.defaults.headers.common.Authorization;
}
