import styles from './InAppGuide.module.scss';
import urlImage from '../../assets/InApp/iphoneInApp.png';
import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';
interface IPropsType {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function InAppGuide() {
  const navigate = useNavigate();
  const { productId } = useParams();
  useEffect(() => {
    if (!navigator.userAgent.match(/NAVER|KAKAOTALK|Instagram\/[^1]/i)) {
      navigate(`/productDetail/${productId}`);
    }
  }, []);
  function handleBrowserChange() {
    if (navigator.userAgent.match(/NAVER|KAKAOTALK|Instagram\/[^1]/i)) {
      if (navigator.userAgent.match(/iPhone|iPad/i)) {
        navigator.clipboard.writeText(
          `https://dietdr.co.kr/productDetail/${productId}`,
        );
        alert(
          '★★★★필독★★★★\n브라우저가 열리면 주소창에 링크를 붙여넣기하여 접속 후 구매하세요.',
        );
        window.location.href = 'x-web-search://?';
      } else {
        window.location.href =
          'intent://' +
          `https://dietdr.co.kr/productDetail/${productId}`.replace(
            /https?:\/\//i,
            '',
          ) +
          '#Intent;scheme=https;package=com.android.chrome;end';
      }
    }
  }
  return (
    <div className={styles.container}>
      <div>
        <h3>[결제 오류시 아래 방법으로 접속하세요.]</h3>
        {isIOS ? (
          <>
            <p>
              버튼을 누르면 자동으로 링크가 복사가 됩니다. Safari 창이 열리면
              붙여넣기하여 접속 후 구매하세요.
            </p>
          </>
        ) : (
          <p>아래 버튼을 눌러 Chrome을 실행해주세요.</p>
        )}
        <button className={styles.openBtn} onClick={handleBrowserChange}>
          {isIOS ? 'Safari' : 'Chrome으'}로 다이어트닥터 열기
        </button>
        {/* <button
          className={styles.keepBtn}
          onClick={() => {
            navigate(`/productDetail/${productId}`);
          }}
        >
          현재 브라우저에서 쇼핑
        </button> */}
      </div>
    </div>
  );
}
export default InAppGuide;
