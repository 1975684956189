import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

function Privacy() {
  return (
    <div>
      <Header />
      <div style={{ padding: '64px 0' }}>
        <h3
          style={{
            color: '#000',
            fontSize: '29px',
            letterSpacing: '-1px',
            textAlign: 'center',
            fontWeight: '600',
            paddingBottom: '32px',
          }}
        >
          개인정보처리방침
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="editor">
            <h2 style="box-sizing: border-box; margin: 0px 0px 7px; padding: 0px; border: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 33.15px; vertical-align: baseline; -webkit-font-smoothing: antialiased;"><p class="" align="left" style="margin: 0cm 0cm 5.25pt; text-align: left; line-height: 24.85pt; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><b><span style="font-size:15.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:="" "맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:black;="" mso-font-kerning:0pt"="">개인정보 처리방침<span lang="EN-US"><o:p></o:p></span></span></b></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(</span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">주<span lang="EN-US">)</span>한국메디컬헬스케어<span lang="EN-US">(</span>“회사”라 함<span lang="EN-US">)</span>는 정보통신망 이용촉진 및 정보보호 등에 관한 법률<span lang="EN-US">, </span>개인정보보호법<span lang="EN-US">, </span>통신비밀보호법<span lang="EN-US">, </span>전기통신사업법<span lang="EN-US">, </span>등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며<span lang="EN-US">, </span>관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">본 개인정보처리방침은
    회사가 제공하는 “홈페이지<span lang="EN-US">(<a href="http://www.dietdr.co.kr">www.dietdr.co.kr</a>)</span>” 및 “어플리케이션<span lang="EN-US"> (</span>다이어트 닥터<span lang="EN-US">)</span>”<span lang="EN-US"> (</span>이하에서는
    홈페이지 및 어플리케이션을 이하 ‘서비스’라 합니다<span lang="EN-US">.)</span>이용에 적용되며 다음과 같은 내용을 담고 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">수집하는 개인정보의 항목<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(1) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">회사는 회원가입<span lang="EN-US">, </span>원활한 고객상담<span lang="EN-US">, </span>각종 서비스의 제공을 위해 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">수집방법 – 수집항목<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">회원가입 –이동통신사
    정보 텍스트<span lang="EN-US">, </span>휴대폰 번호<span lang="EN-US">, CI, </span>성명<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>내외국인정보<span lang="EN-US">, DI<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">원격진료 예약하기 –
    진료예약 수단<span lang="EN-US">, </span>진료시간 입력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">배달약국 예약하기 –
    배달예약 수단<span lang="EN-US">, </span>배달시간 입력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">진료 수단<span lang="EN-US"> : </span>화상진료<span lang="EN-US">, </span>채팅진료<span lang="EN-US">, </span>전화진료
    선택 내역<span lang="EN-US">, </span>진료시간<span lang="EN-US"> : </span>원격진료 가능한 날짜 및 시간<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">진료신청서<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">환자 본인여부 확인<span lang="EN-US"> : </span>본인<span lang="EN-US">, </span>가족<span lang="EN-US">, </span>기타<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">증상입력<span lang="EN-US">, </span>증상 관련 사진<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">증상입력 내용<span lang="EN-US">, </span>증상 관련된 사진<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">배달 수단<span lang="EN-US"> : </span>배달 선택 내역<span lang="EN-US">, </span>배달시간<span lang="EN-US"> : </span>약
    배달 가능한 날짜 및 시간<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">배달을 위한 처방전 정보<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">배달을 위한 배달 주소<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">결제관리<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">카드 선택<span lang="EN-US">, </span>카드추가하기 – 카드번호<span lang="EN-US"> 16</span>자리<span lang="EN-US">,
    </span>유효기간 월<span lang="EN-US">/</span>년<span lang="EN-US">, CVC</span>번호<span lang="EN-US">, </span>기본결제수단 등록 여부<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">진료 및 배달을 위한
    기본정보 입력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">기본정보 입력 – 이름<span lang="EN-US">, </span>휴대폰번호<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>신장<span lang="EN-US">, </span>체중<span lang="EN-US">, </span>혈액형<span lang="EN-US">, </span>이메일<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">진료를 위한 건강정보
    입력 – 알레르기<span lang="EN-US">, </span>복약여부<span lang="EN-US">, </span>과거병력<span lang="EN-US">, </span>기저질환<span lang="EN-US">, </span>수술여부<span lang="EN-US">, </span>입원여부<span lang="EN-US">, </span>부상여부<span lang="EN-US">, </span>가족력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">진료를 위한 생활정보
    입력 – 흡연<span lang="EN-US">, </span>음주<span lang="EN-US">, </span>활동량<span lang="EN-US">, </span>식생활유형<span lang="EN-US">, </span>직업<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">회원탈퇴 –이메일<span lang="EN-US">, </span>이름<span lang="EN-US">, </span>휴대전화번호<span lang="EN-US">, </span>회원탈퇴
    사유<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">원격진료 신청<span lang="EN-US">/</span>예약 – 원격진료 예약 및 진료신청서 작성<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">본인<span lang="EN-US">, </span>가족<span lang="EN-US">, </span>기타 선택<span lang="EN-US">, </span>이름<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>휴대폰번호<span lang="EN-US">, </span>진료과목<span lang="EN-US">, </span>진료의사<span lang="EN-US">, </span>진료수단<span lang="EN-US">, </span>진료시간<span lang="EN-US">, </span>진료신청서<span lang="EN-US">, </span>증상내용<span lang="EN-US">, </span>증상과 관련된 첨부파일<span lang="EN-US">, </span>진료 예약일시<span lang="EN-US">, </span>진료 취소<span lang="EN-US">, </span>진료를 위한 기본정보 입력내용<span lang="EN-US">, </span>진료를 위한 건강정보 입력내용<span lang="EN-US">, </span>진료를 위한 생활방식 입력내용<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">화상진료 – 화상진료영상<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">처방전<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">본인<span lang="EN-US">, </span>가족<span lang="EN-US">, </span>기타 – 이름<span lang="EN-US">, </span>처방전
    정보<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">약 배달 신청<span lang="EN-US">/</span>예약 – 약 배달 예약 및 배달신청서 작성<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">본인<span lang="EN-US">, </span>가족<span lang="EN-US">, </span>기타 선택<span lang="EN-US">, </span>이름<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>휴대폰번호<span lang="EN-US">, </span>배달수단<span lang="EN-US">, </span>배달시간<span lang="EN-US">, </span>배달신청서<span lang="EN-US">, </span>배달내용<span lang="EN-US">, </span>배달 예약일시<span lang="EN-US">, </span>배달
    취소<span lang="EN-US">, </span>배달을 위한 기본정보 입력내용<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">의사 회원가입<span lang="EN-US"> (</span>의료기관 및 의사<span lang="EN-US">)<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">의사 회원가입<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">– 의사 이름<span lang="EN-US">, </span>의사 사진<span lang="EN-US">, </span>휴대폰 전화번호<span lang="EN-US">, </span>학력<span lang="EN-US">, </span>경력<span lang="EN-US">, </span>진료과목<span lang="EN-US">, </span>전문분야<span lang="EN-US">, </span>병원이름<span lang="EN-US">, </span>병원주소<span lang="EN-US">, </span>우편번호<span lang="EN-US">, </span>전화번호<span lang="EN-US">, </span>계좌정보<span lang="EN-US">, </span>예금주<span lang="EN-US">, </span>은행명<span lang="EN-US">, </span>계좌번호<span lang="EN-US">, </span>채팅
    진료비용<span lang="EN-US">, </span>화상 진료비용<span lang="EN-US">, </span>전화 진료비용<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">약사 회원가입<span lang="EN-US"> (</span>의료기관 및 약사<span lang="EN-US">)<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">약사 회원가입<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">– 약사 이름<span lang="EN-US">, </span>약사 사진<span lang="EN-US">, </span>휴대폰 전화번호<span lang="EN-US">, </span>학력<span lang="EN-US">, </span>경력<span lang="EN-US">, </span>배달과목<span lang="EN-US">, </span>전문분야<span lang="EN-US">, </span>병원이름<span lang="EN-US">, </span>병원주소<span lang="EN-US">, </span>우편번호<span lang="EN-US">, </span>전화번호<span lang="EN-US">, </span>계좌정보<span lang="EN-US">, </span>예금주<span lang="EN-US">, </span>은행명<span lang="EN-US">, </span>계좌번호<span lang="EN-US">, </span>배달비용<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">원격진료 접수<span lang="EN-US">/</span>예약된 진료확인 공통<span lang="EN-US"> (</span>의료기관 및 의사<span lang="EN-US">)<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">약 배달 – 배달 접수<span lang="EN-US">/</span>예약된 배달확인 공통<span lang="EN-US"> (</span>의료기관 및 약사<span lang="EN-US">)<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">서비스 이용 시 자동수집
    – 서비스 이용기록<span lang="EN-US">, </span>접속로그<span lang="EN-US">, </span>접속<span lang="EN-US">IP</span>정보<span lang="EN-US">, </span>의사정보<span lang="EN-US">, </span>약사정보<span lang="EN-US">, </span>진료과목<span lang="EN-US">, </span>배달과목<span lang="EN-US">, </span>전문분야<span lang="EN-US">, </span>진료예약 시간<span lang="EN-US">, </span>배달예약 시간<span lang="EN-US">, </span>의사이름<span lang="EN-US">, </span>약사이름<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">위치정보 – 이용자의
    위치에 근접한 병의원 및 약국 정보 제공<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(2) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">㈜한국메디컬헬스케어는 귀하의 개인정보보호를 매우 중요시하며<span lang="EN-US">, </span>『개인정보 보호법』과 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』상의 개인정보 처리방침 및 행정안전부와
    정보통신부가 제정한 『개인정보 보호지침』을 준수하고 있습니다<span lang="EN-US">. </span>회사는 개인정보처리방침을 통하여 귀하께서
    제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며<span lang="EN-US">, </span>개인정보보호를 위해 어떠한 조치가 취해지고
    있는지 알려드립니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(3) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">회사는 개인정보 처리방침을 사이트 첫 화면과 고객센터의 ‘개인정보 처리방침’
    항목에서 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(4) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">회사는 개인정보 처리방침의 지속적인 개선을 위하여 개인정보 처리방침을 개정하는데
    필요한 절차를 정하고 있습니다<span lang="EN-US">. </span>그리고 개인정보 처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된
    사항을 귀하께서 쉽게 알아볼 수 있도록 하고 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(5) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">서비스 아이디를 이용한 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모
    과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이 수집될 수 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">개인정보의 수집 및 이용
    목적<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">&nbsp;<o:p></o:p></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">회사는 다음과 같이 회원님의
    개인정보를 수집합니다<span lang="EN-US">. </span>처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며<span lang="EN-US">, </span>이용 목적이 변경되는 경우에는 개인정보 보호법 제<span lang="EN-US">18</span>조에 따라 별도의
    동의를 받는 등 필요한 조치를 이행할 예정입니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">이용 목적<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">회사가 개인정보를 수집하는
    목적은 휴대폰 전화번호 및 인증번호에 의한 개인 식별을 통해 신원이 불확실하고 불손한 행위를 하는 인터넷 사용자가 아닌 양질의 이용자를 선별하여
    ‘서비스’에서 제공하는 양질의 맞춤화 된 서비스를 드리기 위함입니다<span lang="EN-US">.<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">수집항목<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">회원가입 및 로그인<span lang="EN-US">, </span>회원관리<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">휴대폰 전화번호<span lang="EN-US">, </span>인증번호<span lang="EN-US">, </span>이름<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">원격진료 예약하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">진료수단<span lang="EN-US">, </span>진료시간<span lang="EN-US">(</span>날짜 및 시간<span lang="EN-US">)<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">배달 예약하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">배달수단<span lang="EN-US">, </span>배달시간<span lang="EN-US">(</span>날짜 및 시간<span lang="EN-US">)<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">진료신청서 입력하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">환자 본인<span lang="EN-US">/</span>가족<span lang="EN-US">/</span>기타 여부<span lang="EN-US">, </span>증상입력<span lang="EN-US">, </span>사진 첨부<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">배달신청서 입력하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">환자 본인<span lang="EN-US">/</span>가족<span lang="EN-US">/</span>기타 여부<span lang="EN-US">, </span>증상입력<span lang="EN-US">, </span>사진 첨부<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">기본정보 입력하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">이름<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>휴대전화번호<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">건강정보 입력하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">알레르기<span lang="EN-US">, </span>복약여부<span lang="EN-US">, </span>과거병력<span lang="EN-US">, </span>기저질환<span lang="EN-US">, </span>수술여부<span lang="EN-US">,<o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">입원여부<span lang="EN-US">, </span>부상여부<span lang="EN-US">, </span>가족력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">생활정보 입력하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">흡연<span lang="EN-US">, </span>음주<span lang="EN-US">, </span>활동량<span lang="EN-US">, </span>식생활유형<span lang="EN-US">, </span>직업<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">처방약 수령 주소<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">처방약 수령을 위한 주소<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">카드정보 등록하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">카드번호<span lang="EN-US"> 16</span>자리<span lang="EN-US">, </span>유효기간 월<span lang="EN-US">/</span>년<span lang="EN-US">, CVC</span>번호<span lang="EN-US">(App) </span>진료신청하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">이름<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>휴대폰번호<span lang="EN-US">, </span>시간<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">배달신청하기<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">이름<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>휴대폰번호
    시간<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">진료신청서<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">환자 본인여부 확인<span lang="EN-US"> : </span>본인<span lang="EN-US">, </span>가족<span lang="EN-US">, </span>기타<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">증상입력 내용<span lang="EN-US">, </span>증상 관련된 사진<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">배달신청서<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">환자 본인여부 확인<span lang="EN-US"> : </span>본인<span lang="EN-US">, </span>가족<span lang="EN-US">, </span>기타
    증상입력 내용<span lang="EN-US">, </span>증상 관련된 사진<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App)</span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">결제관리<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">카드번호<span lang="EN-US"> 16</span>자리<span lang="EN-US">, </span>유효기간 월<span lang="EN-US">/</span>년<span lang="EN-US">, CVC</span>번호<span lang="EN-US">, </span>기본결제수단 등록 여부<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">진료를 위한 기본정보 입력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">이름<span lang="EN-US">, </span>휴대폰번호<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>신장<span lang="EN-US">, </span>체중<span lang="EN-US">, </span>혈액형<span lang="EN-US">, </span>이메일<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App)</span><span style="font-size:11.5pt;mso-ascii-font-family:" 맑은="" 고딕";="" mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:="" 굴림;color:#424242;mso-font-kerning:0pt"="">배달을 위한 기본정보 입력<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span style="font-size:11.5pt;
    mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";mso-hansi-font-family:="" "맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:0pt"="">이름<span lang="EN-US">, </span>휴대폰번호<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>성별<span lang="EN-US">, </span>신장<span lang="EN-US">, </span>체중<span lang="EN-US">, </span>혈액형<span lang="EN-US">, </span>이메일<span lang="EN-US"><o:p></o:p></span></span></p>
    
    <p class="" align="left" style="margin: 0cm; text-align: left; line-height: normal; vertical-align: baseline; word-break: keep-all; font-size: 10pt; font-family: " 맑은="" 고딕";"=""><span lang="EN-US" style="font-size:
    11.5pt;mso-ascii-font-family:" 맑은="" 고딕";mso-fareast-font-family:"맑은="" 고딕";="" mso-hansi-font-family:"맑은="" 고딕";mso-bidi-font-family:굴림;color:#424242;mso-font-kerning:="" 0pt"="">(App) </span></p></h2>			</div>`,
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
}
export default Privacy;
