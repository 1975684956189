import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "./AppointmentsCalendar.module.scss";
import { formatDate } from "react-calendar/dist/cjs/shared/dateFormatter";
import holidays from "../../assets/data/holidays.json";
import { useQuery } from "react-query";
import axiosClient from "../../libs/axiosClient";
interface IPropsType {
    appointmentDate: Date | null;
    setAppointmentDate: React.Dispatch<React.SetStateAction<Date | null>>;
    appointmentTime: string;
    setAppointmentTime: React.Dispatch<React.SetStateAction<string>>;
}
interface IHolidayType {
    [key: string]: {
        reservation_date: string;
        is_holiday: string;
        day_of_week: number; //0월요일 6일요일
        is_able: string;
        is_empty: string;
        note: string;
        time_list: {
            [key: string]: {
                reservation_time_id: number;
                reservation_time: string;
                reservation_limit: number;
                reservation_unit: number;
                is_empty: string;
            };
        };
    };
}

let morning = ["10:00", "10:30", "11:00", "11:30"];
let afternoon = [
    "12:00",
    "12:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
];
function AppointmentsCalendar({
    appointmentDate,
    setAppointmentDate,
    appointmentTime,
    setAppointmentTime,
}: IPropsType) {
    const [viewingYear, setViewingYear] = useState(
        appointmentDate
            ? String(appointmentDate.getFullYear())
            : String(new Date().getFullYear())
    );
    const [viewingMonth, setViewingMonth] = useState(
        appointmentDate
            ? ("0" + (appointmentDate.getMonth() + 1)).slice(-2)
            : ("0" + (new Date().getMonth() + 1)).slice(-2)
    );
    const { data: holiday } = useQuery<IHolidayType>(
        [
            (() => {
                return `${viewingYear}-${viewingMonth}`;
            })(),
        ],
        async () => {
            return axiosClient
                .get(
                    `/data/prescription/check_reservation?month=${viewingYear}-${viewingMonth}`
                )
                .then((res) => res.data.data);
        },
        { refetchOnWindowFocus: false, staleTime: 30000 }
    );
    function checkHoliday(date: string) {
        if (!holiday || Object.keys(holiday).length == 0) return false;
        let dateInfo =
            holiday[
                `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
            ];
        if (!dateInfo) return false;
        if (dateInfo.is_able == "N" || dateInfo.is_empty == "N") return true;
        else return false;
    }
    function checkPast(time: string) {
        const date = new Date();
        if (date.getTime() >= appointmentDate!.getTime()) {
            if (
                //현재 시가 더 크거나, 시가 동일해도 현재 분이 더 크면 과거
                Number(time.slice(0, 2)) < date.getHours() ||
                (Number(time.slice(0, 2)) == date.getHours() &&
                    Number(time.slice(3, 5)) <= date.getMinutes())
            )
                return true;
        }
        return false;
    }
    return (
        <article className={styles.container}>
            <section className={styles.calendarWrap}>
                <Calendar
                    className={styles.calendar}
                    onChange={(value, event) => {
                        setAppointmentDate(value as Date);
                    }}
                    value={appointmentDate}
                    minDate={new Date()}
                    onActiveStartDateChange={({
                        action,
                        activeStartDate,
                        value,
                        view,
                    }) => {
                        if (view !== "month" || !activeStartDate) return;
                        setViewingYear(String(activeStartDate.getFullYear()));
                        setViewingMonth(
                            ("0" + (activeStartDate?.getMonth() + 1)).slice(-2)
                        );
                    }}
                    tileClassName={({ activeStartDate, date, view }) => {
                        const TODAY = new Date();
                        const TOMORROW = new Date(
                            TODAY.setDate(TODAY.getDate() + 1)
                        );
                        TODAY.setDate(TODAY.getDate() - 1);

                        let className = "";
                        let todayMidnight = new Date(
                            String(new Date()).replace(
                                /[\d]{2}:[\d]{2}:[\d]{2}/g,
                                "00:00:00"
                            )
                        );
                        const YEAR = date.getFullYear();
                        const MONTH = ("0" + (date.getMonth() + 1)).slice(-2);
                        const DATE = ("0" + date.getDate()).slice(-2);
                        if (checkHoliday(YEAR + MONTH + DATE))
                            className += "holiday";
                        if (date.getDay() == 0) className += " sunday";
                        if (date.getTime() < todayMidnight.getTime())
                            className += " past";
                        if (
                            TODAY.getFullYear() == YEAR &&
                            ("0" + (TODAY.getMonth() + 1)).slice(-2) == MONTH &&
                            ("0" + TODAY.getDate()).slice(-2) == DATE
                        )
                            className += " today";
                        if (
                            TOMORROW.getFullYear() == YEAR &&
                            ("0" + (TOMORROW.getMonth() + 1)).slice(-2) ==
                                MONTH &&
                            ("0" + TOMORROW.getDate()).slice(-2) == DATE
                        )
                            className += " tomorrow";
                        return className;
                    }}
                    tileDisabled={({ date, view }) => {
                        const TODAY = new Date();
                        const TOMORROW = new Date(
                            TODAY.setDate(TODAY.getDate() + 1)
                        );
                        TODAY.setDate(TODAY.getDate() - 1);
                        const DAY = date.getDay();
                        const YEAR = date.getFullYear();
                        const MONTH = ("0" + (date.getMonth() + 1)).slice(-2);
                        const DATE = ("0" + date.getDate()).slice(-2);
                        if (
                            (TODAY.getFullYear() == YEAR &&
                                ("0" + (TODAY.getMonth() + 1)).slice(-2) ==
                                    MONTH &&
                                ("0" + TODAY.getDate()).slice(-2) == DATE) ||
                            (TOMORROW.getFullYear() == YEAR &&
                                ("0" + (TOMORROW.getMonth() + 1)).slice(-2) ==
                                    MONTH &&
                                ("0" + TOMORROW.getDate()).slice(-2) == DATE)
                        )
                            return true; //오늘 내일 예약 불가처리
                        return (
                            (DAY == 0 || checkHoliday(YEAR + MONTH + DATE)) &&
                            view == "month"
                        );
                    }}
                    formatDay={(locale, date) => String(date.getDate())}
                    prevLabel={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                        >
                            <path
                                d="M22 10.9998L15 18.4998L22 25.9998"
                                stroke="#D1D0D5"
                                strokeWidth="2"
                            />
                        </svg>
                    }
                    nextLabel={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                        >
                            <path
                                d="M15 26.0002L22 18.5002L15 11.0002"
                                stroke="#131C31"
                                strokeWidth="2"
                            />
                        </svg>
                    }
                    prev2Label={null}
                    next2Label={null}
                    calendarType={"gregory"}
                    showNeighboringMonth={false}
                />
            </section>

            {appointmentDate && (
                <section className={styles.timeWrap}>
                    <div className={styles.header}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                        >
                            <circle
                                cx="18.5"
                                cy="18.5"
                                r="8.5"
                                stroke="#141736"
                                strokeWidth="2"
                            />
                            <path
                                d="M18.5 13.5V18.5H23.5"
                                stroke="#131C31"
                                strokeWidth="1.5"
                            />
                        </svg>
                        <h6>예약 시간</h6>
                    </div>
                    <div className={styles.times}>
                        <div className={styles.morning}>
                            <h6>오전</h6>
                            <ul>
                                {morning.map((time, idx) => {
                                    let isDisable = false;
                                    if (checkPast(time)) isDisable = true;
                                    if (
                                        holiday &&
                                        Object.keys(holiday).length !== 0
                                    ) {
                                        const RESERVATION_TIME_INFO =
                                            holiday[
                                                `${appointmentDate.getFullYear()}-${(
                                                    "0" +
                                                    (appointmentDate.getMonth() +
                                                        1)
                                                ).slice(-2)}-${(
                                                    "0" +
                                                    appointmentDate.getDate()
                                                ).slice(-2)}`
                                            ]?.time_list;
                                        if (
                                            RESERVATION_TIME_INFO &&
                                            RESERVATION_TIME_INFO[
                                                `${time}:00`
                                            ] &&
                                            RESERVATION_TIME_INFO[`${time}:00`]
                                                .is_empty == "N"
                                        ) {
                                            isDisable = true;
                                        }
                                    }
                                    return (
                                        <li
                                            onClick={() => {
                                                if (isDisable) return;
                                                setAppointmentTime("am" + time);
                                            }}
                                            className={`${
                                                appointmentTime.substring(2) ==
                                                time
                                                    ? styles.hit
                                                    : ""
                                            } ${
                                                isDisable ? styles.disable : ""
                                            }`}
                                            key={time}
                                        >
                                            {time}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className={styles.afternoon}>
                            <h6>오후</h6>
                            <ul>
                                {(() => {
                                    let afternoonList = afternoon;
                                    if (
                                        appointmentDate.getDay() == 2 ||
                                        appointmentDate.getDay() == 4 ||
                                        appointmentDate.getDay() == 5
                                    )
                                        afternoonList = afternoonList.slice(
                                            0,
                                            12
                                        );
                                    if (appointmentDate.getDay() == 6)
                                        afternoonList = afternoonList.slice(
                                            0,
                                            4
                                        );
                                    return afternoonList.map((time, idx) => {
                                        let isDisable = false;
                                        let isLunch = false;
                                        const date = new Date();
                                        let hour = Number(time.slice(0, 2));
                                        let minute = Number(time.slice(3, 5));
                                        if (hour !== 12) hour += 12;
                                        if (
                                            checkPast(
                                                `${("0" + hour).slice(-2)}:${(
                                                    "0" + minute
                                                ).slice(-2)}`
                                            )
                                        )
                                            isDisable = true;
                                        if (
                                            hour == 14 ||
                                            (hour == 15 && minute == 0)
                                        ) {
                                            //점심시간
                                            isDisable = true;
                                            isLunch = true;
                                        }
                                        if (
                                            holiday &&
                                            Object.keys(holiday).length !== 0
                                        ) {
                                            const RESERVATION_TIME_INFO =
                                                holiday[
                                                    `${appointmentDate.getFullYear()}-${(
                                                        "0" +
                                                        (appointmentDate.getMonth() +
                                                            1)
                                                    ).slice(-2)}-${(
                                                        "0" +
                                                        appointmentDate.getDate()
                                                    ).slice(-2)}`
                                                ]?.time_list;
                                            if (
                                                RESERVATION_TIME_INFO &&
                                                RESERVATION_TIME_INFO[
                                                    `${hour}:${(
                                                        "0" + minute
                                                    ).slice(-2)}:00`
                                                ] &&
                                                RESERVATION_TIME_INFO[
                                                    `${hour}:${(
                                                        "0" + minute
                                                    ).slice(-2)}:00`
                                                ].is_empty == "N"
                                            ) {
                                                isDisable = true;
                                            }
                                        }
                                        return (
                                            <li
                                                onClick={() => {
                                                    if (isDisable) return;
                                                    setAppointmentTime(
                                                        "pm" + time
                                                    );
                                                }}
                                                className={`${
                                                    appointmentTime.substring(
                                                        2
                                                    ) == time
                                                        ? styles.hit
                                                        : ""
                                                } ${
                                                    isDisable
                                                        ? styles.disable
                                                        : ""
                                                }
                        ${isLunch ? styles.lunch : ""}
                        `}
                                                key={time}
                                            >
                                                {time}
                                            </li>
                                        );
                                    });
                                })()}
                            </ul>
                        </div>
                    </div>
                </section>
            )}
        </article>
    );
}
export default AppointmentsCalendar;
