import styles from './Home.module.scss';
import Header from '../../components/Header/Header';
import HeaderMain from '../../components/HeaderMain/HeaderMain';
import MainBanner from '../../components/MainBanner/MainBanner';
import rightArrow from '../../assets/rightArrow.png';
import processImage01 from '../../assets/processImage01.jpg';
import processImage02 from '../../assets/processImage02.jpg';
import processImage03 from '../../assets/processImage03.jpg';
import process01 from '../../assets/process01.png';
import process02 from '../../assets/process02.png';
import process03 from '../../assets/process03.png';
import process04 from '../../assets/process04.png';
import rightArrow2 from '../../assets/rightArrow07.png';
import star from '../../assets/star.png';
// import doctor01 from '../../assets/doctors/doctor01.png';
import doctor02 from '../../assets/doctors/doctor02.png';
// import doctor03 from '../../assets/doctors/doctor03.png';
// import doctor04 from '../../assets/doctors/doctor04.png';
// import doctor05 from '../../assets/doctors/doctor05.png';
// import doctor06 from '../../assets/doctors/doctor06.png';
// import doctor07 from '../../assets/doctors/doctor07.png';
// import doctor08 from '../../assets/doctors/doctor08.png';
import youjiyou from '../../assets/doctors/youjiyou.png';
import kimmiri from '../../assets/doctors/kimmiri.png';
import MainReview from '../../components/MainReview/MainReview';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Footer from '../../components/Footer/Footer';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { IProductListType } from '../ProductList/ProductList';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFaqType } from '../Faq/Faq';
import MainFaqFrame from '../../components/MainFaqFrame/MainFaqFrame';
import mainFaqData from '../../assets/data/mainFaq.json';
import SwiperCore from 'swiper';
import LazyLoad from 'react-lazy-load';
import ADLink from '../../components/ADLink/ADLink';
import Popup from '../../components/Popup/Popup';
import { useCookies } from 'react-cookie';
export interface IAdLinkDataType {
  id: number;
  product_name: string;
  price: number;
  sale_price: number;
}
const mainFaqList = mainFaqData;
function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  //프로세스
  const [processSliderIdx, setProcessSliderIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  //프로세스
  //상품
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
    { staleTime: 60000 * 60 * 24 },
  );
  //상품
  //faq
  // const { data: faqListQuery } = useQuery<{
  //   data: IFaqType[];
  //   faq_type: {
  //     [key: number]: string;
  //   };
  // }>('faq', () => axiosClient.get('/data/faq').then((res) => res.data.data), {
  //   staleTime: 60000 * 60 * 24, //1일 캐싱
  // });
  //faq

  //기존 상품 url접속시 대응
  useEffect(() => {
    let pn = new URLSearchParams(location.search).get('pn');
    if (!pn) return;
    if (pn == 'product.view') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=N&code=${new URLSearchParams(
            location.search,
          ).get('pcode')}`,
        )
        .then((res) => navigate(res.data.data.url));
    } else if (pn == 'blind_gate') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=Y&code=${new URLSearchParams(
            location.search,
          ).get('bc')}`,
        )
        .then((res) => navigate(res.data.data.url));
    }
  }, []);
  //기존 상품 url접속시 대응
  const [doctorSliderIdx, setDoctorSliderIdx] = useState(0);
  const [adLinkOpen, setAdLinkOpen] = useState(true);
  const [adLinkData, setAdLinkData] = useState<IAdLinkDataType>();
  // useEffect(() => {
  //   axiosClient.get('/data/product/promotional_product').then((res) => {
  //     if (!Object.keys(res.data.data).length) return;
  //     setAdLinkData(res.data.data);
  //   });
  // }, []);

  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  return (
    <>
      {adLinkOpen && adLinkData && (
        <ADLink
          productId={adLinkData.id}
          productName={adLinkData.product_name}
          price={adLinkData.price}
          discountedPrice={adLinkData.sale_price}
          closer={setAdLinkOpen}
        />
      )}
      {/* {!hasCookie && <Popup />} */}
      <div className={styles.container}>
        {/* <Header /> */}
        <HeaderMain />
        <MainBanner />
        {/* <div className={styles.processWrap}>
          <h3>비대면 처방 프로세스</h3>
          <p>빠르고 안전한, 쉽고 편리한 진료 서비스</p>
          <ul className={styles.hashtag}>
            <li># 비대면서비스</li>
            <li># 가벼운 증상부터 만성질환까지</li>
            <li># 믿고받는진료</li>
          </ul>
          <div className={styles.processContentWrap}>
            <div>
              <img src={process01} alt="" />
              <div className={styles.text}>
                <span>01</span>
                <h4>문진표 작성</h4>
                <p>제품 선택 후 문진표 작성</p>
              </div>
            </div>
            <div>
              <img src={process02} alt="" />
              <div className={styles.text}>
                <span>02</span>
                <h4>결제 및 접수</h4>
                <p>
                  결제 완료 후 1-3일 이내
                  <br />
                  담당의사 접수
                </p>
              </div>
            </div>
            <div>
              <img src={process03} alt="" />
              <div className={styles.text}>
                <span>03</span>
                <h4>전화처방</h4>
                <p>담당 의사로부터 진료 시작</p>
              </div>
            </div>
            <div>
              <img src={process04} alt="" />
              <div className={styles.text}>
                <span>04</span>
                <h4>당일발송</h4>
                <p>오후 12시 이내 처방 완료건 당일발송</p>
              </div>
            </div>
          </div>
          <div className={styles.processSliderWrap}>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              className={styles.processSlider}
              onSlideChange={(e) => setProcessSliderIdx(e.activeIndex)}
            >
              <SwiperSlide className={styles.processSlide}>
                <img src={process01} alt="process" />
                <div className={styles.stage}>
                  <h4>문진표 작성</h4>
                  <p>제품 선택 후 문진표 작성</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process02} alt="process" />
                <div className={styles.stage}>
                  <h4>결제 및 접수</h4>
                  <p>결제 완료 후 1-3일 이내 담당의사 접수</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process03} alt="process" />
                <div className={styles.stage}>
                  <h4>전화처방</h4>
                  <p>담당 의사로부터 전화 진료 시작</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process04} alt="process" />
                <div className={styles.stage}>
                  <h4>당일발송</h4>
                  <p>오후 12시 이내 처방 완료건 당일 배송</p>
                </div>
              </SwiperSlide>
            </Swiper>
            <ul className={styles.processSliderPagination}>
              {(() => {
                let list = [0, 1, 2, 3];
                return list.map((val) => (
                  <li
                    key={val}
                    className={processSliderIdx == val ? styles.hit : ''}
                  >
                    <div></div>
                  </li>
                ));
              })()}
            </ul>
          </div>
        </div> */}
        {/* <div className={styles.prescriptionProductWrap}>
        <h3 onClick={() => navigate(`/productList?cuid=487`)}>
          <span>처방의약품</span>
          <img src={rightArrow} alt="rightArrow" />
        </h3>
        <span>다이어트 닥터 비대면 처방의약품</span>
        <Swiper
          slidesPerView={2.3}
          spaceBetween={10}
          className={styles.prescriptionProductSlider}
        >
          {productList
            ?.filter((product) => product.is_prescription == 1)
            .map((product) => (
              <SwiperSlide
                key={product.product_id}
                className={styles.prescriptionProductSlide}
                onClick={() => navigate(`/productDetail/${product.product_id}`)}
              >
                <img src={product.list_image} alt="productThumbnail" />
                <div className={styles.productInfoWrap}>
                  <h3>{product.title}</h3>
                  <ul className={styles.hashtagList}>
                    {product.hashtag &&
                      product.hashtag
                        .split(',')
                        .map((hashtag) => <li># {hashtag}</li>)}
                  </ul>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div> */}
        <div className={styles.normalProductWrap}>
          <h3 onClick={() => navigate(`/productList?cuid=488`)}>
            <span>다이어트닥터</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>다이어트닥터의 차별화된 건강 식품</span>
          <ul className={styles.normalProductList}>
            {productList
              ?.filter((product) => product.is_prescription === 0)
              .filter((product, idx) => idx < 2)
              .map((product) => (
                <li
                  key={product.product_id}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.productThumbnailWrap}>
                    <img
                      src={product.list_image + '?w=530'}
                      alt="productThumbnail"
                    />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    <ul className={styles.hashtagList}>
                      {product.hashtag &&
                        product.hashtag
                          .split(',')
                          .map((hashtag, idx) => <li key={idx}>{hashtag}</li>)}
                    </ul>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        {/* <div className={styles.allProductList}>
        <h3>
          <span>DIET DOCTOR PRODUCT</span>
        </h3>
        <span>내원없이 간편하게 받자!</span>
        <ul className={styles.productListWrap}>
          {productList
            ?.filter((product, idx) => idx < 4)
            .map((product) => (
              <li
                key={product.product_id}
                onClick={() => navigate(`/productDetail/${product.product_id}`)}
              >
                <div className={styles.productThumbnailWrap}>
                  <img src={product.list_image} alt="productThumbnail" />
                </div>
                <div className={styles.productInfoWrap}>
                  <h3>{product.title}</h3>
                  {product.discount_rate !== 0 && (
                    <span className={styles.discountRate}>
                      {product.discount_rate}%
                    </span>
                  )}
                  <span className={styles.price}>
                    {product.discount_price.toLocaleString()}원
                  </span>
                  <div className={styles.reviewScore}>
                    <img src={star} alt="star" />
                    <span>
                      {Math.floor(
                        (product.review_score_avg.review_point_total_avg / 2) *
                          10,
                      ) / 10}
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        {productList && productList.length > 4 && (
          <button onClick={() => navigate(`/productList`)}>
            모든 제품 보기
          </button>
        )}
      </div> */}
        <div className={styles.faqWrap}>
          <h3 onClick={() => navigate(`/faq`)}>
            <span>DIET DOCTOR FAQ</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>자주 묻는 질문</span>
          <ul>
            {mainFaqList?.map((faq, idx) => (
              <MainFaqFrame faq={faq} key={idx} />
            ))}
          </ul>
        </div>
        <LazyLoad>
          <MainReview />
        </LazyLoad>
        {/* <div className={styles.legitScript}>
        <img src={america} alt="america" />
        <div>
          <div className={styles.legitLogoWrap}>
            <img
              src="	https://static.legitscript.com/seals/14145209.png"
              alt="legit"
            />
          </div>
          <p>
            다이어트 닥터는 미국 legitScript 로 부터 비대면의료 인증을
            받았습니다.
          </p>
          <p>
            Ondoc started with US legitScript
            <br />
            contactless medical certification has been obtained.
          </p>
        </div>
      </div> */}
        <div className={styles.ondocInfo}>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            className={styles.processSlider}
            onSwiper={setSwiper}
          >
            <div
              className={styles.handleBtn}
              onClick={() => swiper?.slidePrev()}
            >
              <img
                src={rightArrow2}
                alt=""
                style={{ transform: 'rotate(180deg)' }}
              />
            </div>
            <div
              className={`${styles.handleBtn} ${styles.rightHandleBtn}`}
              onClick={() => swiper?.slideNext()}
            >
              <img src={rightArrow2} alt="" />
            </div>
            <SwiperSlide className={styles.processSlide}>
              <div className={styles.text}>
                <h3>
                  <span>원격 진료를</span>
                  <br />
                  간편하고 정확하게
                </h3>
                <p>
                  쉽고 편한 의료 서비스를 제공하여 언제 어<br />
                  디서나 정확한 진료를 받아볼 수 있습니다.
                </p>
              </div>
              <div className={styles.processImageWrap}>
                <img src={processImage01} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.processSlide}>
              <div className={styles.text}>
                <h3>
                  <span>정성과 과학으로</span>
                  <br />
                  만듭니다.
                </h3>
                <p>
                  검증된 약재와 맞춤 처방으로 안전한 효과를
                  <br />
                  추구하며, 식약처 인증된 의약용품만을 사용
                </p>
              </div>
              <div className={styles.processImageWrap}>
                <img src={processImage02} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.processSlide}>
              <div className={styles.text}>
                <h3>
                  처방약을
                  <br />
                  <span>쉽고 빠르게</span>
                </h3>
                <p>
                  의료 서비스 이용에 어려움을 겪는 분들에게
                  <br />
                  처방한 약을 쉽고 빠르게 배송
                </p>
              </div>
              <div className={styles.processImageWrap}>
                <img src={processImage03} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.doctorInfoWrap}>
          <h3>DIET DOCTOR DOCTORS</h3>
          <p>다이어트 닥터 의료진 소개 </p>
          <div className={styles.doctorSliderWrap}>
            {/* <div className={styles.doctor}>
            <div className={styles.doctorImageWrap}>
              <img src={doctor01} alt="손영훈" />
            </div>
            <h4>손영훈 한의사</h4>
            <h5>Son younghoon</h5>
            <ul>
              <li>원광대학교 한의과대학 졸업</li>
              <li>보건복지부 장관상 수상</li>
              <li>서울시 체육회장상 수상</li>
              <li>서울시의회 의장상 수상</li>
              <li>대한한방해외의료봉사단 부단장</li>
              <li>대한한의사협회 국제위원</li>
            </ul>
          </div>
          <div className={styles.doctor}>
            <div className={styles.doctorImageWrap}>
              <img src={doctor03} alt="박나라" />
            </div>
            <h4>박나라 한의사</h4>
            <h5>Park Nara</h5>
            <ul>
              <li>대전대학교 한의과대학 졸업</li>
              <li>유튜브 ‘온라인한의사’ 운영진</li>
              <li>한의 원격의료 연구회 회원</li>
            </ul>
          </div> */}
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              className={styles.doctorSlider}
              onSlideChange={(e) => setDoctorSliderIdx(e.activeIndex)}
              style={{ width: '100%' }}
            >
              <SwiperSlide>
                <div className={styles.doctor}>
                  <div className={styles.doctorImageWrap}>
                    <img src={kimmiri} alt="doctor" />
                  </div>
                  <div className={styles.doctorInfo}>
                    <h4>김미리 한의사</h4>
                    <h5>Kim miri</h5>
                    <ul>
                      <li>세명대학교 한의과대학 졸업</li>
                      <li>강남위담한방병원 일반수련의 수료</li>
                      <li>대한한의학회 정회원</li>
                      <li>대한담적한의학회 회원</li>
                      <li>대한한방비만학회 회원</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.doctor}>
                  <div className={styles.doctorImageWrap}>
                    <img src={youjiyou} alt="doctor" />
                  </div>
                  <div className={styles.doctorInfo}>
                    <h4>유지유 한의사</h4>
                    <h5>Yoo jiyou</h5>
                    <ul>
                      <li>원광대학교 한의과대학 졸업</li>
                      <li>신농씨 학회 정회원</li>
                      <li>MPS(근막이완침술) 교육 이사</li>
                      <li>척추관절 추나교육 이수</li>
                      <li>동의보감 병인론 연구모임</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.doctor}>
                  <div className={styles.doctorImageWrap}>
                    <img src={doctor02} alt="doctor" />
                  </div>
                  <div className={styles.doctorInfo}>
                    <h4>이소영 한의사</h4>
                    <h5>Lee Soyoung</h5>
                    <ul>
                      <li>동의방약학회 정회원</li>
                      <li>한방비만학회 준회원</li>
                      <li>심리상담사 1급 심리분석사 1급</li>
                      <li>원광대학교 한의대학졸업</li>
                      <li>
                        E-BOOK {'<'}습담제거로 뱃살 뺀 이야기{'>'} 저자
                      </li>
                      <li>유튜브 ‘당뇨스쿨’ 운영자</li>
                      <li>당뇨 쇼핑몰 '당몰' 자문위원</li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img src={doctor02} alt="doctor" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>이소영 한의사</h4>
                  <span>Lee Soyoung</span>
                  <ul>
                    <li>동의방약학회 정회원</li>
                    <li>한방비만학회 준회원</li>
                    <li>심리상담사 1급 심리분석사 1급</li>
                    <li>원광대학교 한의대학졸업</li>
                    <li>
                      E-BOOK {'<'}습담제거로 뱃살 뺀 이야기{'>'} 저자
                    </li>
                    <li>유튜브 ‘당뇨스쿨’ 운영자</li>
                    <li>당뇨 쇼핑몰 '당몰' 자문위원</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img src={doctor03} alt="doctor" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>박나라 한의사</h4>
                  <span>Park Nara</span>
                  <ul>
                    <li>대전대학교 한의과대학 졸업</li>
                    <li>유튜브 ‘온라인한의사’ 운영진</li>
                    <li>한의 원격의료 연구회 회원</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor04}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>김운정 한의사</h4>
                  <span>Kim Unjung</span>
                  <ul>
                    <li>세명대학교 한의학과 졸업</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor05}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>김지현 한의사</h4>
                  <span>Kim Jihyun</span>
                  <ul>
                    <li>세명대학교 한의과대학 한의학과 졸업</li>
                    <li>분당자생한방병원 일반수련의 수료</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor06}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>박은영 한의사</h4>
                  <span>Park Eunyoung</span>
                  <ul>
                    <li>동국대학교 한의과대학 졸업</li>
                    <li>보건복지부 불안장애 한의표준</li>
                    <li>임상진료 지침 연구원</li>
                    <li>2020~2022 하이닥-네이버IN상담 한의사</li>
                    <li>
                      {'<'}비만당뇨 한방치료사례집{'>'} 저자
                    </li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor07}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>장효두 한의사</h4>
                  <span>Jang Hyodoo</span>
                  <ul>
                    <li>경희대학교 한의과대학 한의학과 졸업</li>
                    <li>경희의료원 임상과정 수료</li>
                    <li>강동경희대병원 임상과정 수료</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.doctor}>
                <div className={styles.doctorImageWrap}>
                  <img
                    src={doctor08}
                    alt="doctor"
                    className={styles.bigImage}
                  />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>전유경 한의사</h4>
                  <span>Jeon Youkyung</span>
                  <ul>
                    <li>대한한의학회 정회원</li>
                    <li>한방비만학회 준회원</li>
                    <li>대한약침학회 준회원</li>
                  </ul>
                </div>
              </div>
            </SwiperSlide> */}
            </Swiper>
            {/* {/* <ul className={styles.doctorSliderPagination}>
            {(() => {
              let list = [0, 1, 2, 3, 4, 5, 6, 7];
              return list.map((val) => (
                <li
                  key={val}
                  className={doctorSliderIdx == val ? styles.hit : ''}
                >
                  <div></div>
                </li>
              ));
            })()}
          </ul> */}
          </div>
        </div>
        <div className={styles.ondocService}>
          <p>
            다이어트닥터는
            <br /> 쉽고 편리한
            <br />
            원격 진료 서비스를 제공합니다.
          </p>
          <p>
            다이어트닥터는 내원 없이 간편하게 받아보는 원격 진료 서비스로
            <br />
            다이어트닥터의 의료진과 함께 가벼운 증상부터 만성질환까지 의료
            서비스 이용에
            <br />
            어려움을 겪는 분들에게 빠르고 안전한, 쉽고 편리한
            <br />
            원격 진료 서비스를 제공합니다.
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Home;
