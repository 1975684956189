import styles from './CouponModal.module.scss';
import leftArrow from '../../assets/leftArrow02.png';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { ICouponType } from '../../pages/Order/Order';

interface IPropsType {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  couponList: ICouponType[];
  useCoupon: ICouponType | undefined;
  setUseCoupon: React.Dispatch<React.SetStateAction<ICouponType | undefined>>;
}
function CouponModal(props: IPropsType) {
  const [selectedCoupon, setSelectedCoupon] = useState<ICouponType>();
  useEffect(() => {
    setSelectedCoupon(props.useCoupon);
  }, []);
  function handleSubmit() {
    props.setUseCoupon(selectedCoupon);
    props.setOpen(false);
  }

  console.log(selectedCoupon);
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <h3 className={styles.header}>
          <img
            src={leftArrow}
            alt="leftArrow"
            onClick={() => props.setOpen(false)}
          />
          <span>쿠폰선택</span>
        </h3>
        <div className={styles.couponListWrap}>
          <div className={styles.useableCouponWrap}>
            <span>
              사용가능 쿠폰
              <span>
                {
                  props.couponList.filter(
                    (coupon) =>
                      coupon.status == 'N' && coupon.is_useable == 'Y',
                  ).length
                }
              </span>
              장
            </span>
            <ul className={styles.useableCouponList}>
              {props.couponList
                .filter(
                  (coupon) => coupon.status == 'N' && coupon.is_useable == 'Y',
                )
                .map((coupon) => (
                  <li
                    key={coupon.id}
                    onClick={() => {
                      if (coupon.id !== selectedCoupon?.id)
                        setSelectedCoupon(coupon);
                      else setSelectedCoupon(undefined);
                    }}
                  >
                    <div
                      className={`${styles.coupon} ${
                        selectedCoupon?.id == coupon.id ? styles.hit : ''
                      }`}
                    >
                      <div className={styles.customCheckbox}></div>
                      <span>{coupon.coupon_name}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className={styles.unuseableCouponWrap}>
            <span>
              사용만료
              <span>
                {
                  props.couponList.filter((coupon) => coupon.status == 'E')
                    .length
                }
              </span>
              장
            </span>
            <ul className={styles.unuseableCouponList}>
              {props.couponList
                .filter((coupon) => coupon.status == 'E')
                .map((coupon) => (
                  <li key={coupon.id}>
                    <div className={styles.coupon}>
                      <div className={styles.customCheckbox}></div>
                      <span>{coupon.coupon_name}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className={styles.couponGuideWrap}>
          <h4>쿠폰 사용 안내</h4>
          <ul>
            <li>
              · 할인쿠폰의 할인금액이 상품의 판매가를 초과할 경우 사용이
              불가능합니다.
            </li>
            <li>
              · 할인쿠폰은 중복으로 사용할 수 없으며, 1회 결제시 1개의
              할인쿠폰만 사용 가능합니다
            </li>
            <li>
              · 주문취소 및 환불시 이미 사용한 쿠폰은 재발급되지 않습니다.
            </li>
            <li>
              · 발급된 할인쿠폰의 유효기간은 조기종료 및 변경 될 수 있습니다.
            </li>
            <li>
              · 유효기간이 지난 할인쿠폰은 자동 소멸하오니 기간 만료 전에 꼭
              사용하시기 바랍니다.
            </li>
          </ul>
        </div>

        <div className={styles.submitBtn} onClick={handleSubmit}>
          쿠폰 적용
        </div>
      </div>
    </div>
  );
}
export default CouponModal;
