import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginCheck } from '../../auth/auth';
import { getRedirectUrl } from '../../common/loginConfirm';

function LoginHandler() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!loginCheck()) navigate(`/login?rurl=${getRedirectUrl()}`);
  // }, []);
  return <></>;
}
export default LoginHandler;
