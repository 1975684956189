import styles from './HeaderMain.module.scss';
import logo from '../../assets/logo.png';
import search from '../../assets/search.png';
import hamburger from '../../assets/header/hamburger.png';
import basket from '../../assets/header/basket.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import SearchModal from '../SearchModal/SearchModal';
import { isDesktop } from 'react-device-detect';
import { loginCheck, logout } from '../../auth/auth';
import KakaoBanner from '../KakaoBanner/KakaoBanner';
import { loginConfirm, loginConfirmRurl } from '../../common/loginConfirm';
import SideMenuMobile from '../../mobileComponents/SideMenuMobile/SideMenuMobile';
interface IPropsType {
  setIsSmallType?: React.Dispatch<React.SetStateAction<boolean>>;
}
function Header(props: IPropsType) {
  const location = useLocation();
  const [isSmallType, setIsSmallType] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const movingBarRef = useRef<HTMLDivElement>(null);
  function handleNavigationBarMove(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.left = e.currentTarget.offsetLeft + 'px';
    movingBarRef.current.style.width = e.currentTarget.offsetWidth + 'px';
  }
  function handleNavigationBarHide(
    e: React.MouseEvent<HTMLUListElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.width = '0px';
  }
  function handleLogout() {
    logout();
    window.location.href = '/';
  }
  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 50) {
          setIsSmallType(true);
          if (props.setIsSmallType) props.setIsSmallType(true);
        } else {
          setIsSmallType(false);
          if (props.setIsSmallType) props.setIsSmallType(false);
        }
      }, 200),
    [],
  );
  useEffect(() => {
    if (!isDesktop) return;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (loginCheck()) setIsLogin(true);
    else setIsLogin(false);
  }, []);
  const [mobileSideOpen, setMobileSideOpen] = useState(false);
  return (
    <>
      {isDesktop ? (
        <>
          <div
            className={`${styles.heighter}`}
            style={{ height: '131px' }}
          ></div>
          <div
            className={`${styles.container} ${
              isSmallType ? styles.tiny : ''
            } container`}
          >
            <div className={styles.header}>
              <img
                src={logo}
                alt="logo"
                className={styles.logo}
                onClick={() => navigate('/')}
              />
              {/* <form
                className={styles.searchForm}
                onClick={() => setSearchOpen(true)}
              >
                <input type="text" placeholder="보감비책 1:1 맞춤 처방?" />
                <img src={search} alt="search" />
              </form> */}
              <ul className={styles.btns}>
                <li>
                  <Link to="/basket">장바구니</Link>
                </li>
                <li>
                  {!isLogin && location.pathname !== '/login' ? (
                    <Link
                      to={`/login?rurl=${location.pathname + location.search}`}
                    >
                      로그인
                    </Link>
                  ) : !isLogin && location.pathname === '/login' ? (
                    <Link to={`${location.pathname + location.search}`}>
                      로그인
                    </Link>
                  ) : (
                    <a href="#none" onClick={handleLogout}>
                      로그아웃
                    </a>
                  )}
                </li>
                <li>
                  {!isLogin ? (
                    <Link to="/join">회원가입</Link>
                  ) : (
                    <Link to="/myPage">마이페이지</Link>
                  )}
                </li>
              </ul>
            </div>
            <div className={styles.navigation}>
              <ul onMouseLeave={(e) => handleNavigationBarHide(e)}>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate(`/companyInfo`)}
                >
                  <span>다이어트닥터</span>
                </li>
                {/* <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate(`/program-list`)}
                >
                  <span>병원진료</span>
                </li> */}
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate('/productList')}
                >
                  <span>상품</span>
                  <ul>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/productList');
                      }}
                    >
                      전체상품
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/productList?cuid=487');
                      }}
                    >
                      처방의약품
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/productList?cuid=488');
                      }}
                    >
                      건강식품
                    </li>
                  </ul>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => {
                    if (!loginConfirmRurl('/myWeight')) return;
                    navigate('/myWeight');
                  }}
                >
                  <span>챌린지</span>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => {
                    if (!isLogin) navigate('/event');
                    else navigate('/review/all');
                  }}
                >
                  <span>커뮤니티</span>
                  <ul>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/notice');
                      }}
                    >
                      공지사항
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/event');
                      }}
                    >
                      이벤트
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!loginConfirmRurl('/review/all')) return;
                        navigate('/review/all');
                      }}
                    >
                      상품 후기
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/faq');
                      }}
                    >
                      FAQ
                    </li>
                  </ul>
                </li>
                <div ref={movingBarRef} className={styles.movingBar}></div>
              </ul>
            </div>
            {/* <KakaoBanner /> */}
          </div>
          {searchOpen && <SearchModal setOpen={setSearchOpen} />}
        </>
      ) : (
        <>
          <div className={styles.mContainer}>
            <img
              src={logo}
              alt="logo"
              className={styles.logo}
              onClick={() => navigate('/')}
            />
            <div className={styles.btns}>
              {/* <img src={search} alt="search" className={styles.search} /> */}
              <img
                src={basket}
                alt="basket"
                className={styles.basket}
                onClick={() => navigate('/basket')}
              />
              <img
                src={hamburger}
                alt="hamburger"
                className={styles.hamburger}
                onClick={() => setMobileSideOpen(true)}
              />
            </div>
          </div>
          <div className={styles.mHeighter}></div>
          {mobileSideOpen && <SideMenuMobile isOpen={setMobileSideOpen} />}
        </>
      )}
    </>
  );
}
export default Header;
