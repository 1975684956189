import { isDesktop } from 'react-device-detect';
import styles from './Loading.module.scss';
import slide from '../../assets/slide.png';
import close from '../../assets/close.png';
import { useNavigate } from 'react-router-dom';
import useMobileSlide from '../../hook/MobileSlide';
import loading from '../../assets/loading.png';
import { useEffect, useState } from 'react';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}

// function Loading(props: IPropsType) {
function Loading({ onLoadFinish }: any) {
  const navigate = useNavigate();
  // const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
  //   useMobileSlide({ closer: props.closer });

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          onLoadFinish();
          return 100;
        }
        return prev + 1;
      });
    }, 30);

    return () => clearInterval(interval);
  }, []);

  const [progress, setProgress] = useState(0);

  return (
    <div className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}>
      <div
        className={styles.contentWrap}
        // ref={modalRef}
        // onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        // onTouchEnd={handleTouchEnd}
      >
        {isDesktop ? (
          <div className={styles.closeWrap}>
            <img
              src={close}
              alt="close"
              onClick={() => {
                navigate('?');
              }}
            />
          </div>
        ) : (
          <div className={styles.slideWrap}>
            <img src={slide} alt="slide" />
          </div>
        )}
        <div className={styles.mainWrap}>
          <div className={styles.topWrap}>
            <div className={styles.imgWrap}>
              <img src={loading} alt="loading" />
            </div>
            <div className={styles.alertWrap}>
              고객님에게 딱! 맞는
              <br /> <span className={styles.hit}>추천 단계</span>가 무엇일지
              <br /> 응답을 분석하고 있어요.
            </div>
            <span>잠시만 기다려주세요!</span>
          </div>
          <div className={styles.barWrap}>
            <div className={styles.progressBar}>
              <div
                className={styles.progress}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <span>분석 중..</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
