import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import styles from './WeightList.module.scss';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import pointImg from '../../assets/pointImg.png';
import chart from '../../assets/weightChallenge/chart.png';
import prevBtn from '../../assets/weightChallenge/prevBtn.png';
import dateLeft from '../../assets/weightChallenge/dateLeft.png';
import dateRight from '../../assets/weightChallenge/dateRight.png';
import dateLeftNo from '../../assets/weightChallenge/dateLeftNo.png';
import dateRightNo from '../../assets/weightChallenge/dateRightNo.png';
import weightUp from '../../assets/weightChallenge/weightUp.png';
import weightDown from '../../assets/weightChallenge/weightDown.png';

export interface IWeightType {
  content: string;
  created_at: string;
  loseweight: number;
}

function WeightList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabMenuSelect, setTabMenuSelect] = useState('');
  const [weeklyData, setWeeklyData] = useState<any[]>([]);
  const [monthlyData, setMonthlyData] = useState<any[]>([]);
  const [avgWeekWeight, setAvgWeekWeight] = useState<Number>(0);
  const [loseWeightWeek, setLoseWeightWeek] = useState<String>('0');
  const [avgMonthWeight, setAvgMonthWeight] = useState<Number>(0);
  const [loseWeightMonth, setLoseWeightMonth] = useState<String>('0');
  const [monthState, setMonthState] = useState<Number>();
  const [yearState, setYearState] = useState<Number>();
  const [challengePartiDay, setChallengePartiDay] = useState();
  const [activeItem, setActiveItem] = useState('weekList');

  let sum = 0;
  let avg;
  let loseSum = 0;
  let sumMonth = 0;
  let avgMonth;
  let loseSumMonth = 0;
  let weeklyWeightFilter: string | any[] = [];
  let monthlyWeightFilter: string | any[] = [];
  let weekStartDate: string | number | Date;
  let weekEndDate: string | number | Date;
  let monthStartDate: string | number | Date;
  let monthEndDate: string | number | Date;
  let challengeLogLength: number;
  let monthRef = useRef<number>();
  let yearRef = useRef<number>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get('date-tab')) setTabMenuSelect('주별');
    else if (params.get('date-tab') === 'month') {
      setTabMenuSelect('월별');
      setActiveItem('monthList');
    }
  }, [location]);

  const weekAvgWeightLoseWeight = () => {
    sum = 0;
    loseSum = 0;

    if (weeklyWeightFilter.length !== 0) {
      for (let i = 0; i < weeklyWeightFilter.length; i++) {
        sum += Number(weeklyWeightFilter[i].content);
        loseSum += Number(weeklyWeightFilter[i].loseweight);
      }

      avg = (sum / weeklyWeightFilter.length).toFixed(2);
      setAvgWeekWeight(Number(avg));
      if (Number(loseSum.toFixed(2)) > 0)
        setLoseWeightWeek('+' + Number(loseSum.toFixed(2)));
      else if (Number(loseSum.toFixed(2)) < 0)
        setLoseWeightWeek(Number(loseSum.toFixed(2)).toString().slice(1));
      else setLoseWeightWeek('0');
    } else {
      setAvgWeekWeight(0);
      setLoseWeightWeek('0');
    }
  };

  const monthAvgWeightLoseWeight = () => {
    sumMonth = 0;
    loseSumMonth = 0;

    if (monthlyWeightFilter.length !== 0) {
      for (let i = 0; i < monthlyWeightFilter.length; i++) {
        sumMonth += Number(monthlyWeightFilter[i].content);
        loseSumMonth += Number(monthlyWeightFilter[i].loseweight);
      }
      avgMonth = (sumMonth / monthlyWeightFilter.length).toFixed(2);
      setAvgMonthWeight(Number(avgMonth));
      if (Number(loseSumMonth.toFixed(2)) > 0)
        setLoseWeightMonth('+' + Number(loseSumMonth.toFixed(2)));
      else if (Number(loseSumMonth.toFixed(2)) < 0)
        setLoseWeightMonth(Number(loseSumMonth.toFixed(2)).toString().slice(1));
      else setLoseWeightMonth('0');
    } else {
      setAvgMonthWeight(0);
      setLoseWeightMonth('0');
    }
  };

  const weeklyDataConditionImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-${nowMonth}-01`)
      .then(function (res) {
        setWeeklyData(res.data.challenge_log);
        setChallengePartiDay(res.data.challenge_participation_period);
        weeklyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        weekAvgWeightLoseWeight();
      });
  };

  const weeklyDataImport = () => {
    axiosClient.get(`/data/challenge/my/1`).then(function (res) {
      challengeLogLength = res.data.challenge_log.length;
      weekStartDate = res.data.challenge_log[0].start_date;
      weekEndDate = res.data.challenge_log[challengeLogLength - 1].end_date;

      if (nowDate < new Date(weekStartDate)) {
        if (nowMonth !== 1) {
          nowMonth -= 1;
          setMonthState(nowMonth);
        } else {
          nowMonth = 12;
          nowYear -= 1;
          setMonthState(nowMonth);
          setYearState(nowYear);
        }
        monthRef.current = nowMonth;
        yearRef.current = nowYear;
      } else if (nowDate > new Date(weekEndDate)) {
        if (nowMonth !== 12) {
          nowMonth += 1;
          setMonthState(nowMonth);
        } else {
          nowMonth = 1;
          nowYear += 1;
          setMonthState(nowMonth);
          setYearState(nowYear);
        }
        monthRef.current = nowMonth;
        yearRef.current = nowYear;
      } else {
        monthRef.current = nowMonth;
        yearRef.current = nowYear;
        setWeeklyData(res.data.challenge_log);
        setChallengePartiDay(res.data.challenge_participation_period);
        weeklyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        weekAvgWeightLoseWeight();
        return;
      }
      weeklyDataConditionImport();
    });
  };

  const monthlyDataConditionImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-01-01&unit=monthly`)
      .then(function (res) {
        setMonthlyData(res.data.challenge_log);
        monthlyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        monthAvgWeightLoseWeight();
      });
  };

  const monthlyDataImport = () => {
    axiosClient.get(`/data/challenge/my/1?unit=monthly`).then(function (res) {
      challengeLogLength = res.data.challenge_log.length;
      monthStartDate = res.data.challenge_log[0].start_date;
      monthEndDate = res.data.challenge_log[challengeLogLength - 1].end_date;

      if (nowDate < new Date(monthStartDate)) {
        if (nowYear === nowYearStore) nowYear -= 1;
        yearRef.current = nowYear;
        setYearState(nowYear);
      } else if (nowDate > new Date(monthEndDate)) {
        if (nowYear === nowYearStore) nowYear += 1;
        yearRef.current = nowYear;
        setYearState(nowYear);
      } else {
        yearRef.current = nowYear;
        setMonthlyData(res.data.challenge_log);
        monthlyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        monthAvgWeightLoseWeight();
        return;
      }
      monthlyDataConditionImport();
    });
  };

  useEffect(() => {
    // 몸무게 조회 (주별)
    weeklyDataImport();

    // 몸무게 조회 (월별)
    monthlyDataImport();
  }, []);

  // 날짜지정하기!!
  const nowDate = new Date();
  let nowYear = nowDate.getFullYear();
  let nowMonth = nowDate.getMonth() + 1;

  let nowYearStore = nowDate.getFullYear();
  useEffect(() => {
    const month = nowDate.getMonth() + 1;
    setMonthState(month);

    const year = nowDate.getFullYear();
    setYearState(year);
  }, []);

  // 주별 날짜 변경
  const dateWeekClick = (dateNum: number) => {
    let monthStateChange = Number(monthState);
    let yearStateChange = Number(yearState);

    if (dateNum === -1) {
      if (Number(monthState) > 1 && Number(monthState) < 13) {
        monthStateChange = Number(monthState) + dateNum;
        setMonthState(Number(monthState) + dateNum);
      } else if (Number(monthState) === 1) {
        monthStateChange = Number(monthState) + 11;
        yearStateChange = Number(yearState) + dateNum;
        setMonthState(Number(monthState) + 11);
        setYearState(Number(yearState) + dateNum);
      }
    } else if (dateNum === +1) {
      if (Number(monthState) > 0 && Number(monthState) < 12) {
        monthStateChange = Number(monthState) + dateNum;
        setMonthState(Number(monthState) + dateNum);
      } else if (Number(monthState) === 12) {
        monthStateChange = Number(monthState) - 11;
        yearStateChange = Number(yearState) + dateNum;
        setMonthState(Number(monthState) - 11);
        setYearState(Number(yearState) + dateNum);
      }
    }

    const monthStateChangeZero =
      monthStateChange <= 9 ? '0' + monthStateChange : monthStateChange;

    axiosClient
      .get(
        `/data/challenge/my/1?date_str=${yearStateChange}-${monthStateChangeZero}-01`,
      )
      .then(function (res) {
        setWeeklyData(res.data.challenge_log);
        weeklyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );

        weekAvgWeightLoseWeight();
      });
  };

  // 월별 날짜 변경
  const dateMonthClick = (dateNum: number) => {
    let yearStateChange = Number(yearState);

    setYearState(Number(yearState) + dateNum);
    yearStateChange += dateNum;

    axiosClient
      .get(
        `/data/challenge/my/1?date_str=${yearStateChange}-01-01&unit=monthly`,
      )
      .then(function (res) {
        setMonthlyData(res.data.challenge_log);
        monthlyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        monthAvgWeightLoseWeight();
      });
  };

  const tabMenuClick = (item: string) => {
    setActiveItem(item);
    setYearState(nowYear);
    setMonthState(nowMonth);

    if (item === 'weekList') {
      weeklyDataImport();
      navigate('/myWeight/list');
    } else if (item === 'monthList') {
      monthlyDataImport();
      navigate('/myWeight/list?date-tab=month');
    }
  };

  return (
    <>
      <Header />
      {isMobile && (
        <div className={styles.prevWrap} onClick={() => navigate('/myWeight')}>
          <img src={prevBtn} alt={prevBtn} />
        </div>
      )}
      <div className={styles.navigation}>
        <ul>
          <li
            className={activeItem === 'weekList' ? styles.active : ''}
            onClick={() => tabMenuClick('weekList')}
          >
            <span>주별</span>
          </li>
          <li
            className={activeItem === 'monthList' ? styles.active : ''}
            onClick={() => tabMenuClick('monthList')}
          >
            <span>월별</span>
          </li>
        </ul>
      </div>
      <main className={styles.contentWrap}>
        <div
          className={`${styles.dateWrap} ${!isMobile ? styles.isDesktop : ''}`}
        >
          {tabMenuSelect === '주별' && (
            <h3>
              {Number(yearState) <= 2023 && Number(monthState) <= 9 ? (
                <img src={dateLeftNo} alt={dateLeftNo} />
              ) : (
                <img
                  onClick={() => dateWeekClick(-1)}
                  src={dateLeft}
                  alt={dateLeft}
                />
              )}
              {yearState?.toString()}년 {monthState?.toString()}월
              {Number(yearState) === yearRef.current &&
              Number(monthState) === monthRef.current ? (
                <img src={dateRightNo} alt={dateRightNo} />
              ) : (
                <img
                  onClick={() => dateWeekClick(+1)}
                  src={dateRight}
                  alt={dateRight}
                />
              )}
            </h3>
          )}
          {tabMenuSelect === '월별' && (
            <h3>
              {Number(yearState) <= 2023 ? (
                <img src={dateLeftNo} alt={dateLeftNo} />
              ) : (
                <img
                  onClick={() => dateMonthClick(-1)}
                  src={dateLeft}
                  alt={dateLeft}
                />
              )}
              {yearState?.toString()}년
              {Number(yearState) === yearRef.current ? (
                <img src={dateRightNo} alt={dateRightNo} />
              ) : (
                <img
                  onClick={() => dateMonthClick(+1)}
                  src={dateRight}
                  alt={dateRight}
                />
              )}
            </h3>
          )}
          <p>{challengePartiDay}일째 챌린지 참여 중이에요!</p>
        </div>
        <div
          className={`${styles.listWrap} ${
            !isMobile && tabMenuSelect === '월별'
              ? styles.isDesktop
              : !isMobile && tabMenuSelect === '주별'
              ? styles.isWeekDesktop
              : ''
          }`}
        >
          {/* <div className={`${styles.listWrap} ${!isMobile ? styles.isWeekDesktop: ''}`}> */}

          {tabMenuSelect === '주별' &&
            weeklyData &&
            weeklyData.map((item, idx) => (
              <div className={styles.list} key={idx}>
                {new Date(item.start_date) <= nowDate &&
                new Date(item.end_date) >= nowDate ? (
                  <div className={styles.nowTitle}>
                    <p className={styles.nowTitleP}>{idx + 1}주</p>
                  </div>
                ) : (
                  <div>
                    <p className={styles.notNowTitleP}>{idx + 1}주</p>
                  </div>
                )}

                {new Date(item.start_date) <= nowDate &&
                new Date(item.end_date) >= nowDate ? (
                  <div>
                    {Number(item.content) === 0 && <span>- </span>}
                    {Number(item.content) > 0 && (
                      <span className={styles.nowWeightSpan}>
                        {Number(item.content)}kg
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    {Number(item.content) === 0 && <span>- </span>}
                    {Number(item.content) > 0 && (
                      <span>{Number(item.content)}kg</span>
                    )}
                  </div>
                )}
                <div>
                  {item.loseweight < 0 && (
                    <img src={weightDown} alt={weightDown} />
                  )}
                  {item.loseweight > 0 && <img src={weightUp} alt={weightUp} />}
                  <span>
                    {Number(item.loseweight.toString().replace(/[^0-9.]/g, ''))}
                  </span>
                </div>
              </div>
            ))}
          {tabMenuSelect === '월별' &&
            monthlyData &&
            monthlyData.map((item, idx) => (
              <div
                className={`${styles.list} ${
                  !isMobile ? styles.isDesktop : styles.isMobile
                }`}
                key={idx}
              >
                {idx + 1 === monthRef.current &&
                yearState === yearRef.current ? (
                  <div className={styles.nowTitle}>
                    <p className={styles.nowTitleP}>{Number(item.month)}월</p>
                  </div>
                ) : (
                  <div>
                    {yearState === yearRef.current &&
                    item.month > Number(monthRef.current) ? (
                      <p
                        style={{
                          color: '#666666',
                        }}
                      >
                        {Number(item.month)}월
                      </p>
                    ) : (
                      <p>{Number(item.month)}월</p>
                    )}
                  </div>
                )}
                {/* 현재 월 제외 */}
                {idx + 1 !== monthRef.current && (
                  <div>
                    {Number(item.content) === 0 &&
                      yearState !== yearRef.current && <span>- </span>}

                    {Number(item.content) === 0 &&
                      yearState === yearRef.current &&
                      item.month < Number(monthRef.current) && <span>- </span>}

                    {Number(item.content) === 0 &&
                      yearState === yearRef.current &&
                      item.month > Number(monthRef.current) && <span> </span>}

                    {Number(item.content) > 0 && (
                      <span>{Number(item.content)}kg</span>
                    )}
                  </div>
                )}
                {/* 현재 월 */}
                {idx + 1 === monthRef.current && (
                  <div>
                    {Number(item.content) === 0 && <span>- </span>}
                    {Number(item.content) > 0 && (
                      <span className={styles.nowWeightSpan}>
                        {Number(item.content)}kg
                      </span>
                    )}
                  </div>
                )}
                <div>
                  {item.loseweight < 0 && (
                    <img src={weightDown} alt={weightDown} />
                  )}
                  {item.loseweight > 0 && <img src={weightUp} alt={weightUp} />}
                  {yearState === yearRef.current &&
                  item.month > Number(monthRef.current) ? (
                    <span></span>
                  ) : (
                    <span>
                      {Number(
                        item.loseweight.toString().replace(/[^0-9.]/g, ''),
                      )}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
        {isMobile && <div className={styles.mHeight}></div>}
        <div
          className={`${styles.weightWrap} ${
            !isMobile ? styles.isDesktop : styles.isMobile
          }`}
        >
          <div className={styles.weightInfo}>
            <div>
              <p>평균 체중</p>
              {tabMenuSelect === '주별' && <p>{Number(avgWeekWeight)}kg</p>}
              {tabMenuSelect === '월별' && <p>{Number(avgMonthWeight)}kg</p>}
            </div>
            <div></div>
            <div>
              <p>총 감량 체중</p>
              {tabMenuSelect === '주별' && <p>{loseWeightWeek}kg</p>}
              {tabMenuSelect === '월별' && <p>{loseWeightMonth}kg</p>}
            </div>
          </div>
          <div className={styles.weightBtnWrap}>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/myWeight/chart')}
            >
              <img src={chart} alt={chart} />
              <span>그래프보기</span>
            </button>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/myWeight/point')}
            >
              <img className={styles.pointImg} src={pointImg} alt={pointImg} />
              <span>리워드보기</span>
            </button>
          </div>
        </div>
      </main>
    </>
  );
}

export default WeightList;
