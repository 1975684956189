import styles from './ADLink.module.scss';
import content from '../../assets/adLink/content03.png';
import content4 from '../../assets/adLink/content04.png';
import content5 from '../../assets/adLink/content05.png';
import content6 from '../../assets/adLink/content06.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import close from '../../assets/closeBtn03.png';

interface IPropsType {
  productId: number;
  productName: string;
  price: number;
  discountedPrice: number;
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}
function ADLink({
  productId,
  productName,
  price,
  discountedPrice,
  closer,
}: IPropsType) {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <button className={styles.closeBtn} onClick={() => closer(false)}>
          <img src={close} alt="닫기" />
        </button>
        <div
          className={styles.content}
          onClick={() =>
            (window.location.href = `https://dietdr.co.kr/productDetail/${productId}`)
          }
        >
          {productName.replace(/ /g, '').includes('보감비책다이어트정') &&
            params.productId !== '70' && <img src={content} alt="상품" />}
          {productName.replace(/ /g, '').includes('보감비책다이어트정') &&
            params.productId === '70' && <img src={content5} alt="상품" />}
          {productName.replace(/ /g, '').includes('보감비책마이크로환') &&
            params.productId !== '75' && <img src={content4} alt="상품" />}
          {productName.replace(/ /g, '').includes('보감비책마이크로환') &&
            params.productId === '75' && <img src={content6} alt="상품" />}
        </div>
        <button
          className={styles.detailBtn}
          onClick={() =>
            (window.location.href = `https://dietdr.co.kr/productDetail/${productId}`)
          }
        ></button>
        {/* <div className={styles.btns}>
          <button className={styles.closeBtn} onClick={() => closer(false)}>
            닫기
          </button>
          <button
            className={styles.detailBtn}
            onClick={() =>
              (window.location.href = `https://dietdr.co.kr/productDetail/${productId}`)
            }
          >
            자세히 보기
          </button>
        </div> */}
      </div>
    </div>
  );
}
export default ADLink;
