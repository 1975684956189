import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './MyPage.module.scss';
import rightArrow from '../../assets/rightArrow.png';
import rightArrow2 from '../../assets/rightArrow04.png';
import rightArrow3 from '../../assets/rightArrow03.png';
import basket from '../../assets/myPage/basket.png';
import point from '../../assets/myPage/point.png';
import review from '../../assets/myPage/review.png';
import coupon from '../../assets/myPage/coupon.png';
import heart from '../../assets/myPage/heart.png';
import card from '../../assets/myPage/card.png';
import { useNavigate } from 'react-router-dom';
import OrderFrame from '../../components/OrderFrame/OrderFrame';
import { useEffect, useState } from 'react';
import { ITokenInfoType, logout } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import axiosClient from '../../libs/axiosClient';
import { IOrderDataType } from '../OrderComplete/OrderComplete';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
import dietDoctorMark from '../../assets/dietDoctorMark.png';
import challenge from '../../assets/myPage/challenge.png';
import bannerScaleImg from '../../assets/bannerScaleImg.png';
import referral from '../../assets/myPage/referral.png';
import referralCodeLogo from '../../assets/myPage/referralCode.png';
import copy from '../../assets/myPage/copy.png';
import { isDesktop, isMobile } from 'react-device-detect';
import ChallengeSubmitModal from '../../components/ChallengeSubmitModal/ChallengeSubmitModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
export interface IMyPageInfoType {
  cart_count: number;
  member_point: number;
  order_count: {
    결제대기: number;
    결제완료: number;
    배송완료: number;
    배송준비: number;
    배송중: number;
    주문취소: number;
  };
}
function MyPage() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<ITokenInfoType>();
  const [inputWeight, setInputWeight] = useState('');
  const [inputWeekData, setInputWeekData] = useState<any[]>([]);
  const [isInputFocused, setIsInputFocused] = useState(false); // input focus
  const [challengeSubmitModalOpen, setChallengeSubmitModalOpen] =
    useState(false);
  const [challengeParticipation, setChallengeParticipation] =
    useState<String>();
  const [challengeNextStartDay, setChallengeNextStartDay] = useState<String>();
  const [challengeNextEndDay, setChallengeNextEndDay] = useState<String>();
  const [todayDate, setTodayDate] = useState('');
  const [weekDay, setWeekDay] = useState([
    '일',
    '월',
    '화',
    '수',
    '목',
    '금',
    '토',
  ]);
  const [referralCode, setReferralCode] = useState<string>('');

  let startDay;
  let endDay;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null || token == 'null' || !token) return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setUserInfo(decodedUserInfo);
    } catch (err) {
      logout();
    }
  }, []);
  const [orderData, setOrderData] = useState<IOrderDataType[]>();
  useEffect(() => {
    axiosClient
      .get('/data/order?limit=3')
      .then((res) => setOrderData(res.data.data));
  }, []);

  const [myPageInfo, setMyPageInfo] = useState<IMyPageInfoType>();
  useEffect(() => {
    axiosClient.get('/data/mypage').then((res) => {
      setMyPageInfo(res.data.data);
    });
  }, []);

  useEffect(() => {
    const date = new Date();
    const today =
      date.getMonth() +
      1 +
      '월 ' +
      date.getDate() +
      '일 ' +
      weekDay[date.getDay()] +
      '요일';
    setTodayDate(today);
  });

  const onChangeWeight = (e: any) => {
    const weightValue = e.target.value;
    const weightNumber = weightValue.replace(/[^0-9.]/g, ''); // 숫자와 .만 입력되도록
    const weightZeroRem = weightNumber.replace(/(^0+)/, ''); // 맨처음 0 삭제
    setInputWeight(weightZeroRem);
    // setInputWeight(e.target.value);
  };

  const onKeyUpWeight = (e: any) => {
    let weightValue = e.target.value;

    if (weightValue.charAt(0) === '.') {
      weightValue = weightValue.substring(1);
    }
    const parts = weightValue.split('.');
    if (parts.length > 2) {
      weightValue = parts[0] + '.' + parts[1];
    }
    const regex = /^\d{0,3}(\.\d{0,2})?$/;
    if (!regex.test(weightValue)) {
      weightValue = weightValue.match(/^\d{0,3}(\.\d{0,2})?/)[0];
    }
    setInputWeight(weightValue);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const weight = e.target.weight.value.toString();
    const weightRegux = /^(?!0\d)([1-9]\d*|0)(\.\d{1,2})?$/;
    let formData = new FormData();
    formData.append('content', weight);

    if (weight === '') return;
    if (weightRegux.test(weight) && challengeParticipation === 'N') {
      setChallengeParticipation('Y');
      // 몸무게 등록
      axiosClient.post('/func/challenge/1/apply', formData).then((res) => {
        setChallengeSubmitModalOpen(true);
      });
    } else {
      alert('몸무게를 올바르게 작성해주세요.');
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  useEffect(() => {
    axiosClient.get(`/data/challenge/my/1`).then(function (res) {
      setInputWeekData(res.data.challenge_log);
    });

    axiosClient
      .get(`/data/challenge/my/1/is_participated`)
      .then(function (res) {
        setChallengeParticipation(res.data.data.is_participated);
        startDay = new Date(res.data.data.next_start_date);
        endDay = new Date(res.data.data.next_end_date);
        setChallengeNextStartDay(
          startDay.getMonth() + 1 + '월 ' + startDay.getDate() + '일',
        );
        setChallengeNextEndDay(
          endDay.getMonth() + 1 + '월 ' + endDay.getDate() + '일',
        );
      });
  }, []);

  useEffect(() => {
    axiosClient
      .get('/data/referrer/code', {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        setReferralCode(res.data.data.code);
      });
  }, []);
  return (
    <div>
      <LoginHandler />
      <Header />
      <div className={styles.user}>
        <div className={styles.mark}>
          <img src={dietDoctorMark} alt="" />
        </div>
        <div
          className={styles.userNameWrap}
          onClick={() => navigate('/updateProfile')}
        >
          <span>{userInfo?.name} 님</span>
          <img src={rightArrow} alt="arrow" />
        </div>
        <ul className={styles.menuList}>
          <li className={styles.countMenu} onClick={() => navigate('/basket')}>
            <div>
              <img src={basket} alt="basket" />
              <span>장바구니</span>
            </div>
            <span className={styles.count}>{myPageInfo?.cart_count}</span>
          </li>
          <li className={styles.countMenu} onClick={() => navigate('/point')}>
            <div>
              <img className={styles.pointImg} src={point} alt="point" />
              <span>적립금</span>
            </div>
            <span className={styles.count}>
              {myPageInfo?.member_point.toLocaleString()}
            </span>
          </li>
          <li className={styles.normalMenu} onClick={() => navigate('/pick')}>
            <img src={heart} alt="heart" />
            <span>찜한상품</span>
          </li>
          <li className={styles.normalMenu} onClick={() => navigate('/coupon')}>
            <img src={coupon} alt="coupon" />
            <span>쿠폰</span>
          </li>
          <li
            className={styles.normalMenu}
            onClick={() => navigate('/myReview')}
          >
            <img src={review} alt="review" />
            <span>후기</span>
          </li>
          <li className={styles.normalMenu} onClick={() => navigate('/myCard')}>
            <img src={card} alt="card" />
            <span>간편결제</span>
          </li>
          <li
            className={styles.normalMenu}
            onClick={() => navigate('/myWeight')}
          >
            <img src={challenge} alt="challenge" />
            {isMobile && <span>챌린지</span>}
            {isDesktop && <span>나의챌린지</span>}
          </li>
          <li
            className={styles.referralMenu}
            onClick={() => navigate('/referral')}
          >
            <div>
              <img src={referral} alt="referral" />
              <span>추천인</span>
            </div>
          </li>
          <li className={styles.referralMenu}>
            <div>
              <img
                className={styles.pointImg}
                src={referralCodeLogo}
                alt="referralCode"
              />
              <span>추천인 코드</span>
            </div>
            {isDesktop && (
              <span
                style={{
                  marginLeft: '8px',
                }}
              >
                {referralCode}
              </span>
            )}

            <CopyToClipboard
              text={referralCode}
              onCopy={() => console.log('복사완료')}
            >
              <span
                className={`${styles.copy} ${isMobile ? styles.isMobile : ''}`}
                onClick={() => navigate('/myPage')}
              >
                <img src={copy} alt="copy" />
                복사
              </span>
            </CopyToClipboard>
          </li>
        </ul>
      </div>
      <div
        className={`${styles.challengeWrap} ${isMobile ? styles.isMobile : ''}`}
      >
        {isDesktop && (
          <div className={styles.title}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <circle cx="9" cy="9" r="7" stroke="black" />
              <path d="M9 5V9H13" stroke="black" strokeLinecap="square" />
            </svg>
            <span>{todayDate}</span>
            <h2>체중 감량 챌린지</h2>
          </div>
        )}
        {isMobile && (
          <div className={`${styles.title} ${isMobile ? styles.isMobile : ''}`}>
            <span>체중 감량 챌린지</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle cx="9" cy="9" r="7" stroke="black" />
                <path d="M9 5V9H13" stroke="black" strokeLinecap="square" />
              </svg>
              {todayDate}
            </span>
          </div>
        )}

        <div
          className={`${styles.weightForm} ${isMobile ? styles.isMobile : ''}`}
        >
          <form onSubmit={onSubmit}>
            <div className={styles.weightInputWrap}>
              {challengeParticipation === 'Y' ? (
                <input
                  value={''}
                  placeholder="체중을 입력해주세요   000.00"
                  disabled
                  style={{
                    background: '#fff',
                  }}
                />
              ) : (
                <input
                  type="text"
                  name="weight"
                  value={inputWeight}
                  onChange={onChangeWeight}
                  onKeyUp={onKeyUpWeight}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="체중을 입력해주세요   000.00"
                />
              )}
              <span>kg</span>
            </div>
            {challengeParticipation === 'Y' ? (
              <button
                type="button"
                className={styles.unableBtn}
                disabled={false}
              >
                입력하기
              </button>
            ) : (
              <button type="submit" className={styles.inputBtn}>
                입력하기
              </button>
            )}
          </form>
          {challengeParticipation === 'Y' && isMobile && (
            <div
              className={`${styles.bannerWrap} ${
                isMobile ? styles.isMobile : styles.isDesktop
              }`}
            >
              <div className={styles.bannerTextOk}>
                <p>이번 주</p>
                <span>체중이 입력 완료</span>
                <span>됐습니다.</span>
              </div>
              <div className={styles.bannerTextDate}>
                <span>다음 입력 기간: </span>
                <span>
                  {challengeNextStartDay} ~ {challengeNextEndDay}
                </span>
              </div>
              <div className={styles.bannerImg}>
                <img src={bannerScaleImg} alt="weightInputOk" />
              </div>
            </div>
          )}
          {challengeParticipation === 'Y' && isDesktop && (
            <div
              className={`${styles.bannerWrap} ${
                isMobile ? styles.isMobile : styles.isDesktop
              }`}
            >
              <div className={styles.bannerTextOk}>
                <span>이번 주 </span>
                <span>체중이 입력 완료</span>
                <span>됐습니다.</span>
              </div>
              <div className={styles.bannerTextDate}>
                <span>다음 입력 기간: </span>
                <span>
                  {challengeNextStartDay} ~ {challengeNextEndDay}
                </span>
              </div>
              <img src={bannerScaleImg} alt="weightInputOk" />
            </div>
          )}
        </div>
      </div>
      <div className={styles.orderStatus}>
        <h3>진행중인 주문</h3>
        <div className={styles.statusList}>
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.결제대기 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.결제대기}</span>
            <span>결제대기</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.결제완료 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.결제완료}</span>
            <span>결제완료</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송준비 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송준비}</span>
            <span>한약조제중</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송중 > 0 ? styles.hit : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송중}</span>
            <span>배송중</span>
          </div>
          <img src={rightArrow3} alt="rightArrow" />
          <div
            className={`${styles.status} ${
              myPageInfo && myPageInfo?.order_count.배송완료 > 0
                ? styles.hit
                : ''
            }`}
          >
            <span>{myPageInfo && myPageInfo?.order_count.배송완료}</span>
            <span>배송완료</span>
          </div>
        </div>
        <p className={styles.orderGuide}>
          진행중인 주문 주문 및 배송 사이에 약재 수급에 따라 진료 완료일로부터
          2~3일정도 소요될 수 있습니다.
        </p>
      </div>
      <div className={styles.userOrderWrap}>
        <h3>주문 및 배송</h3>
        <ul className={styles.orderListWrap}>
          {orderData?.map((order) => (
            <li key={order.order_id}>
              <OrderFrame page={'myPage'} orderData={order} />
            </li>
          ))}
        </ul>
        <div
          className={styles.moreOrderBtn}
          onClick={() => navigate('/myOrder')}
        >
          주문내역 더보기
        </div>
      </div>
      <div className={styles.event} onClick={() => navigate('/event')}>
        <span>이벤트</span>
        <img src={rightArrow2} alt="arrow" />
      </div>
      <div className={styles.faq} onClick={() => navigate('/faq')}>
        <span>FAQ</span>
        <img src={rightArrow2} alt="arrow" />
      </div>
      {challengeSubmitModalOpen && (
        <ChallengeSubmitModal closer={setChallengeSubmitModalOpen} />
      )}
      <Footer />
    </div>
  );
}
export default MyPage;
