import { Link } from 'react-router-dom';
import styles from './ReviewFilter.module.scss';
function ReviewFilter(props: { borderRadius?: number }) {
  return (
    <div
      className={styles.reviewFilter}
      style={{ borderRadius: props.borderRadius == 0 ? '0' : '10px' }}
    >
      <p>
        의료법에 의거하여 의약품 후기는
        <br />
        로그인 후 보실 수 있습니다.
      </p>
      <Link
        to={`${`/login?rurl=${
          window.location.pathname + window.location.search
        }`}`}
      >
        로그인
      </Link>
    </div>
  );
}
export default ReviewFilter;
