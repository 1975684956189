import axios from 'axios';
import { loginCheck, logout, refreshTokenCheck } from '../auth/auth';
const axiosClient = axios.create({
  // baseURL: '',
  baseURL: 'https://api.dietdr.co.kr',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
    'Access-Control-Allow-Credentials': true,
    'ngrok-skip-browser-warning': '69420',
  },
});
let isTokenRefreshingReq = false;
let refreshSubscribersReq: any[] = [];
const onTokenRefreshedReq = (accessToken: string) => {
  refreshSubscribersReq.map((callback) => {
    callback(accessToken);
  });
};
const addRefreshSubscriberReq = (callback: any) => {
  refreshSubscribersReq.push(callback);
};

axiosClient.interceptors.request.use(
  async function (config) {
    if (loginCheck()) {
      //유효한 토큰 보유시
      config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;
      return config;
    } else {
      let refreshToken = localStorage.getItem('refresh-token');
      if (!refreshTokenCheck()) {
        logout();
        return config; //만료된 퇴큰 보유, 리프레시 토큰 만료 혹은 미보유 시 Authorization세팅하지 않고 그대로 요청 진행, 이후 403발생 및 response 인터셉터로 인해 로그아웃 및 홈 리다이렉트
      } else {
        //유효한 리프레시 토큰 보유시
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriberReq((accessToken: any) => {
            if (accessToken)
              config.headers!.Authorization = 'Bearer ' + accessToken;
            else delete config.headers!.Authorization;
            resolve(config);
          });
        });
        if (!isTokenRefreshingReq) {
          isTokenRefreshingReq = true;
          const res = await axios
            .post(
              `https://api.dietdr.co.kr/func/user/refresh-token`, // token refresh api
              {
                refresh_token: `Bearer ${refreshToken}`,
              },
            )
            .catch((err) => {
              return {
                data: {
                  access_token: null,
                  refresh_token: null,
                  access_token_expire: null,
                  refresh_token_expire: null,
                },
              };
            });
          // 새로운 토큰 저장
          const {
            access_token,
            refresh_token,
            access_token_expire,
            refresh_token_expire,
          } = res.data;
          localStorage.setItem('token', access_token);
          localStorage.setItem('refresh-token', refresh_token);
          localStorage.setItem('token-expire', access_token_expire);
          localStorage.setItem('refresh-token-expire', refresh_token_expire);
          isTokenRefreshingReq = false;

          if (access_token)
            axiosClient.defaults.headers.common.Authorization = `Bearer ${access_token}`;
          onTokenRefreshedReq(access_token);
        }
        return retryOriginalRequest;
      }
    }
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  },
);

let isTokenRefreshing = false;
let refreshSubscribers: any[] = [];
const onTokenRefreshed = (accessToken: any) => {
  refreshSubscribers.map((callback) => {
    console.log(callback);
    callback(accessToken);
  });
};

const addRefreshSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!error || !error.response || !error.response.status) {
      return Promise.reject(error); // 네트워크 에러로 인해 response 없을 시 단순 에러 반환
    }
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 401) {
      // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken: any) => {
          if (accessToken)
            originalRequest.headers.Authorization = 'Bearer ' + accessToken;
          else delete originalRequest.headers.Authorization;
          resolve(axiosClient(originalRequest));
        });
      });
      if (!isTokenRefreshing) {
        // isTokenRefreshing이 false인 경우에만 token refresh 요청
        isTokenRefreshing = true;
        const refreshToken = localStorage.getItem('refresh-token');
        if (!refreshToken || refreshToken == 'null') {
          logout();
          window.location.href = `/login?rurl=${
            window.location.pathname + window.location.search
          }`;
        }
        const res = await axios
          .post(
            `https://api.dietdr.co.kr/func/user/refresh-token`, // token refresh api
            {
              refresh_token: `Bearer ${refreshToken}`,
            },
          )
          .catch((err) => {
            return {
              data: {
                access_token: null,
                refresh_token: null,
                access_token_expire: null,
                refresh_token_expire: null,
              },
            };
          });
        // 새로운 토큰 저장
        const {
          access_token,
          refresh_token,
          access_token_expire,
          refresh_token_expire,
        } = res.data;
        localStorage.setItem('token', access_token);
        localStorage.setItem('refresh-token', refresh_token);
        localStorage.setItem('token-expire', access_token_expire);
        localStorage.setItem('refresh-token-expire', refresh_token_expire);
        isTokenRefreshing = false;
        if (access_token)
          axiosClient.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        else {
          logout();
          window.location.href = `/login?rurl=${
            window.location.pathname + window.location.search
          }`;
        }
        // 새로운 토큰으로 지연되었던 요청 진행
        onTokenRefreshed(access_token);
      }

      return retryOriginalRequest;
    } else if (status === 403) {
      logout();
      window.location.href = `/login?rurl=${
        window.location.pathname + window.location.search
      }`;
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
