import { isDesktop } from 'react-device-detect';
import Header from '../../components/Header/Header';
import styles from './Login.module.scss';
import loginLogo from '../../assets/loginLogo.png';
import loginBack from '../../assets/loginBack.png';
import kakaoLogo from '../../assets/kakaoLogo.png';
import userIcon from '../../assets/userIcon.png';
import passwordIcon from '../../assets/passwordIcon.png';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { KAKAO_AUTH_URL } from '../../auth/Kakao';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
function Login() {
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  let redirectURL = new URLSearchParams(location.search).get('rurl');
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    axiosClient
      .post(
        '/func/user/login',
        {
          id: id,
          password: pw,
        },
        { withCredentials: true },
      )
      .then((res) => {
        setToken(res.data);
        if (redirectURL && redirectURL !== '/join')
          window.location.href = redirectURL;
        else window.location.href = '/';
      })
      .catch((err) => {
        alert(err.response.data.detail);
      });
  }

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);
  return (
    <div className={`${styles.container} ${!isDesktop && styles.isMobile}`}>
      {isDesktop && <Header />}
      {!isDesktop && (
        <div className={styles.backBtn} onClick={() => navigate(-1)}>
          <img src={loginBack} alt="backBtn" />
        </div>
      )}
      <div className={styles.contentWrap}>
        <h2 className={styles.title}>로그인</h2>
        {/*<p className={styles.kakaoEventText}>*/}
        {/*  카카오 플러스친구 등록 3000원 쿠폰 💌*/}
        {/*</p>*/}
        <form onSubmit={(e) => handleSubmit(e)} className={styles.loginForm}>
          <div
            className={styles.kakaoLoginBtn}
            onClick={() => {
              localStorage.setItem('rurl', redirectURL || '/');
              window.location.href = KAKAO_AUTH_URL;
            }}
          >
            <img src={kakaoLogo} alt="kakaoLogo" />
            <span>카카오로 시작하기</span>
          </div>
          <div className={styles.or}>
            <div></div>
            <span>OR</span>
            <div></div>
          </div>
          <div className={styles.inputWrap}>
            <img src={userIcon} alt="userIcon" />
            <input
              type="text"
              value={id}
              onChange={(e) => setId(e.currentTarget.value)}
              onBlur={() => setId((prev) => prev.trim())}
              placeholder="아이디"
            />
          </div>
          <div className={styles.inputWrap}>
            <img src={passwordIcon} alt="passwordIcon" />
            <input
              type="password"
              value={pw}
              onChange={(e) => setPw(e.currentTarget.value)}
              onBlur={() => setPw((prev) => prev.trim())}
              placeholder="비밀번호"
            />
          </div>
          <button className={styles.loginBtn}>로그인</button>
        </form>
        <ul className={styles.menu}>
          <li onClick={() => navigate('/findAccount?tab=0')}>아이디</li>
          <li onClick={() => navigate('/findAccount?tab=1')}>비밀번호찾기</li>
          <li onClick={() => navigate('/join')}>회원가입</li>
        </ul>
      </div>
    </div>
  );
}
export default Login;
