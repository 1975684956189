import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './Point.module.scss';
import leftArrow from '../../assets/leftArrow02.png';
import rightArrow from '../../assets/rightArrow02.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import { isDesktop } from 'react-device-detect';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
interface IPointHistoryType {
  pl_adate: string;
  pl_appdate: string;
  pl_delete: string;
  pl_inid: string;
  pl_point: number;
  pl_point_after: number;
  pl_point_apply: number;
  pl_point_before: number;
  pl_rdate: string;
  pl_status: string;
  pl_title: string;
  pl_uid: number;
}
function Point() {
  const navigate = useNavigate();
  const [paginationList, setPaginationList] = useState([1, 2, 3, 4, 5]);
  const [pagination, setPagination] = useState(1);
  const [useablePoint, setUseablePoint] = useState<{ total_point: number }>();
  const [pointHistory, setPointHistory] = useState<IPointHistoryType[]>([]);
  useEffect(() => {
    axiosClient
      .get('/data/point')
      .then((res) => setUseablePoint(res.data.data));
    axiosClient
      .get('/data/point/history?months=24')
      .then((res) => setPointHistory(res.data.data));
  }, []);
  return (
    <div className={styles.container}>
      <LoginHandler />
      <Header />
      {isDesktop && <MyPageNavigate />}
      {!isDesktop && (
        <h3 className={styles.title}>
          <img src={leftArrow} alt="leftArrow" onClick={() => navigate(-1)} />
          <span>적립금</span>
        </h3>
      )}
      <div className={styles.contentWrap}>
        <div className={styles.header}>
          <span>사용 가능한 적립금</span>
          <span>{useablePoint?.total_point.toLocaleString()}P</span>
        </div>
        <div className={styles.pointListWrap}>
          <h4>적립금 내역</h4>
          <ul className={styles.pointList}>
            {pointHistory?.map((history) => (
              <li key={history.pl_uid}>
                <span>
                  {history.pl_adate.substring(0, 10).replace(/-/gi, '.')}
                </span>
                <span>{history.pl_title}</span>
                <span
                  style={{
                    color:
                      history.pl_point > 0 ? '#ff5656' : 'var(--theme-color);',
                  }}
                >
                  {history.pl_point > 0
                    ? '+' + history.pl_point.toLocaleString()
                    : history.pl_point.toLocaleString()}
                </span>
              </li>
            ))}
          </ul>
          {/* <div className={styles.paginationWrap}>
            <img src={leftArrow} alt="arrow" />
            {paginationList.map((number) => (
              <span
                key={number}
                onClick={() => setPagination(number)}
                className={number == pagination ? styles.hit : ''}
              >
                {number}
              </span>
            ))}
            <img src={rightArrow} alt="arrow" />
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Point;
