import { IFaqType } from '../../pages/Faq/Faq';
import styles from './MainFaqFrameMobile.module.scss';
import bottomArrow from '../../assets/bottomArrow03.png';
import bottomArrow2 from '../../assets/bottomArrow04.png';
import { useState } from 'react';
function MainFaqFrameMobile(props: { faq: IFaqType }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div
        className={`${styles.askWrap} ${isOpen ? styles.isOpen : ''}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span>{props.faq.title}</span>
        <img
          src={!isOpen ? bottomArrow2 : bottomArrow}
          alt="bottomArrow"
          style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
        />
      </div>

      <div
        className={`${styles.answerWrap} ${isOpen ? styles.isOpen : ''}`}
        dangerouslySetInnerHTML={{ __html: props.faq.content }}
      ></div>
    </div>
  );
}
export default MainFaqFrameMobile;
