import { useEffect, useRef, useState } from 'react';
import styles from './StepModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { isDesktop, isMIUI, isMobile } from 'react-device-detect';
import useMobileSlide from '../../hook/MobileSlide';
import { useForm } from 'react-hook-form';
import slide from '../../assets/slide.png';
import close from '../../assets/close.png';
import step from '../../assets/step.png';
import stepResult from '../../assets/stepResult.png';
import stepResult2 from '../../assets/stepResult2.png';
import stepResult3 from '../../assets/stepResult3.png';
import axiosClient from '../../libs/axiosClient';
import Loading from '../Loading/Loading';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  productId?: number;
}

interface IAnswer {
  answer_id: string;
  text: string;
}

interface IStepType {
  answers: IAnswer[];
  question_id: string;
  text: string;
  parent_question_id: string;
  description: string;
}

function StepModal(props: IPropsType) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileSlide({ closer: props.closer });
  // 결과페이지인지 설문페이지인지
  const [isResult, setIsResult] = useState(false);
  // 결과페이지에서 결과버튼 보여줄지 말지 여부
  const [resultBtn, setResultBtn] = useState(false);
  const [stepList, setStepList] = useState<IStepType[]>([]);
  const [subList, setSubList] = useState<IStepType[]>([]);
  const [stepKey, setStepKey] = useState<string[]>([]);
  const [resultStep, setResultStep] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getStepList = async () => {
    await axiosClient.get('/data/survey?name=step_recommend').then((res) => {
      setStepList(res.data.data.questions);
    });
  };

  useEffect(() => {
    getStepList();
  }, []);

  useEffect(() => {
    if (!stepList) return;

    // 하위질문 저장
    setSubList([...stepList.filter((filter) => /-.*\./.test(filter.text))]);

    // 질문 watch 키값 저장
    setStepKey([...stepList.map((item) => item.question_id)]);
  }, [stepList]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (!isResult && stepKey) {
      stepKey.map((item) => setValue(item.toString(), 0));
    }
    if (isDesktop && !isResult) setResultBtn(false);
    else setResultBtn(true);
  }, [isResult, stepKey]);

  const onSubmit = () => {
    if (isResult) navigate('?');

    let formData = new FormData();

    const stepList: any = stepKey.map((item) => watch()[item]);

    formData.append('questionnaire_name', 'step_recommend');
    stepList.forEach((item: any) => {
      if (item !== 0) formData.append(`answer_ids`, item);
    });

    axiosClient
      .post('/func/survey', formData, {
        withCredentials: true,
      })
      .then((res) => {
        setIsLoading(true);
        setIsResult(true);
        setResultStep(res.data.data.step_recommend);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onLoadFinish = () => {
    if (resultStep !== null) setIsLoading(false);
    // setIsLoading(true);
  };

  const onSubmitError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    // window.scrollTo(
    //   0,
    //   window.scrollY + errorList[0].ref.getBoundingClientRect().top - 150,
    // );
    alert(errorList[0].message);
  };

  // const [renderStart, setRenderStart] = useState(false);
  let renderStart = false;

  const renderSubSteps = (
    parentQuestionId: string,
    answerId: string,
    margin?: number,
  ) => {
    renderStart = true;
    const filteredSubs = subList.filter(
      (sub) => sub.parent_question_id === parentQuestionId,
    );
    return filteredSubs.map((sub) => {
      if (
        watch()[sub.parent_question_id] !== 0 &&
        watch()[sub.parent_question_id.toString()] !== answerId
      ) {
        return (
          <div
            style={{ marginTop: margin && margin + 'px' }}
            key={sub.question_id}
            className={`${styles.stepFrame} ${
              errors[sub.question_id.toString()] ? styles.warningFrame : ''
            }`}
            {...register(sub.question_id.toString(), {
              value: 0,
              validate: () =>
                watch()[sub.question_id.toString()] === 0
                  ? `${sub.text} 질문을 선택해주세요.`
                  : true,
            })}
          >
            <div className={styles.titleWrap}>{sub.text}</div>
            <div className={styles.ansListWrap}>
              {sub.answers.map((subAnswer) => (
                <div
                  key={subAnswer.answer_id}
                  className={`${styles.ansFrame} ${
                    watch()[sub.question_id.toString()] === subAnswer.answer_id
                      ? styles.hit
                      : ''
                  }`}
                  onClick={() => {
                    setValue(sub.question_id.toString(), subAnswer.answer_id);
                  }}
                >
                  <div className={styles.customRadioBox}></div>
                  <span>{subAnswer.text}</span>
                </div>
              ))}
            </div>
            {renderSubSteps(sub.question_id, sub.answers[0].answer_id, 8)}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className={`${styles.container} ${isDesktop ? styles.isDesktop : ''}`}>
      <div
        ref={modalRef}
        className={`${styles.contentWrap} ${
          isResult && styles.resultContentWrap
        }`}
      >
        {isDesktop ? (
          <div className={styles.closeWrap}>
            <img
              src={close}
              alt="close"
              onClick={() => {
                navigate(`/productDetail/${props.productId}`);
                props.closer(false);
              }}
            />
          </div>
        ) : (
          <div
            className={styles.slideWrap}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={slide} alt="slide" />
          </div>
        )}
        {isResult ? (
          <div className={styles.mainWrap}>
            <div className={styles.leftWrap}>
              <div className={`${styles.bigTextWrap} ${styles.resultTextWrap}`}>
                <span>
                  <span className={styles.hit}>{resultStep}단계</span>를
                  {isMobile && <br />} 추천드려요!
                </span>
              </div>
              <div className={styles.resultImgWrap}>
                {resultStep === 1 && <img src={stepResult} alt="result" />}
                {resultStep === 2 && <img src={stepResult2} alt="result" />}
                {resultStep === 3 && <img src={stepResult3} alt="result" />}
              </div>
            </div>
            <div className={styles.rightWrap}>
              <div className={styles.resultSubWrap}>
                {resultStep === 1 && (
                  <span>
                    1단계는{' '}
                    <span className={styles.hit}>
                      제품을 처음 드셔보시는 분
                    </span>
                    들이나
                    <br />
                    <span className={styles.hit}>소화기간이 예민하신 분</span>
                    들에게
                    {isMobile && <br />}
                    추천드립니다.
                  </span>
                )}
                {resultStep === 2 && (
                  <span>
                    2단계는 운동을 통해{' '}
                    <span className={styles.hit}>
                      꾸준하게 다이어트를 하고{' '}
                    </span>
                    {isMobile && <br />}
                    <span className={styles.hit}>
                      있으나{isDesktop && <br />} 식단 조절이 안되고 고민이신 분
                    </span>
                    들에게 {isMobile && <br />}
                    추천드립니다.
                  </span>
                )}
                {resultStep === 3 && (
                  <span>
                    3단계는{' '}
                    <span className={styles.hit}>
                      다른 다이어트 보조제로 효과를 보지
                    </span>
                    {isMobile && <br />}
                    <span className={styles.hit}>
                      못하였거나{isDesktop && <br />} 비만 등으로 고민이 많은 분
                    </span>
                    들에게
                    {isMobile && <br />}
                    추천드립니다.
                  </span>
                )}
                <br />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.mainWrap}>
            <div className={styles.leftWrap}>
              <div className={styles.bigTextWrap}>
                <span>
                  어떤 단계가 좋을까요?
                  <br /> 간단한 문답을 통해
                  <br /> <span className={styles.hit}>나에게 맞는 단계</span>를
                  {isDesktop && <br />}
                  확인해 보세요.
                </span>
              </div>
              <div className={styles.stepWrap}>
                <div className={styles.stepLeftWrap}>
                  <span>나에게 맞는 단계가 고민되시나요?</span>
                  <div>
                    <span>항목을 통해 단계를 추천받아보세요!</span>
                  </div>
                </div>
                <img src={step} alt="step" />
              </div>
            </div>
            <div className={styles.rightWrap}>
              {/* {renderSteps(
                stepList && stepList.filter((step) => !/-.*\./.test(step.text)),
              )} */}
              {stepList &&
                stepList
                  .filter((filter) => !/-.*\./.test(filter.text))
                  .map((step, quesIdx) => (
                    <div
                      key={step.question_id}
                      className={`${styles.stepFrameWrap} ${
                        errors[`${step.question_id}`] ? styles.warning : ''
                      }`}
                      {...register(`${step.question_id}`, {
                        value: 0,
                        validate: () =>
                          watch()[`${step.question_id}`] === 0
                            ? `'${step.text}' 질문을 선택해주세요.`
                            : true,
                      })}
                    >
                      <div className={styles.stepFrame}>
                        <div className={styles.titleWrap}>{step.text}</div>
                        <div className={styles.ansListWrap}>
                          {step.answers.map((answer, answerIdx) => (
                            <div
                              key={answer.answer_id}
                              className={`${styles.ansFrame} ${
                                watch()[`${step.question_id}`] ===
                                answer.answer_id
                                  ? styles.hit
                                  : ''
                              }`}
                              onClick={() => {
                                setValue(
                                  `${step.question_id}`,
                                  answer.answer_id,
                                );
                              }}
                            >
                              <div className={styles.customRadioBox}></div>
                              <span>{answer.text}</span>
                            </div>
                          ))}
                        </div>
                        {step.description && (
                          <div className={styles.subWrap}>
                            {step.description}
                          </div>
                        )}
                      </div>
                      {renderSubSteps(
                        step.question_id,
                        step.answers[0].answer_id,
                      )}
                    </div>
                  ))}
              {renderStart && (
                <div className={styles.resultBtnWrap}>
                  <div className={styles.alertTextWrap}>
                    본 페이지는 참고용으로 제공되는 정보이며 진료 시
                    <br />
                    의료진의 판단에 따라 단계가 변경될 수 있으며 개인별 건강
                    상태에
                    <br />
                    따라 약재 및 배합이 다르게 처방될 수 있습니다.
                  </div>
                  <div className={styles.btnWrap}>
                    {isResult && (
                      <div
                        className={styles.reStepBtn}
                        onClick={() => {
                          setIsResult(false);
                          setResultStep(undefined);
                        }}
                      >
                        <span>다시 진단하기</span>
                      </div>
                    )}
                    <div
                      className={styles.resultBtn}
                      onClick={handleSubmit(onSubmit, onSubmitError)}
                    >
                      {isResult ? (
                        <span>진료 받으러 가기</span>
                      ) : (
                        <span>결과 확인하기</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {resultBtn && (
          <div className={styles.resultBtnWrap}>
            <div className={styles.alertTextWrap}>
              본 페이지는 참고용으로 제공되는 정보이며 진료 시
              {isMobile && <br />} 의료진의 판단에 따라{isDesktop && <br />}{' '}
              단계가 변경될 수 있으며 개인별 건강 상태에
              {isMobile && <br />}따라 약재 및 배합이 다르게 처방될 수 있습니다.
            </div>
            <div className={styles.btnWrap}>
              {isResult && (
                <div
                  className={styles.reStepBtn}
                  onClick={() => {
                    setIsResult(false);
                    setResultStep(undefined);
                  }}
                >
                  <span>다시 진단하기</span>
                </div>
              )}
              <div
                className={styles.resultBtn}
                onClick={handleSubmit(onSubmit, onSubmitError)}
              >
                {isResult ? (
                  <span>진료 받으러 가기</span>
                ) : (
                  <span>결과 확인하기</span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {isLoading && <Loading closer={setIsLoading} />} */}
      {isLoading && <Loading onLoadFinish={onLoadFinish} />}
    </div>
  );
}

export default StepModal;
