import { isDesktop } from 'react-device-detect';
import styles from './PostReviewModal.module.scss';
import { useEffect, useState } from 'react';
import star from '../../../assets/star.png';
import emptyStar from '../../../assets/emptyStar.png';
import camera from '../../../assets/camera.png';
import closeBtn from '../../../assets/closeBtn04.png';
import inputLogo from '../../../assets/inputLogo.png';
import bottomArrow from '../../../assets/bottomArrow05.png';
import axiosClient from '../../../libs/axiosClient';
import check from '../../../assets/checkMark.png';
import {
  IReviewDataType,
  IReviewYetType,
} from '../../../pages/MyReview/MyReview';
interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  reviewYet: IReviewYetType;
  getReviewYet: () => void;
}
function PostReviewModal(props: IPropsType) {
  const [gender, setGender] = useState(-1);
  const [age, setAge] = useState(-1);
  const [weight, setWeight] = useState(0);
  const [content, setContent] = useState('');
  const [customReviewStar1, setCustomReviewStar1] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [customReviewStar2, setCustomReviewStar2] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [customReviewStar3, setCustomReviewStar3] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [customReviewStar4, setCustomReviewStar4] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [imageSrc, setImageSrc] = useState<string[]>([]);
  const [imageBinary, setImageBinary] = useState<any[]>([]);
  const [guideOpen, setGuideOpen] = useState(false);
  const [marketingAgree, setMarketingAgree] = useState(false);
  const [isWriting, setIsWriting] = useState(false);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  function encodeFileToBase64(file: any): any {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject(fr);
      };
      fr.readAsDataURL(file);
    });
  }
  function deleteUploadImage(idx: number) {
    let list = [...imageSrc];
    let binaryList = [...imageBinary];
    list.splice(idx, 1);
    binaryList.splice(idx, 1);
    setImageSrc(list);
    setImageBinary(binaryList);
  }
  function handleValidate() {
    if (gender == -1) {
      alert('성별을 선택해주세요.');
      return false;
    }
    if (age == -1) {
      alert('나이를 선택해주세요.');
      return false;
    }
    if (
      !customReviewStar1.includes(true) ||
      !customReviewStar2.includes(true) ||
      !customReviewStar3.includes(true) ||
      !customReviewStar4.includes(true)
    ) {
      alert('별점을 선택해주세요.');
      return false;
    }
    if (content.length < 10) {
      alert('리뷰 내용을 10자 이상 작성해주세요.');
      return false;
    }
    if (!marketingAgree) {
      alert('마케팅 용도 2차 활용 동의를 체크해주세요.');
      document
        .querySelector('.reviewModal')!
        .scrollTo(0, document.querySelector('.reviewModal')!.clientHeight);
      return false;
    }
    return true;
  }
  async function handleSubmit() {
    if (!handleValidate()) return;
    let imageList = [...imageBinary];
    if (isWriting) {
      alert('리뷰 등록중 입니다.');
      return;
    }
    let formData = new FormData();
    formData.append('talk_type', '상품평가');
    formData.append('content', content);
    formData.append('gender', gender == 0 ? 'M' : 'F');
    formData.append(
      'age',
      String(
        age == 0 ? 20 : age == 1 ? 30 : age == 2 ? 40 : age == 3 ? 50 : '',
      ),
    );
    formData.append('weight', String(weight));
    formData.append('product_id', String(props.reviewYet.product_id));
    formData.append(
      'stars0',
      String(customReviewStar1.filter((isTrue) => isTrue).length * 20),
    );
    formData.append(
      'stars1',
      String(customReviewStar2.filter((isTrue) => isTrue).length * 20),
    );
    formData.append(
      'stars2',
      String(customReviewStar3.filter((isTrue) => isTrue).length * 20),
    );
    formData.append(
      'stars3',
      String(customReviewStar4.filter((isTrue) => isTrue).length * 20),
    );
    formData.append('is_secret', 'N');
    for (let i = 0; i < imageList.length; i++) {
      formData.append(`file${i}`, imageList[i]);
    }
    formData.append(
      'order_product_id',
      String(props.reviewYet?.order_product_id!),
    );
    formData.append('marketing_agree', marketingAgree ? 'Y' : 'N');
    axiosClient
      .post('/func/product_talk', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        alert('리뷰 등록이 완료되었습니다.');
        props.getReviewYet();
        props.closer(false);
      })
      .catch((err) => setIsWriting(false));

    setIsWriting(true);
  }
  return (
    <div
      className={`${styles.container} ${isDesktop ? styles.pc : styles.mobile}`}
    >
      <div className={`${styles.contentWrap} reviewModal`}>
        <div className={styles.head}>
          <div className={styles.left}>
            {/* <span>포토후기 500P</span>
            <span>일반후기 100P</span> */}
          </div>
          <div className={styles.closeBtn} onClick={() => props.closer(false)}>
            닫기
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>리뷰쓰기</div>
          {props.reviewYet && (
            <div className={styles.productInfoWrap}>
              <div className={styles.productThumbnail}>
                <img src={props.reviewYet?.list_image} alt="imsiProduct" />
              </div>
              <div className={styles.productInfo}>
                <span className={styles.productName}>
                  [ {props.reviewYet?.product_name} ]
                </span>
                <div className={styles.optionInfo}>
                  <span>옵션</span>
                  <span>
                    {props.reviewYet?.op_option1 && props.reviewYet?.op_option1}
                    {props.reviewYet?.op_option2 &&
                      ' / ' + props.reviewYet?.op_option2}
                    {props.reviewYet?.op_option3 &&
                      ' / ' + props.reviewYet?.op_option3}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className={styles.userInfoWrap}>
            <div className={styles.gender}>
              <span>성별</span>
              <ul>
                <li
                  className={gender == 0 ? styles.hit : ''}
                  onClick={() => setGender(0)}
                >
                  남성
                </li>
                <li
                  className={gender == 1 ? styles.hit : ''}
                  onClick={() => setGender(1)}
                >
                  여성
                </li>
              </ul>
            </div>
            <div className={styles.age}>
              <span>연령대</span>
              <ul>
                <li
                  className={age == 0 ? styles.hit : ''}
                  onClick={() => setAge(0)}
                >
                  20대
                </li>
                <li
                  className={age == 1 ? styles.hit : ''}
                  onClick={() => setAge(1)}
                >
                  30대
                </li>
                <li
                  className={age == 2 ? styles.hit : ''}
                  onClick={() => setAge(2)}
                >
                  40대
                </li>
                <li
                  className={age == 3 ? styles.hit : ''}
                  onClick={() => setAge(3)}
                >
                  50대-60대
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.scoreWrap}>
            <span>별점 평가</span>
            <ul className={styles.customReviewList}>
              <li>
                <span>효과가 좋아요</span>
                <div className={styles.starWrap}>
                  {customReviewStar1.map((isTrue, idx) => (
                    <div
                      onClick={() => {
                        let newScore: boolean[] = [];
                        for (let i = 0; i < 5; i++) {
                          if (i <= idx) newScore.push(true);
                          else newScore.push(false);
                          setCustomReviewStar1(newScore);
                        }
                      }}
                    >
                      {isTrue ? (
                        <img src={star} alt="star" />
                      ) : (
                        <img src={emptyStar} alt="emptyStar" />
                      )}
                    </div>
                  ))}
                </div>
              </li>
              <li>
                <span>맛과 향이 좋아요</span>
                <div className={styles.starWrap}>
                  {customReviewStar2.map((isTrue, idx) => (
                    <div
                      onClick={() => {
                        let newScore: boolean[] = [];
                        for (let i = 0; i < 5; i++) {
                          if (i <= idx) newScore.push(true);
                          else newScore.push(false);
                          setCustomReviewStar2(newScore);
                        }
                      }}
                    >
                      {isTrue ? (
                        <img src={star} alt="star" />
                      ) : (
                        <img src={emptyStar} alt="emptyStar" />
                      )}
                    </div>
                  ))}
                </div>
              </li>
              <li>
                <span>가격대비 만족해요</span>
                <div className={styles.starWrap}>
                  {customReviewStar3.map((isTrue, idx) => (
                    <div
                      onClick={() => {
                        let newScore: boolean[] = [];
                        for (let i = 0; i < 5; i++) {
                          if (i <= idx) newScore.push(true);
                          else newScore.push(false);
                          setCustomReviewStar3(newScore);
                        }
                      }}
                    >
                      {isTrue ? (
                        <img src={star} alt="star" />
                      ) : (
                        <img src={emptyStar} alt="emptyStar" />
                      )}
                    </div>
                  ))}
                </div>
              </li>
              <li>
                <span>먹기가 간편해요</span>
                <div className={styles.starWrap}>
                  {customReviewStar4.map((isTrue, idx) => (
                    <div
                      onClick={() => {
                        let newScore: boolean[] = [];
                        for (let i = 0; i < 5; i++) {
                          if (i <= idx) newScore.push(true);
                          else newScore.push(false);
                          setCustomReviewStar4(newScore);
                        }
                      }}
                    >
                      {isTrue ? (
                        <img src={star} alt="star" />
                      ) : (
                        <img src={emptyStar} alt="emptyStar" />
                      )}
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.weightInfoWrap}>
            <span>감량 체중</span>
            <div className={styles.weight}>
              <span>-</span>
              <input
                type="text"
                value={weight}
                onChange={(e) => {
                  if (isNaN(Number(e.currentTarget.value))) return;
                  if (Number(e.currentTarget.value) < 0) setWeight(0);
                  else setWeight(Number(e.currentTarget.value));
                }}
              />
              <span>kg</span>
            </div>
          </div>
          <div className={styles.uploadImagesWrap}>
            <span>사진첨부</span>
            <ul className={styles.uploadImageList}>
              {imageSrc
                .filter((src) => src !== 'deleted')
                .map((image, idx) => (
                  <li className={styles.selectedImageWrap} key={image}>
                    <img src={image} alt="selectedImg" />
                    <div
                      className={styles.closeBtn}
                      onClick={() => deleteUploadImage(idx)}
                    >
                      <img src={closeBtn} alt="closeBtn" />
                    </div>
                  </li>
                ))}
              {(() => {
                let list: string[] = [];
                for (
                  let i = 0;
                  i < 3 - imageSrc.filter((src) => src !== 'deleted').length;
                  i++
                )
                  list.push('');
                return list.map(() => (
                  <li className={styles.selectedImageWrap}></li>
                ));
              })()}
            </ul>
            <div className={styles.inputImgBtn}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                multiple
                onChange={(e) => {
                  let files = e.currentTarget.files;
                  let readers = [];
                  if (!files!.length) return;
                  let binaryList = [];
                  for (let i = 0; i < 3 - imageBinary.length; i++) {
                    if (!files![i]) break;
                    binaryList.push(files![i]);
                  }
                  setImageBinary([...imageBinary, ...binaryList]);
                  for (let i = 0; i < files!.length; i++) {
                    readers.push(encodeFileToBase64(files![i]));
                  }
                  Promise.all(readers).then((values) => {
                    let list = [];
                    if (values.length + imageSrc.length > 3) {
                      alert('최대 3장까지만 첨부 가능합니다.');
                    }
                    for (let i = 0; i < 3 - imageSrc.length; i++) {
                      if (!values[i]) break;
                      list.push(values[i]);
                    }
                    setImageSrc([...imageSrc, ...list]);
                  });
                }}
              />
              <div className={styles.imgBtn}>
                <img src={camera} alt="cameraIcon" />
                <span>사진첨부</span>
              </div>
            </div>
          </div>
          <div className={styles.reviewFormWrap}>
            <div className={styles.title}>
              <img src={inputLogo} alt="inputLogo" />
              <span>리뷰 작성</span>
            </div>
            <textarea
              placeholder="자세하고 솔직한 리뷰는 다른 고객에게 큰 도움이 됩니다.(최소 10자이상)"
              value={content}
              onChange={(e) => setContent(e.currentTarget.value)}
            />
          </div>
          <button className={styles.submitBtn} onClick={handleSubmit}>
            완료
          </button>
          <div className={styles.reviewGuideWrap}>
            <div
              className={styles.guideTitle}
              onClick={() => setGuideOpen((prev) => !prev)}
            >
              <span>다이어트 닥터 리뷰 정책</span>
              <img
                src={bottomArrow}
                alt="bottomArrow"
                style={{
                  transform: guideOpen ? 'rotate(180deg)' : 'rotate(0)',
                }}
              />
            </div>
            {guideOpen && (
              <div className={styles.guideContent}>
                다음 금지행위에 해당되는 리뷰는 다이어트 닥터 이용약관에 따라
                고객에게 통보 없이 삭제 또는 블라인드 될 수 있습니다.
                <br />
                <br />
                [리뷰 작성 시 금지행위]
                <br />
                1.욕설, 폭언, 비방 등 타인에 불쾌하거나 위협이 되는 내용 게시
                <br /> 2. 허위/과장된 내용 또는 직접 작성하지 않았거나 구매한
                상품과 관련 없는 내용 게시 <br />
                3. 정당한 권한 없이 타인의 권리 등(개인정보, 지식재산권, 소유권,
                명예, 신용 등)을 침해하는 내용 게시 <br />
                4. 음란물 또는 청소년 유해 매체물, 범죄행위나 불법적인 행동을
                전시 또는 조장하는 내용 게시 <br />
                5. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를
                포함하는 리뷰 게시
                <br />
                6. 사기성 상품, 서비스, 사업 계획 등을 판촉하는 리뷰 게시 <br />
                7. 기타 관련법령 및 이용약관, 운영정책에 위배되는 리뷰 게시
              </div>
            )}
          </div>
          <div
            className={styles.marketingAgreement}
            onClick={() => setMarketingAgree((prev) => !prev)}
          >
            <div
              className={`${styles.marketingCheckbox} ${
                marketingAgree ? styles.isChecked : ''
              }`}
            >
              {marketingAgree && <img src={check} />}
            </div>
            <p>
              작성한 리뷰는 마케팅 용도로 2차 활용될 수 있음을 확인하고 이에
              동의합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostReviewModal;
