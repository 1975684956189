import styles from './ADBanner.module.scss';
import product from '../../assets/adLink/product.png';
import product2 from '../../assets/adLink/product02.png';
import product3 from '../../assets/adLink/product03.png';
import rightArrow from '../../assets/rightArrow08.png';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import rightArrow2 from '../../assets/rightArrow09.png';
import { useEffect, useState } from 'react';
interface IPropsType {
  endDate: string;
  productId: number;
  productName: string;
  price: number;
  discountedPrice: number;
  top: number;
}
function ADBanner({
  endDate,
  productId,
  productName,
  price,
  discountedPrice,
  top,
}: IPropsType) {
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (idx == 0) setIdx(1);
      else if (idx == 1) setIdx(0);
    }, 3000);
  }, [idx]);

  return (
    <>
      {idx == 0 && (
        <div
          className={`${styles.container} ${!isDesktop ? styles.isMobile : ''}`}
          style={{ top: `${top}px` }}
          onClick={() => {
            // navigate(`/productDetail/${productId}`);
            window.location.href = `https://dietdr.co.kr/productDetail/${productId}`;
          }}
        >
          {productName.replace(/ /g, '').includes('보감비책다이어트정') && (
            <>
              <div className={styles.productThumbnailWrap}>
                <img src={product} alt="상품썸네일" />
              </div>
              <h1 className={styles.productName}>보감비책 다이어트 정</h1>
            </>
          )}
          {productName.replace(/ /g, '').includes('보감비책마이크로환') && (
            <>
              <div className={styles.productThumbnailWrap}>
                <img src={product3} alt="상품썸네일" />
              </div>
              <h1 className={styles.productName}>보감비책 마이크로 환</h1>
            </>
          )}

          <div className={styles.priceWrap}>
            <div className={styles.price}>
              <span>정상가</span>
              <span> : </span>
              <span>{price.toLocaleString()}원</span>
            </div>
            <div className={styles.discountedPrice}>
              <span>할인가</span>
              <span> : </span>
              <span>{discountedPrice.toLocaleString()}원</span>
            </div>
          </div>
          {isDesktop ? (
            <div className={styles.productDetailBtn}>
              <img src={rightArrow} alt="화살표" />
            </div>
          ) : (
            <div className={styles.mobileProductDetailBtn}>
              <img src={rightArrow2} alt="화살표" />
            </div>
          )}
        </div>
      )}
      {idx == 1 && (
        <div
          className={`${styles.couponContainer} ${
            !isDesktop ? styles.isMobile : ''
          }`}
          style={{ top: `${top}px` }}
          onClick={() => {
            // navigate(`/productDetail/${productId}`);
            window.location.href = `https://dietdr.co.kr/event/19`;
          }}
        >
          <div className={styles.productThumbnailWrap}>
            <img src={product2} alt="상품썸네일" />
          </div>
          <h1 className={styles.productName}>쇼핑 지원 쿠폰 팩 이벤트</h1>
          <p className={styles.comment}>
            다이어트닥터 회원을 위한
            <br />
            쿠폰 팩 받아가세요!
          </p>
          {isDesktop ? (
            <div className={styles.productDetailBtn}>
              <img src={rightArrow} alt="화살표" />
            </div>
          ) : (
            <div className={styles.mobileProductDetailBtn}>
              <img src={rightArrow2} alt="화살표" />
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default ADBanner;
