import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import styles from './UpdateProfile.module.scss';
import inputCancel from '../../assets/inputCancel.png';
import selectOpen from '../../assets/selectOpen.png';
import axiosClient from '../../libs/axiosClient';
import jwt_decode from 'jwt-decode';
import { ITokenInfoType, logout } from '../../auth/auth';
import { setToken } from '../../auth/auth';
import { isDesktop } from 'react-device-detect';
import leftArrow from '../../assets/leftArrow02.png';
import LoginHandler from '../../components/LoginHandler/LoginHandler';

var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,30}$/;
const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z ]+$/;
const emailRegex =
  /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
const telRegex = /^[0-9]*$/;
function UpdateProfile() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [pwCheck, setPwCheck] = useState('');
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [emailDirectly, setEmailDirectly] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);
  const [marketing, setMarketing] = useState<boolean[]>([false, false, false]);
  const [userInfo, setUserInfo] = useState<ITokenInfoType>();
  const [marketingAgreeBefore, setMarketingAgreeBefore] = useState<boolean[]>(
    [],
  );
  const [updated, setUpdated] = useState(false);
  const [state, setState] = useState('');
  const [refCode, setRefCode] = useState<string>('');
  const [refCodeUpdate, setRefCodeUpdate] = useState<string>('');

  function handleUpdate(updateList: string[]) {
    let formData = new FormData();
    formData.append('referrer_code', refCodeUpdate);
    if (updated) return;
    if (updateList.length == 0) {
      alert('수정된 정보가 없습니다.');
      return;
    }
    setUpdated(true);
    let queryString = '?';
    if (updateList.includes('password'))
      queryString += `password=${pw}&password_confirm=${pwCheck}&`;
    if (updateList.includes('name')) queryString += `name=${name}&`;
    if (updateList.includes('phone'))
      queryString += `phone=${tel[0] !== '+' ? tel : encodeURIComponent(tel)}&`;
    if (updateList.includes('email'))
      queryString += `email=${email1}@${email2}&`;
    if (updateList.includes('marketing'))
      queryString += `accept_sms=${marketing[0] ? 'Y' : 'N'}&accept_email=${
        marketing[1] ? 'Y' : 'N'
      }`;
    if (updateList.includes('referrer_code') === false && !refCodeUpdate) {
      axiosClient.put(`/func/user/update${queryString}`).then((res) => {
        alert('회원정보 수정이 완료되었습니다.');
        setUpdated(false);
        setToken(res.data.data);
      });
    } else if (refCodeUpdate && updateList.includes('referrer_code') === true) {
      axiosClient
        .get(`/data/referrer/is_exist?referrer_code=${refCodeUpdate}`, {
          headers: {
            'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
            'ngrok-skip-browser-warning': true,
          },
        })
        .then((res) => {
          axiosClient
            .post('/func/referrer/apply', formData, {
              headers: {
                'ngrok-skip-browser-warning': true,
              },
            })
            .then((res) => console.log(res));
          axiosClient.put(`/func/user/update${queryString}`).then((res) => {
            alert('회원정보 수정이 완료되었습니다.');
            setUpdated(false);
            setRefCode(refCodeUpdate);
            setToken(res.data.data);
          });
        })
        .catch((err) => {
          alert(err.response.data.detail);
          setUpdated(false);
        });
    }
  }

  function referrerCodeView() {
    axiosClient
      .get('/data/user/receive', {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        setRefCode(res.data.data.referrer_code);
      });
  }

  useEffect(() => {
    referrerCodeView();
  }, []);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let needUpdateList: string[] = [];
    if (pw.length !== 0 || pwCheck.length !== 0) {
      if (pw !== pwCheck) {
        alert('비밀번호가 일치하지 않습니다.');
        return;
      }
      if (!passwordRegex.test(pw)) {
        alert(
          '비밀번호는 영문, 숫자, 특수기호(필수x)를 사용하여 8자 이상 30자 이내만 가능합니다.',
        );
        return;
      }
      needUpdateList.push('password');
    }
    if (userInfo?.name !== name) {
      if (!nameRegex.test(name)) {
        alert('이름은 한글, 영어, 공백만 사용 가능합니다.');
        return;
      }
      needUpdateList.push('name');
    }
    if (userInfo?.phone.replace(/[-)+]/gi, '') !== tel) {
      if (tel.length < 8 || !telRegex.test(tel)) {
        alert('휴대폰 번호 형식이 올바르지 않습니다.');
        return;
      }
      needUpdateList.push('phone');
    }
    if (userInfo?.email !== email1 + '@' + email2) {
      if (!emailCheck) {
        alert('이메일 중복확인을 해주세요.');
        return;
      }
      needUpdateList.push('email');
    }
    if (
      marketingAgreeBefore[0] != marketing[0] ||
      marketingAgreeBefore[1] != marketing[1] ||
      marketingAgreeBefore[2] != marketing[2]
    ) {
      if (!marketing.includes(true)) {
        alert('마케팅 정보 수신 동의를 확인해주세요.!');
        return;
      }
      needUpdateList.push('marketing');
    }
    if (refCodeUpdate) {
      needUpdateList.push('referrer_code_start');
      axiosClient
        .get(`/data/referrer/is_exist?referrer_code=${refCodeUpdate}`, {
          headers: {
            'Access-Control-Allow-Origin': 'proxy": "https://api.dietdr.co.kr',
            'ngrok-skip-browser-warning': true,
          },
        })
        .then((res) => {
          setUpdated(false);
          needUpdateList.push('referrer_code');
          handleUpdate(needUpdateList);
        })
        .catch((err) => {
          alert(err.response.data.detail);
          setUpdated(false);
        });
    }
    handleUpdate(needUpdateList);
  }
  function handleEmailCheck() {
    if (!emailRegex.test(email1 + '@' + email2)) {
      alert('올바르지 않은 이메일 형식입니다.');
      return;
    }
    axiosClient
      .get(`/data/verification/email?email=${email1 + '@' + email2}`)
      .then((res) => {
        setEmailCheck(true);
        alert('사용 가능한 이메일입니다.');
      })
      .catch((err) => alert(err.response.data.detail));
  }
  function marketingAgree(idx: number) {
    if (idx == 0 || idx == 1) {
      setMarketing((prev) => {
        prev[idx] = !prev[idx];
        return [prev[0], prev[1], false];
      });
    } else setMarketing([false, false, !marketing[2]]);
  }
  useEffect(() => {
    if (updated) return;
    const token = localStorage.getItem('token');
    if (token == null || token == 'null') return;
    try {
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      setUserInfo(decodedUserInfo);
      setId(decodedUserInfo.member_id);
      setName(decodedUserInfo.name);
      setTel(decodedUserInfo.phone.trim().replace(/[-)+]/gi, ''));
      setEmail1(decodedUserInfo.email.split('@')[0]);
      setEmail2(decodedUserInfo.email.split('@')[1]);
    } catch (err) {
      logout();
    }
    axiosClient.get('/data/user/receive').then((res) => {
      let list: boolean[] = [];
      if (res.data.data.accept_sms == 'Y') list.push(true);
      else list.push(false);
      if (res.data.data.accept_email == 'Y') list.push(true);
      else list.push(false);
      if (list.includes(true)) list.push(false);
      else list.push(true);
      setMarketingAgreeBefore([...list]);
      setMarketing([...list]);
    });
  }, [updated]);
  function handleWithdrawal() {
    let confirm = window.confirm('정말 탈퇴하시겠습니까?');
    if (!confirm) return;
    axiosClient
      .delete('/func/user')
      .then((res) => {
        logout();
        alert('성공적으로 탈퇴되었습니다.');
        navigate('/');
      })
      .catch((err) => alert(err.response.data.detail));
  }
  const onChangeRefCode = (e: any) => {
    const lefCode = e.target.value;
    setRefCodeUpdate(lefCode);
  };
  let date: any;
  useEffect(() => {
    axiosClient
      .get('/data/user/me', {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        date = new Date(res.data.created_at);
        date.setDate(date.getDate() + 7);
        const afterDate = new Date(date);
        const todayDate = new Date();
        if (afterDate >= todayDate) setState('true');
        else setState('false');
      });
  }, []);
  return (
    <div>
      <LoginHandler />
      <Header />
      <div className={styles.contentWrap}>
        {isDesktop && <MyPageNavigate />}
        <h3 className={`${styles.title} ${!isDesktop ? styles.isMobile : ''}`}>
          {!isDesktop && (
            <img src={leftArrow} alt="leftArrow" onClick={() => navigate(-1)} />
          )}
          <span>회원정보수정</span>
        </h3>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.editForm}>
          <div className={styles.id}>
            <div>
              <span>아이디</span>
              <span onClick={handleWithdrawal}>회원탈퇴</span>
            </div>
            <div className={styles.idInputWrap}>
              <input
                type="text"
                placeholder="영문, 숫자 4자 이상"
                value={id}
                onChange={(e) => {
                  setId(e.currentTarget.value);
                }}
                disabled
              />
            </div>
          </div>
          <div className={styles.pw}>
            <span>비밀번호</span>
            <input
              type="password"
              value={pw}
              onChange={(e) => setPw(e.currentTarget.value)}
              placeholder="비밀번호 입력"
            />
          </div>
          <div className={styles.pwCheck}>
            <span>비밀번호 확인</span>
            <input
              type="password"
              value={pwCheck}
              onChange={(e) => setPwCheck(e.currentTarget.value)}
              placeholder="비밀번호 재입력"
            />
          </div>
          <div className={styles.name}>
            <span>이름</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              placeholder="이름"
              // disabled
            />
          </div>
          <div className={styles.tel}>
            <span>휴대폰 번호</span>
            <div className={styles.telInputWrap}>
              <input
                type="text"
                placeholder="01000000000"
                value={tel}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setTel((prev) => value);
                }}
                onBlur={() => setTel((prev) => prev.trim())}
              />
            </div>
            <p>주문 등과 관련된 중요한 문자가 발송됩니다.</p>
          </div>
          <div className={styles.email}>
            <span>이메일 주소</span>
            <div className={styles.emailInputWrap}>
              <input
                type="text"
                value={email1}
                onChange={(e) => {
                  setEmail1(e.currentTarget.value);
                  setEmailCheck(false);
                }}
              />
              <span>@</span>
              <div>
                <div className={styles.customSelect}>
                  <input
                    type="text"
                    value={email2}
                    placeholder="직접입력"
                    onChange={(e) => {
                      setEmail2(e.currentTarget.value);
                      setEmailCheck(false);
                    }}
                  />
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      if (e.currentTarget.value == 'directly') {
                        setEmailDirectly(true);
                        setEmail2('');
                      } else setEmail2(e.currentTarget.value);
                      setEmailCheck(false);
                    }}
                    style={{ display: emailDirectly ? 'none' : '' }}
                  >
                    <option disabled selected>
                      선택해주세요.
                    </option>
                    <option value="naver.com">naver.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="daum.net">daum.net</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="outlook.com">outlook.com</option>
                    <option value="icloud.com">icloud.com</option>
                    <option value="directly">직접입력</option>
                  </select>
                  <img
                    src={selectOpen}
                    alt="selectOpen"
                    style={{
                      display: emailDirectly ? 'none' : '',
                      pointerEvents: 'none',
                    }}
                  />
                  <img
                    src={inputCancel}
                    alt="inputCancel"
                    style={{ display: !emailDirectly ? 'none' : '' }}
                    onClick={() => setEmailDirectly(false)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.emailCheck} onClick={handleEmailCheck}>
              중복확인
            </div>
          </div>
          <div className={styles.referral}>
            <span>추천인 코드</span>
            <span
              style={{
                color: '#D0D2D6',
              }}
            >
              (선택사항)
            </span>
            {state === 'true' && refCode ? (
              <div className={styles.referralInputWrap}>
                <input
                  type="text"
                  name="referral"
                  value={refCode}
                  placeholder="추천인 코드 입력"
                  onChange={(e) => setRefCode(e.currentTarget.value)}
                  disabled
                />
              </div>
            ) : state === 'true' && !refCode ? (
              <div className={styles.referralInputWrap}>
                <input
                  type="text"
                  name="referral"
                  value={refCodeUpdate}
                  placeholder="추천인 코드 입력"
                  onChange={onChangeRefCode}
                />
              </div>
            ) : (
              <div className={styles.referralInputWrap}>
                <input
                  style={{
                    caretColor: 'transparent',
                  }}
                  type="text"
                  name="referral"
                  value=""
                  placeholder="추천인 코드 입력"
                  onClick={() => {
                    alert('이벤트 대상이 아닙니다.');
                  }}
                />
              </div>
            )}
            <div className={styles.referralJoinMsg}>
              <span
                style={{
                  color: '#666',
                }}
              >
                신규회원 제품 구매 시,{' '}
              </span>
              <span>신규회원 : </span>
              <span>5,000원 즉시 할인</span>
            </div>
            <div className={styles.referralJoinMsg}>
              <span>추천인 : </span>
              <span>5,000원 즉시 지급</span>
            </div>
          </div>
          <div className={styles.marketing}>
            <span>마케팅 수신 동의</span>
            <ul>
              <li onClick={() => marketingAgree(0)}>
                <div
                  className={`${styles.checkbox} ${
                    marketing[0] ? styles.hit : ''
                  }`}
                ></div>
                <span>SNS</span>
              </li>
              <li onClick={() => marketingAgree(1)}>
                <div
                  className={`${styles.checkbox} ${
                    marketing[1] ? styles.hit : ''
                  }`}
                ></div>
                <span>이메일</span>
              </li>
              <li onClick={() => marketingAgree(2)}>
                <div
                  className={`${styles.checkbox} ${
                    marketing[2] ? styles.hit : ''
                  }`}
                ></div>
                <span>수신 거부</span>
              </li>
            </ul>
            <p>신상품 소식, 이벤트 안내, 다양한 혜택이 제공됩니다.</p>
          </div>
          <button className={styles.submitBtn}>완료</button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
export default UpdateProfile;
