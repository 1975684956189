import styles from './Footer.module.scss';
import logo from '../../assets/blackLogo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import bottomArrow from '../../assets/bottomArrow03.png';
function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  function handleBusinessInfoOPen() {
    window.open(
      'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3158802344&apv_perm_no=',
      '',
      'width=750,height=700',
    );
  }
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.opener} onClick={() => setIsOpen((prev) => !prev)}>
        <img
          src={bottomArrow}
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}
        />
      </div>
      {isOpen && (
        <div
          className={`${styles.container} ${isMobile ? styles.isMobile : ''}`}
        >
          {/* {location.pathname == '/' && (
            <div
              className={`${styles.onlyReturningPatients} ${
                isMobile ? styles.isMobile : ''
              }`}
            >
              비대면 진료는 재진 환자만 가능합니다.
            </div>
          )} */}
          <div className={styles.logoArea}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.companyInfoWrap}>
            <h3 className={styles.companyName}>
              Korea Medical Healthcare co., ltd.
            </h3>
            <div className={styles.companyInfoLine}>
              <span>CEO: YUBIN SON</span>
              <span>Business registration number: 3158802344</span>
              <span
                className={styles.businessInfo}
                onClick={handleBusinessInfoOPen}
              >
                [사업자 정보확인]
              </span>
            </div>
            <div className={styles.companyInfoLine}>
              <span>통신판매업 신고번호 : 제2021-서울강남-06998호</span>
              <span>CPO: YUBIN SON (dietdoctor.korea@gmail.com)</span>
            </div>
            <div className={styles.companyInfoLine}>
              <span>고객센터 : 070-7174-3927</span>
              <span>
                ADDRESS : 704, Seolleung-ro, Gangnam-gu, Seoul, Republic of
                Korea
              </span>
            </div>
            <div className={styles.companyInfoLine}></div>

            <ul className={styles.navigation}>
              <li onClick={() => navigate('/companyInfo')}>회사소개</li>
              <li onClick={() => navigate('/terms')}>이용약관</li>
              <li onClick={() => navigate('/guide')}>이용안내</li>
              <li onClick={() => navigate('/unauthorizedEmailCollection')}>
                이메일무단수집거부
              </li>
              <li onClick={() => navigate('/privacy')}>개인정보처리방침</li>
            </ul>
            <span className={styles.copyright}>
              Copyright(c) dietdr.co.kr. All Rights Reserved.
            </span>
          </div>
          <p className={styles.ondocInfo}>
            *다이어트 닥터는 비대면 진료서비스를 고객과 의사에게 연결해주는
            플랫폼입니다.
            <br />
            다이어트 닥터는 결제와 배송 대행을 맡고 있으며 한약의 판매는
            한의원에서 이루어집니다.
          </p>
        </div>
      )}
    </>
  );
}
export default Footer;
