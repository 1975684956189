import styles from './DeliveryDetail.module.scss';
import bottomArrow from '../../assets/bottomArrow.png';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
function DeliveryDetail() {
  const [deliveryLocationOpen, setDeliveryLocationOpen] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState<any>();
  const [deliveryCheck, setDeliveryCheck] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const { deliveryNumber } = useParams();
  const { deliveryCompany } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    let companyCode = '';
    if (deliveryCompany == 'CJ대한통운') companyCode = 'kr.cjlogistics';
    else if (deliveryCompany == '우체국택배') companyCode = 'kr.epost';
    else if (deliveryCompany == '한진택배') companyCode = 'kr.hanjin';
    else if (deliveryCompany == '롯데택배') companyCode = 'kr.lotte';
    else if (deliveryCompany == '로젠택배') companyCode = 'kr.logen';
    else if (deliveryCompany == '로젠택배') companyCode = 'kr.logen';
    try {
      fetch(
        `https://apis.tracker.delivery/carriers/${companyCode}/tracks/${deliveryNumber?.replace(
          /-/gi,
          '',
        )}`,
      )
        .then((r) => r.json())
        .then((r) => {
          if (r.message) setDeliveryCheck(false);
          else {
            setDeliveryInfo(r);
            setDeliveryCheck(true);
          }
        });
    } catch (e) {
      setDeliveryCheck(false);
    }
  }, []);
  useEffect(() => {
    if (!alertModalOpen) return;
    setTimeout(() => {
      setAlertModalOpen(false);
    }, 3000);
  }, [alertModalOpen]);
  function handleDateChanger(date: string) {
    let day = '';
    let month = date.substring(5, 7);
    let _date = date.substring(8, 10);
    let hour = date.substring(11, 13);
    let minute = date.substring(14, 16);
    switch (new Date(date.substring(0, 10)).getDay()) {
      case 0:
        day = '일';
        break;
      case 1:
        day = '월';
        break;
      case 2:
        day = '화';
        break;
      case 3:
        day = '수';
        break;
      case 4:
        day = '목';
        break;
      case 5:
        day = '금';
        break;
      case 6:
        day = '토';
        break;
    }
    return `${month}.${_date}(${day}) ${hour}:${minute}`;
  }
  async function copyDeliveryNumber() {
    try {
      await navigator.clipboard.writeText(deliveryNumber || '');
    } catch (err: any) {
      if (err.name === 'NotAllowedError') {
        const permission = await window.confirm(
          '클립보드 권한이 없습니다. 클립보드 권한을 허용하시겠습니까?',
        );
        if (permission) {
          await navigator.permissions
            .query({
              name: 'clipboard-write' as PermissionName,
            })
            .then(() => navigator.clipboard.writeText(deliveryNumber || ''));
        }
      }
    }
  }
  useEffect(() => {
    document.body.style.minWidth = 'unset';
    let navigation = document.querySelector('.navigation') as HTMLElement;
    if (navigation) navigation.style.display = 'none';
    return () => {
      document.body.style.minWidth = '850px';
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.safeArea}></div>
      <div className={styles.contentWrap}>
        {
          <>
            <div className={styles.sectionHeader}>배송조회</div>
            <div className={styles.deliveryLocationWrap}>
              <div className={styles.bar}></div>
              <div className={styles.deliveryLocation}>
                <div
                  className={`${styles.isPreparing} ${
                    (deliveryInfo?.state.id == 'information_received' ||
                      !deliveryInfo ||
                      deliveryInfo?.progresses.length == 0) &&
                    styles.hit
                  }`}
                >
                  <span>배송준비중</span>
                </div>
                <div
                  className={`${styles.isDelivering} ${
                    deliveryLocationOpen ? styles.isOpen : styles.isClose
                  } ${
                    deliveryInfo &&
                    deliveryInfo?.state.id !== 'information_received' &&
                    deliveryInfo?.state.id !== 'delivered' &&
                    styles.hit
                  }`}
                >
                  <span>배송중</span>
                  <span
                    className={styles.detailBtn}
                    onClick={() => setDeliveryLocationOpen((prev) => !prev)}
                  >
                    <span>자세히보기</span>
                    <img src={bottomArrow} alt="bottomArrow" />
                  </span>
                  {deliveryLocationOpen && (
                    <div className={styles.deliveryDetailWrap}>
                      {deliveryInfo?.progresses.map(
                        (item: any, idx: number) => (
                          <div key={idx} className={styles.locationInfo}>
                            <span className={styles.location}>
                              {item.location.name}
                            </span>
                            <span className={styles.date}>
                              {handleDateChanger(item.time)}
                            </span>
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`${styles.isComplete} ${
                    deliveryInfo?.state.id == 'delivered' && styles.hit
                  }`}
                >
                  <span>배송완료</span>
                  {deliveryInfo?.state.id == 'delivered' && (
                    <span className={styles.deliveryedTime}>
                      {handleDateChanger(deliveryInfo?.to.time)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.deliveryInfoWrap}>
              <span className={styles.title}>배송정보</span>
              <div className={styles.deliveryInfo}>
                <div>
                  <span>택배사</span>
                  <span>{deliveryInfo?.carrier.name}</span>
                </div>
                <div>
                  <span>송장번호</span>
                  <span>{deliveryInfo?.carrier.id}</span>
                  <button onClick={copyDeliveryNumber}>복사</button>
                </div>
                {deliveryInfo?.progresses.length > 0 &&
                  deliveryInfo?.progresses[
                    deliveryInfo.progresses.length - 1
                  ].description.slice(-1) == ')' && (
                    <div>
                      <span>배송기사</span>
                      <span>
                        {deliveryInfo?.progresses[
                          deliveryInfo.progresses.length - 1
                        ].description.slice(-18, -1)}
                      </span>
                    </div>
                  )}
                <div>
                  <span>보내는분</span>
                  <span>{deliveryInfo?.from.name}</span>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
}
export default DeliveryDetail;
