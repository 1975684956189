import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import bannerImage from '../../assets/bannerImage01.jpeg';
import logo from '../../assets/companyInfoLogo.png';
// import background from '../../assets/companyInfoBg.png';
import styles from './CompanyInfo.module.scss';
function CompanyInfo() {
  return (
    <div>
      <Header />
      <div className={styles.contentWrap}>
        <img src={logo} alt="" />
        <p>
          다이어트 닥터는
          <br />
          한방 비대면 진료 서비스를 선도합니다.
        </p>
        <p>
          다이어트 닥터는 내원 없이 간편하게 한방 비대면 진료를 받을 수 있는
          서비스로 빠르고 안전한 진료 서비스를 제공합니다.
        </p>
      </div>
      <Footer />
    </div>
  );
}
export default CompanyInfo;
