import styles from './OrderContinue.module.scss';
import Header from '../../components/Header/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { isMobile, isDesktop } from 'react-device-detect';
import closeBtn from '../../assets/closeBtn03.png';
import CouponModal from '../../components/CouponModal/CouponModal';
import axiosClient from '../../libs/axiosClient';
import { IBasketDataType, IBasketProductType } from '../Basket/Basket';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
import naver from '../../assets/pay/naver.png';
import kakao from '../../assets/pay/kakao.png';
import smile from '../../assets/pay/smile.png';
import payco from '../../assets/pay/payco.png';
import apple from '../../assets/pay/apple.png';
import card from '../../assets/pay/card.png';
import phone from '../../assets/pay/phone.png';
import bank from '../../assets/pay/bank.png';
import globalCard from '../../assets/pay/globalCard.png';
import order from '../../assets/question/order.png';
import pay from '../../assets/question/card.png';
import coupon from '../../assets/question/coupon.png';
import payBanner from '../../assets/question/payBanner.png';
import productDrop from '../../assets/question/productDrop.png';
import productDropRed from '../../assets/question/productDropRed.png';
import rightGrey from '../../assets/question/rightGrey.png';
import PayModal from '../../components/PayModal/PayModal';
import mPrev from '../../assets/question/mPrev.png';

declare var daum: any;

export interface ICouponType {
  company_id: null;
  coupon_name: string;
  coupon_target: string;
  created_at: string;
  discount_maximum_price: number;
  discount_type: string;
  discount_value: number;
  expire_date: string;
  id: string;
  is_useable: string;
  min_order_price: number;
  product_id: null;
  status: string;
  type: string;
  used_at: null;
}
function OrderContinue() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { cartId } = useParams();
  const { isDirect } = useParams();
  const location = useLocation();
  const isInitial = new URLSearchParams(location.search).get('isInitial');

  const [payStep, setPayStep] = useState(2);
  const [isProductOpenFirst, setIsProductOpenFirst] = useState(false);
  const [isProductOpenSecond, setIsProductOpenSecond] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [healthfoodOnly, setHealthFoodOnly] = useState(true); //주문상품이 건강식품 뿐일때 true 처방의약품 포함시 false

  // 할인혜택 정보 상태
  const [userPoint, setUserPoint] = useState(0);
  const [point, setPoint] = useState<number>(0);
  const [usePoint, setUsePoint] = useState(0);
  function pointApply() {
    if (
      orderData &&
      point >
        orderData.sort_by_category.health_functional_discount_price_sum +
          orderData.total_delivery_sum
    ) {
      setPoint(0);
      return alert('포인트는 건강식품 금액까지만 사용 가능합니다.');
    }
    if (userPoint < 1000) {
      alert('포인트는 1,000원 이상부터 사용 가능합니다.');
      return;
    }
    if (point % 1000 !== 0) {
      alert('포인트는 1,000원 단위로 사용 가능합니다.');
      return;
    }
    let confirm = window.confirm('포인트를 적용하시겠습니까?');
    if (confirm) setUsePoint(point);
  }

  const [promotion, setPromotion] = useState('');
  const [promotionPrice, setPromotionPrice] = useState(0);
  const [promotionPercent, setPromotionPercent] = useState(0);
  function checkPromotion() {
    axiosClient
      .get(`/data/promotion/check-code?promotion_code=${promotion}`)
      .then((res) => {
        if (res.data.result == 'Fail') {
          alert('유효하지 않은 프로모션입니다.');
          return;
        }
        let usePromotion = window.confirm('프로모션을 적용하시겠습니까?');
        if (usePromotion) {
          if (res.data.data.discount_type == 'price') {
            setPromotionPrice(res.data.data.discount_value);
            setPromotionPercent(0);
          } else if (res.data.data.discount_type == 'per') {
            setPromotionPercent(res.data.data.discount_value);
            setPromotionPrice(0);
          }
        } else {
          setPromotionPrice(0);
          setPromotionPercent(0);
        }
      });
  }
  // 할인혜택 정보 상태

  // 결제수단 상태
  const [payMethod, setPayMethod] = useState(0);
  const [withoutBankbook, setWithoutBankbook] = useState(false); // 무통장입금 결제 가능시 true

  useEffect(() => {
    (async () => {
      try {
        let check_can_paymethod_result = await axiosClient
          .get('/data/order/check_can_paymethod')
          .then((res) => res.data.data.paymethod);
        if (check_can_paymethod_result.includes('online')) {
          setWithoutBankbook(true);
        }
        await getPointData();
      } catch (err) {
        alert(
          '에러가 발생했습니다. 지속적으로 에러 발생시 사이트 우측 하단 채널톡을 통해 문의바랍니다.',
        );
        navigate('/');
      }
    })();
  }, []);

  async function getPointData() {
    await axiosClient.get('/data/point').then((res) => {
      setUserPoint(res.data.data.total_point);
    });
  }
  //환불계좌 관련 상태
  const [refundBankList, setRefundBankList] = useState<Object>();

  // 약관동의 관련 상태
  const [payAgree, setPayAgree] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [diagnosisAgree, setDiagnosisAgree] = useState(false);
  const [payAgreeOpen, setPayAgreeOpen] = useState(false);
  const [privacyAgreeOpen, setPrivacyAgreeOpen] = useState(false);
  const [diagnosisAgreeOpen, setDiagnosisAgreeOpen] = useState(false);
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [agreementModalType, setAgreementModalType] = useState(-1);
  useEffect(() => {
    if (agreementModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [agreementModalOpen]);
  // 약관동의 관련 상태
  // 쿠폰 관련 상태
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [couponList, setCouponList] = useState<ICouponType[]>([]);
  const [useCoupon, setUseCoupon] = useState<ICouponType>();
  useEffect(() => {
    if (couponModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [couponModalOpen]);
  // 쿠폰 관련 상태

  //포인트, 쿠폰, 프로모션
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
  useEffect(() => {
    //%할인 쿠폰이나 %할인 프로모션 사용시 최종 금액에서 쿠폰 할인 적용 => 쿠폰 할인 적용된 금액에서 다시 프로모션 쿠폰 금액 적용
    if (!orderData) return;
    let couponPrice = useCoupon
      ? useCoupon.discount_type == 'price' //쿠폰 타입이 price이면
        ? useCoupon.discount_value // 단순 쿠폰 할인 금액을 추가하고,
        : useCoupon.discount_type == 'per' // 쿠폰 타입이 per이면
        ? Math.floor(
            orderData.total_discount_price_sum *
              (useCoupon.discount_value / 100), //쿠폰 할인 %계산해서 추가
          )
        : 0
      : 0;
    let promotion =
      promotionPrice !== 0
        ? promotionPrice
        : promotionPercent !== 0
        ? Math.floor(
            (orderData.total_discount_price_sum - couponPrice) *
              (promotionPercent / 100),
          )
        : 0;

    setTotalDiscountPrice(usePoint + promotion + couponPrice);
  }, [usePoint, promotionPrice, promotionPercent, useCoupon]);
  //포인트, 쿠폰, 프로모션

  //스크롤 위치에 따른 구매하기 버튼 fixed처리
  const buyBtnRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(true);
  useEffect(() => {
    if (!buyBtnRef.current) return;
    const io = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting || isDesktop) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    });
    io.observe(buyBtnRef.current);
    return () => io.disconnect();
  }, [isFixed]);
  //스크롤 위치에 따른 구매하기 버튼 fixed처리

  const [validation, setValidation] = useState({
    payAgree: false,
    privacyAgree: false,
    diagnosisAgree: false,
  });
  function checkValidate() {
    let validate = {
      payAgree: false,
      privacyAgree: false,
      diagnosisAgree: false,
    };

    //약관 동의 검증
    if (!payAgree) validate.payAgree = true;
    if (!privacyAgree) validate.privacyAgree = true;
    if (!diagnosisAgree) validate.diagnosisAgree = true;

    setValidation({ ...validate });
    console.log(validation);
    if (Object.values(validate).includes(true)) {
      if (!payAgree || !privacyAgree || !diagnosisAgree)
        alert('필수 약관에 모두 동의해주세요.');
      return false;
    } else return true;
  }

  function saveInfo() {
    const infomation: any = {};
    infomation.payMethod = payMethod;
    // infomation.totalDiscountPrice = totalDiscountPrice;
    // infomation.promotion = promotion;
    // infomation.point = point;
    // infomation.usePoint = usePoint;
    // infomation.useCoupon = useCoupon;

    sessionStorage.setItem('temporaryOrderInfo', JSON.stringify(infomation));
    sessionStorage.setItem('lastOrderPageUrl', location.pathname);
  }
  // 결제창 넘어가기 전에 입력 정보 저장(뒤로가기, 결제 취소 대비)
  const handleClick = async () => {
    if (!orderData || !orderId) return;
    let formData = new FormData();

    formData.append('order_key', orderId);
    formData.append('use_point', String(usePoint));
    let couponTotalPrice = useCoupon
      ? useCoupon.discount_type == 'price' //쿠폰 타입이 price이면
        ? useCoupon.discount_value // 단순 쿠폰 할인 금액을 추가하고,
        : useCoupon.discount_type == 'per' // 쿠폰 타입이 per이면
        ? Math.floor(
            orderData.total_discount_price_sum *
              (useCoupon.discount_value / 100), //쿠폰 할인 %계산해서 추가
          )
        : 0
      : 0;
    let totalPromotionPrice =
      promotionPrice !== 0
        ? promotionPrice
        : promotionPercent !== 0
        ? Math.floor(
            (orderData.total_discount_price_sum - couponTotalPrice) *
              (promotionPercent / 100),
          )
        : 0;
    if (useCoupon) {
      formData.append('use_coupon', String(useCoupon.id));
      formData.append('use_coupon_price', String(couponTotalPrice));
    }
    if (promotionPrice !== 0 || promotionPercent !== 0) {
      formData.append('use_promotion_code', promotion);
      formData.append('use_promotion_price', String(totalPromotionPrice));
    }
    // const amount =String(orderData?.sort_by_category.health_functional_discount_price_sum);
    // formData.append('amount', amount);
    // const totalAmount = String(
    //   orderData?.sort_by_category.health_functional_price_sum +
    //     orderData?.total_delivery_sum -
    //     totalDiscountPrice,
    // )
    // formData.append('total_amount_sum', totalAmount);

    formData.append('pay_method', payMethod === 0 ? 'card' : 'foreign');
    formData.append('is_app', localStorage.getItem('mobileApp') ? 'Y' : '');
    formData.append('order_step', 'health_functional');

    // let formValues: any = formData.entries();
    // for (const pair of formValues) {
    //   console.log(pair[0] + ':' + pair[1]);
    // }

    let isError = false;

    let createOrderResult = await axiosClient
      .post('/func/combined_order/continue', formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err) alert(err.response.data.detail);
        isError = true;
      });
    if (isError) {
      navigate('/basket');
    }

    window.location.href = `${createOrderResult.payurl}`;
    saveInfo();
  };

  // 구매 버튼 눌렀을 때
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const isOk = checkValidate();
    if (!isOk) {
      setIsSubmitting(false);
      return;
    }
    await handleClick();
    setIsSubmitting(false);
  }

  // 질환, 약 여부 체크
  const [orderData, setOrderData] = useState<IBasketDataType>();
  const [orderDataPresc, setOrderDataPresc] = useState<any[]>([]);
  const [orderDataHealth, setOrderDataHealth] = useState<any[]>([]);
  useEffect(() => {
    setPayModalOpen(true);

    if (!orderId) return;

    // axiosClient.get(`/data/cart?${queryString}`).then((res) => {
    axiosClient
      .get(`/data/combined_order/order-continue/${orderId}`)
      .then((res) => {
        // if (res.data.data.carts.length == 0) {
        //   alert('장바구니에 상품이 존재하지 않습니다.');
        //   navigate('/basket');
        // }
        setOrderData(res.data.data);
        setOrderDataPresc(res.data.data.sort_by_category.telemedicine);
        // try {
        //   for (let i = 0; i < res.data.data.carts.length; i++) {
        //     let needPrescription = false;
        //     for (let j = 0; j < res.data.data.carts[i].cart.length; j++) {
        //       if (res.data.data.carts[i].cart[j].product.product_id == 31)
        //         setWithoutBankbook(true); //product_id가 31인 제품 구매시엔 무통장 가능하게끔
        //       if (res.data.data.carts[i].cart[j].product.is_prescription == 1) {
        //         //처방전 필요한 주문건인지 체크
        //         needPrescription = true;
        //         setOrderDataPresc((prev) => [
        //           ...prev,
        //           res.data.data.carts[i].cart[j],
        //         ]);
        //       } else {
        //         setOrderDataHealth((prev) => [
        //           ...prev,
        //           res.data.data.carts[i].cart[j],
        //         ]);
        //       }
        //     }
        //     if (needPrescription) {
        //     }
        //   }
        // } catch (err: any) {
        //   alert(err.response.data.detail);
        // }
      });
  }, []);

  //쿠폰 데이터 받아오고, 상품들의 처방의약품, 건강식품 여부 체크하는 로직
  useEffect(() => {
    if (!orderData || !orderId) return;

    axiosClient
      .get(`/data/combined_order/coupon/useable?order_id=${orderId}`)
      .then((res) => {
        setCouponList(res.data.data.coupons);
      });
  }, [orderData, orderId]);
  //쿠폰 데이터 받아오는 부분

  function productOptionCal(product: IBasketProductType) {
    let data = { price: 0, count: 0 };
    product.items.map((option) => {
      data.price += option.discount_price * option.count;
      data.count += option.count;
    });
    return data;
  }
  useEffect(() => {
    try {
      const temporaryOrderInfo = JSON.parse(
        sessionStorage.getItem('temporaryOrderInfo') || JSON.stringify(''),
      ); //결제 페이지 넘어가기 전 저장해둔 작성 정보, 결제 페이지에서 뒤로가기를 눌러 돌아왔을 때 저장된 정보 가져와서 다시 입력해줌
      if (temporaryOrderInfo) {
        setPayMethod(temporaryOrderInfo.payMethod);
        setPayAgree(true);
        setPrivacyAgree(true);
        setDiagnosisAgree(true);
        // setTotalDiscountPrice(temporaryOrderInfo.totalDiscountPrice);
        // setPromotion(temporaryOrderInfo.promotion);
        // setPoint(temporaryOrderInfo.point);
        // setUsePoint(temporaryOrderInfo.usePoint);
        // setUseCoupon(temporaryOrderInfo.useCoupon);
      }
      // sessionStorage.removeItem('temporaryOrderInfo');
    } catch (err) {
      sessionStorage.removeItem('temporaryOrderInfo');
      alert('문제가 발생하였습니다. 다시 시도해주세요.');
      navigate('/');
    }
  }, []);
  return (
    <div
      className={`${styles.container} ${
        isDesktop ? styles.pcVersion : styles.isMobile
      }`}
    >
      <LoginHandler />
      {isDesktop && <Header />}
      {!isDesktop && (
        <div className={styles.prevWrap}>
          <img onClick={() => navigate(-1)} src={mPrev} alt="mPrev" />
        </div>
      )}
      <div className={styles.stepTitleWrap}>
        <span>결제하기</span>
        <div className={styles.stepWrap}>
          <span>결제하기</span>
          <div></div>
          <div></div>
          <div></div>
          <div className={styles.hitStep}>4</div>
        </div>
      </div>
      <div className={styles.orderForm}>
        <div className={styles.left}>
          <div className={styles.orderTitleWrap}>
            <img src={order} alt="order" />
            <span>나의결제</span>
          </div>
          <div className={styles.productListWrap}>
            <div className={styles.payBannerWrap}>
              <img src={payBanner} alt="payBanner" />
            </div>
            <div
              className={`${styles.productDropWrap} ${
                payStep === 1 ? styles.hit : ''
              }`}
              onClick={() => setIsProductOpenFirst((prev) => !prev)}
            >
              <div className={styles.productDropTitleWrap}>
                <div className={styles.payStepWrap}>첫 번째 결제</div>
                <div className={styles.categoryWrap}>
                  <span>처방의약품</span>
                  <span> {orderDataPresc.length}</span>
                </div>
                <div className={styles.priceWrap}>
                  <span className={styles.payEndSpan}>결제완료</span>
                  <img
                    style={{
                      transform: isProductOpenFirst ? 'none' : 'rotate(180deg)',
                    }}
                    src={productDropRed}
                    alt="productDrop"
                  />
                </div>
              </div>
              <div className={styles.productDropInfoWrap}>
                {isProductOpenFirst &&
                  orderDataPresc?.map((product: any) => (
                    <div
                      className={styles.productInfoWrap}
                      key={product.product.product_id}
                    >
                      <div className={styles.thumbnailWrap}>
                        <img src={product.product.list_image} alt="thumbnail" />
                      </div>
                      <div className={styles.productInfo}>
                        <h3 className={styles.productName}>
                          {product.product.title}
                        </h3>
                        <ul className={styles.optionList}>
                          {product.product.option_depth > 0 &&
                            product.items.map((option: any) => (
                              <li key={option.id}>
                                <span>
                                  {option.option1 && option.option1}
                                  {option.option2 && ' / ' + option.option2}
                                  {option.option3 && ' / ' + option.option3}
                                </span>
                                <span>{option.count}개</span>
                              </li>
                            ))}
                        </ul>
                        <div className={styles.totalInfoWrap}>
                          {(() => {
                            let res = productOptionCal(product);
                            return (
                              <>
                                <span>
                                  {res.price.toLocaleString()}원 / 총 수량{' '}
                                  {res.count}개
                                </span>
                              </>
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  ))}
                {isProductOpenFirst && (
                  <div className={styles.totalPriceWrap}>
                    <div>
                      <span>상품가격</span>
                      <span>
                        ₩{' '}
                        {orderData?.sort_by_category.telemedicine_price_sum.toLocaleString()}
                      </span>
                    </div>
                    <div>
                      <span>할인금액</span>
                      <span>
                        ₩{' '}
                        {orderData?.payment_info.telemedicine.use_point.toLocaleString()}
                      </span>
                    </div>
                    <div>
                      <span>배송비</span>
                      <span>무료</span>
                    </div>
                    <div className={styles.totalWrap}>
                      <span>총 결제 금액</span>
                      <span>
                        {orderData &&
                          (
                            orderData?.sort_by_category.telemedicine_price_sum +
                            0 -
                            orderData?.payment_info.telemedicine.use_point
                          ).toLocaleString()}
                        원
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`${styles.productDropWrap} ${
                payStep === 2 ? styles.hit : ''
              }`}
              onClick={() => setIsProductOpenSecond((prev) => !prev)}
            >
              <div className={styles.productDropTitleWrap}>
                <div className={styles.payStepWrap}>두 번째 결제</div>
                <div className={styles.categoryWrap}>
                  <span>건강식품</span>
                  <span>
                    {orderData?.sort_by_category.health_functional.length}
                  </span>
                </div>
                <div className={styles.priceWrap}>
                  <span>
                    {orderData &&
                      (
                        orderData?.sort_by_category
                          .health_functional_price_sum +
                        orderData?.total_delivery_sum -
                        totalDiscountPrice
                      ).toLocaleString()}
                    원
                  </span>
                  <img
                    style={{
                      transform: isProductOpenSecond
                        ? 'rotate(180deg)'
                        : 'none',
                    }}
                    src={productDrop}
                    alt="productDrop"
                  />
                </div>
              </div>
              <div className={styles.productDropInfoWrap}>
                {isProductOpenSecond &&
                  orderData?.sort_by_category.health_functional?.map(
                    (product: any) => (
                      <div
                        className={styles.productInfoWrap}
                        key={product.product.product_id}
                      >
                        <div className={styles.thumbnailWrap}>
                          <img
                            src={product.product.list_image}
                            alt="thumbnail"
                          />
                        </div>
                        <div className={styles.productInfo}>
                          <h3 className={styles.productName}>
                            {product.product.title}
                          </h3>
                          <ul className={styles.optionList}>
                            {product.product.option_depth > 0 &&
                              product.items.map((option: any) => (
                                <li key={option.id}>
                                  <span>
                                    {option.option1 && option.option1}
                                    {option.option2 && ' / ' + option.option2}
                                    {option.option3 && ' / ' + option.option3}
                                  </span>
                                  <span>{option.count}개</span>
                                </li>
                              ))}
                          </ul>
                          <div className={styles.totalInfoWrap}>
                            {(() => {
                              let res = productOptionCal(product);
                              return (
                                <>
                                  <span>
                                    {res.price.toLocaleString()}원 / 총 수량{' '}
                                    {res.count}개
                                  </span>
                                </>
                              );
                            })()}
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                {isProductOpenSecond && (
                  <div className={styles.totalPriceWrap}>
                    <div>
                      <span>상품가격</span>
                      <span>
                        ₩{' '}
                        {orderData?.sort_by_category.health_functional_price_sum.toLocaleString()}
                      </span>
                    </div>
                    <div>
                      <span>할인금액</span>
                      <span>
                        ₩ {<>{totalDiscountPrice.toLocaleString()}</>}
                      </span>
                    </div>
                    <div>
                      <span>배송비</span>
                      <span>
                        {orderData?.total_delivery_sum == 0
                          ? '무료'
                          : orderData?.total_delivery_sum.toLocaleString()}
                      </span>
                    </div>
                    <div className={styles.totalWrap}>
                      <span>총 결제 금액</span>
                      <span>
                        {orderData &&
                          (
                            orderData?.sort_by_category
                              .health_functional_price_sum +
                            orderData?.total_delivery_sum -
                            totalDiscountPrice
                          ).toLocaleString()}
                        원
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles.orderTitleWrap} ${styles.couponTitleWrap}`}>
            <div>
              <img src={coupon} alt="coupon" />
              <span>할인혜택</span>
            </div>
            {/* <span>할인혜택은 건강식품만 가능합니다.</span> */}
          </div>
          <div className={styles.discountInfoWrap}>
            <div className={styles.content}>
              <div className={styles.savings}>
                <div>
                  {/* {userPoint.toLocaleString()}원 */}
                  <span>적립금</span>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder={'보유 ' + userPoint.toLocaleString() + '원'}
                    value={point !== 0 ? point : ''}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      // setUsePoint(0);
                      if (isNaN(Number(e.currentTarget.value))) return;
                      if (Number(e.currentTarget.value) < 0) setPoint(0);
                      else if (Number(e.currentTarget.value) > userPoint)
                        setPoint(userPoint);
                      else {
                        setPoint(Number(e.currentTarget.value));
                      }
                    }}
                  />
                  <div onClick={pointApply}>적용</div>
                </div>
              </div>
              <div className={styles.promotion}>
                <div>
                  <span>프로모션 코드</span>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="코드를 입력해주세요."
                    value={promotion}
                    onChange={(e) => {
                      setPromotion(e.currentTarget.value);
                      setPromotionPercent(0);
                      setPromotionPrice(0);
                    }}
                  />
                  <div onClick={checkPromotion}>적용</div>
                </div>
              </div>
              <div
                className={styles.couponBtn}
                onClick={() => setCouponModalOpen(true)}
              >
                <div>
                  <span>쿠폰</span>
                </div>
                <div>
                  {
                    <span className={useCoupon ? styles.hit : ''}>
                      {!useCoupon &&
                        '보유 ' +
                          couponList.filter(
                            (coupon) =>
                              coupon.status == 'N' && coupon.is_useable == 'Y',
                          ).length +
                          '장'}
                      {useCoupon && useCoupon.coupon_name}
                    </span>
                  }
                  <img src={rightGrey} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
          {isDesktop && (
            <ul className={styles.priceGuideList}>
              <li>
                · 무이자할부가 적용되지 않은 상품과 무이자할부가 가능한 상품을
                동시에 구매할 경우 전체 주문 상품 금액에 대해 무이자할부가
                적용되지 않습니다. 무이자할부를 원하시는 경우 장바구니에서
                무이자할부 상품만 선택하여 주문하여 주시기 바랍니다.
              </li>
              <li>
                · 최소 결제 가능 금액은 결제금액에서 배송비를 제외한 금액입니다.
              </li>
            </ul>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.orderTitleWrap}>
            <img src={pay} alt="pay" />
            <span>결제수단</span>
          </div>
          <div className={styles.payMethodWrap}>
            <ul className={styles.payMethodList}>
              <li
                className={payMethod === 0 ? styles.hit : ''}
                onClick={() => setPayMethod(0)}
              >
                <div className={styles.customCheckBox}></div>
                <span>일반결제</span>
              </li>
              <li
                className={payMethod === 1 ? styles.hit : ''}
                onClick={() => setPayMethod(1)}
              >
                <div className={styles.customCheckBox}></div>
                <span>해외카드</span>
              </li>
              <p className={styles.payAlert}>다음 결제수단을 지원합니다.</p>
              {payMethod === 0 && (
                <div className={styles.payIconWrap}>
                  <div>
                    <img src={naver} alt="pay" />
                    <img src={apple} alt="pay" />
                  </div>
                  <div>
                    <img src={kakao} alt="pay" />
                    <img src={card} alt="pay" />
                  </div>
                  <div>
                    <img src={smile} alt="pay" />
                    <img src={phone} alt="pay" />
                  </div>
                  <div>
                    <img src={payco} alt="pay" />
                    <img src={bank} alt="pay" />
                  </div>
                </div>
              )}
              {payMethod === 1 && (
                <div className={styles.payIconWrap2}>
                  <img src={globalCard} alt="pay" />
                </div>
              )}
            </ul>
          </div>
          {!isDesktop && (
            <ul
              className={`${styles.priceGuideList} ${styles.mPriceGuideList}`}
            >
              <li>
                · 무이자할부가 적용되지 않은 상품과 무이자할부가 가능한 상품을
                동시에 구매할 경우 전체 주문 상품 금액에 대해 무이자할부가
                적용되지 않습니다. 무이자할부를 원하시는 경우 장바구니에서
                무이자할부 상품만 선택하여 주문하여 주시기 바랍니다.
              </li>
              <li>
                · 최소 결제 가능 금액은 결제금액에서 배송비를 제외한 금액입니다.
              </li>
            </ul>
          )}
          <ul className={styles.agreementList}>
            <li>
              <div>
                <div
                  className={`${styles.selectArea} ${
                    validation.payAgree && !payAgree
                      ? styles.validationError
                      : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={payAgree}
                    onChange={(e) => {}}
                    className={payAgree ? styles.checked : ''}
                    onClick={() => setPayAgree((prev) => !prev)}
                  />
                  <span>[필수]</span>
                  <span
                    className={styles.agreeHit}
                    onClick={() => {
                      if (isDesktop) setPayAgreeOpen((prev) => !prev);
                      else {
                        setAgreementModalOpen(true);
                        setAgreementModalType(0);
                      }
                    }}
                  >
                    구매조건 확인 및 결제진행
                  </span>
                  <span>동의</span>
                </div>
              </div>
              {payAgreeOpen && (
                <p>
                  전자상거래법8조 2항 사업자와 전자결제업자등은 전자적
                  대금지급이 이루어지는 경우 소비자가 입력한 정보가 소비자의
                  진정 의사 표시에 의한 것인지를 확인함에 있어 주의를 다하여야
                  한다.
                </p>
              )}
            </li>
            <li>
              <div>
                <div
                  className={`${styles.selectArea} ${
                    validation.privacyAgree && !privacyAgree
                      ? styles.validationError
                      : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={privacyAgree}
                    onChange={(e) => {}}
                    className={privacyAgree ? styles.checked : ''}
                    onClick={() => setPrivacyAgree((prev) => !prev)}
                  />
                  <span>[필수]</span>
                  <span
                    className={styles.agreeHit}
                    onClick={() => {
                      if (isDesktop) {
                        setPrivacyAgreeOpen((prev) => !prev);
                      } else {
                        setAgreementModalOpen(true);
                        setAgreementModalType(1);
                      }
                    }}
                  >
                    개인정보 수집 및 활용
                  </span>
                  <span>동의 안내</span>
                </div>
              </div>
              {privacyAgreeOpen && (
                <p>
                  개인정보 처리 방침에 관한 사항은 홈페이지에서 확인 가능 하시며
                  , 진료에 필요한 최소한의 정보는 동의 여부와 관계없이 수집되며
                  10년 간 보관됨을 안내 드립니다. 지금 작성해주시는 성명,
                  주민등록번호, 주소, 연락처, 진료과목 등의 정보는 진료목적으로
                  이용되며 의료법 제22조 1항 의료법 시행규칙 제14조 규정에 의해
                  동의 유무와 관계없이 수집 및 이용이 가능한 점 안내드립니다.
                </p>
              )}
            </li>
            <li>
              <div>
                <div
                  className={`${styles.selectArea} ${
                    validation.diagnosisAgree && !diagnosisAgree
                      ? styles.validationError
                      : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={diagnosisAgree}
                    onChange={(e) => {}}
                    className={diagnosisAgree ? styles.checked : ''}
                    onClick={() => setDiagnosisAgree((prev) => !prev)}
                  />
                  <span>[필수]</span>
                  <span
                    className={styles.agreeHit}
                    onClick={() => {
                      if (isDesktop) setDiagnosisAgreeOpen((prev) => !prev);
                      else {
                        setAgreementModalOpen(true);
                        setAgreementModalType(2);
                      }
                    }}
                  >
                    비대면 전화 진료
                  </span>
                  <span>동의 안내</span>
                </div>
              </div>
              {diagnosisAgreeOpen && (
                <p>
                  1. 환자는 진료 전 본의의 명의를 정확히 밝히고, 다른 사람의
                  명의로 진료를 받는 등 거짓이나 부정한 방법으로 진료를 받지
                  아니 한다. 2. 환자는 자신의 건강 관련 정보를 의료인에게 정확히
                  알려야 하며, 거짓으로 진료를 볼 경우 발생하는 문제에 대해서는
                  본인이 모든 책임을 진다. 3. 비대면 전화 진료 시, 의료진 판단
                  하에 복양의 안전을 위해 환자의 상태에 따라 처방이 불가 할 수
                  있다. 4. 환자는 약 복용 시 발현, 불편증상 등 이상 증세가 있을
                  경우 대면진료를 받아야 한다. 5. 의료진 판단 하에 대면 진료를
                  요구할 수 있으며, 환자는 특별한 사정이 없는 한 이에 응해야
                  한다. 6. 비대면 전화 진료의 장점과 주의사항에 대해 확인 및
                  이해하였다.
                </p>
              )}
            </li>
          </ul>
          <div className={styles.buyBtnArea}>
            <div
              className={`${styles.buyBtnWrap} ${
                isFixed ? styles.fixedBtn : ''
              }`}
            >
              <div className={styles.buyBtn} onClick={handleSubmit}>
                {orderData &&
                  (
                    orderData?.sort_by_category.health_functional_price_sum +
                    orderData?.total_delivery_sum -
                    totalDiscountPrice
                  ).toLocaleString()}
                원 결제하기
              </div>
            </div>
          </div>
          <div ref={buyBtnRef}></div>
        </div>
      </div>
      {agreementModalOpen && (
        <div className={styles.agreementModalContainer}>
          <div className={styles.modal}>
            <h3>
              {agreementModalType == 0
                ? '구매조건 확인 및 결제 진행 동의'
                : agreementModalType == 1
                ? '개인정보 수집 및 활용 동의'
                : agreementModalType == 2
                ? '비대면 전화 진료 동의'
                : ''}
            </h3>
            <div className={styles.content}>
              {agreementModalType == 0
                ? '전자상거래법8조 2항 사업자와 전자결제업자등은 전자적 대금지급이 이루어지는 경우 소비자가 입력한 정보가 소비자의 진정 의사 표시에 의한 것인지를 확인함에 있어 주의를 다하여야 한다.'
                : agreementModalType == 1
                ? '개인정보 처리 방침에 관한 사항은 홈페이지에서 확인 가능 하시며 , 진료에 필요한 최소한의 정보는 동의 여부와 관계없이 수집되며 10년 간 보관됨을 안내 드립니다. 지금 작성해주시는 성명, 주민등록번호, 주소, 연락처, 진료과목 등의 정보는 진료목적으로 이용되며 의료법 제22조 1항 의료법 시행규칙 제14조 규정에 의해 동의 유무와 관계없이 수집 및 이용이 가능한 점 안내드립니다.'
                : agreementModalType == 2
                ? `1. 환자는 진료 전 본의의 명의를 정확히 밝히고, 다른 사람의 명의로 진료를 받는 등 거짓이나 부정한 방법으로 진료를 받지 아니 한다.
                2. 환자는 자신의 건강 관련 정보를 의료인에게 정확히 알려야 하며, 거짓으로 진료를 볼 경우 발생하는 문제에 대해서는 본인이 모든 책임을 진다.
                3. 비대면 전화 진료 시, 의료진 판단 하에 복양의 안전을 위해 환자의 상태에 따라 처방이 불가 할 수 있다.
                4. 환자는 약 복용 시 발현, 불편증상 등 이상 증세가 있을 경우 대면진료를 받아야 한다.
                5. 의료진 판단 하에 대면 진료를 요구할 수 있으며, 환자는 특별한 사정이 없는 한 이에 응해야 한다.
                6. 비대면 전화 진료의 장점과 주의사항에 대해 확인 및 이해하였다.`
                : ''}
            </div>
            <div
              className={styles.submit}
              onClick={() => setAgreementModalOpen(false)}
            >
              확인
            </div>
          </div>
        </div>
      )}
      {couponModalOpen && (
        <CouponModal
          setOpen={setCouponModalOpen}
          couponList={couponList}
          useCoupon={useCoupon}
          setUseCoupon={setUseCoupon}
        />
      )}
      {payModalOpen && <PayModal closer={setPayModalOpen} />}
    </div>
  );
}
export default OrderContinue;
