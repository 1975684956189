import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleRedirectUrlCheck } from '../common/redirectUrlCheck';
import axiosClient from '../libs/axiosClient';
import { setToken, STORAGE_ITEMS } from './auth';
const KAKAO_REST_API_KEY = '021e568247aad459dfff5f66fc6cc2f0';
const KAKAO_REDIRECT_URI = `${window.location.origin}/auth/kakao/callback`;
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;
function Kakao() {
  const navigate = useNavigate();
  useEffect(() => {
    let params = new URL(document.URL).searchParams;
    let code = params.get('code');
    const localKey = 'refCode';
    const localGetRefCode = localStorage.getItem(localKey);
    let refCode = '';
    const referrerRegex = /^(?=.*\d)(?=.*[A-Z]).*$/;
    if (localGetRefCode) {
      const parsedData = JSON.parse(localGetRefCode);
      if (referrerRegex.test(parsedData.refCodeUrl))
        refCode = parsedData.refCodeUrl;
    }

    axiosClient
      .post(
        `/func/user/login/kakao?referrer_code=${refCode}`,
        // '/func/user/login/kakao',
        {
          code: code,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
            'ngrok-skip-browser-warning': true,
          },
          withCredentials: true,
        },
      )
      .then((res) => {
        setToken(res.data);
        window.localStorage.removeItem('refCode');
        let rurl = localStorage.getItem('rurl');
        if (handleRedirectUrlCheck(rurl || '/'))
          window.location.href = rurl || '/';
        else window.location.href = '/';
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.detail)
          alert(err.response.data.detail);
        window.location.href = '/login';
      });
  }, []);
  return <div></div>;
}
export default Kakao;
