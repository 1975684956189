import { useRef } from 'react';

interface UseModalSwipeProps {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}

const useMobileSlide = ({ closer }: UseModalSwipeProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const startYRef = useRef<number>(0);
  const currentYRef = useRef<number>(0);

  const openModal = () => {
    if (modalRef.current) modalRef.current.style.transform = 'translateY(0)';
  };

  const closeModal = () => {
    closer(false);
    if (modalRef.current) modalRef.current.style.transform = 'translateY(100%)';
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    startYRef.current = event.touches[0].clientY;
    currentYRef.current = parseFloat(
      getComputedStyle(modalRef.current as Element).transform.split(',')[5] ||
        '0',
    );
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches[0].clientY < startYRef.current) return;
    const diffY = event.touches[0].clientY - startYRef.current;
    if (modalRef.current) {
      modalRef.current.style.transform = `translateY(${
        currentYRef.current + diffY
      }px)`;
    }
  };

  const handleTouchEnd = () => {
    const modalHeight = modalRef.current?.clientHeight || 0;
    const currentTranslateY = parseFloat(
      getComputedStyle(modalRef.current as Element).transform.split(',')[5],
    );

    if (Math.abs(currentTranslateY) > modalHeight / 3) {
      closeModal();
    } else {
      openModal();
    }
  };

  return { modalRef, handleTouchStart, handleTouchMove, handleTouchEnd };
};

export default useMobileSlide;
