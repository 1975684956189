import Header from '../../components/Header/Header';
import styles from './Basket.module.scss';
import leftArrow from '../../assets/leftArrow02.png';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import checkMark from '../../assets/checkMark.png';
import { loginCheck } from '../../auth/auth';
import { loginConfirm } from '../../common/loginConfirm';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
export interface IBasketProductType {
  items: {
    cart_saved_price: number;
    count: number;
    created_at: string;
    discount_price: number;
    id: number;
    option1: string | null;
    option2: string | null;
    option3: string | null;
    option_id: number;
    price: number;
    product_code: string;
    stock_count: number;
    user_id: number;
  }[];
  product: {
    brand_id: null | string;
    brand_image: null | string;
    brand_name: null | string;
    cp_delivery_freeprice: number;
    cp_delivery_price: number;
    cp_delivery_use: string;
    delivery_case_price: number;
    delivery_cost: number;
    delivery_description: string;
    delivery_price: number;
    delivery_type: string;
    detail_url: string;
    discount_price: number;
    discount_rate: number;
    hashtag: string;
    id: string;
    image: string;
    is_like: number;
    is_prescription: number;
    list_image: string;
    option_depth: number;
    p_shoppingPayFree: number;
    p_shoppingPayPdPrice: number;
    p_shoppingPayPfPrice: number;
    price: number;
    product_id: number;
    review_score_avg: {
      data: {
        review_point_0_avg: number;
        review_point_1_avg: number;
        review_point_2_avg: number;
        review_point_3_avg: number;
        review_point_total_avg: number;
        review_total_count: number;
      };
    };
    soldout: string;
    stock_count: number;
    title: string;
  };
  product_delivery_sum: number;
  product_discount_price_sum: number;
  product_price_sum: number;
}
export interface IBasketDataType {
  carts: {
    cart: IBasketProductType[];
    company_delivery_sum: number;
    company_discount_price_sum: number;
    company_id: string;
    company_image: string | null;
    company_name: string;
    company_price_sum: number;
  }[];
  payment_info: {
    telemedicine: {
      use_point: number;
    };
  };
  sort_by_category: {
    telemedicine: IBasketProductType[];
    health_functional: IBasketProductType[];
    telemedicine_price_sum: number;
    telemedicine_discount_price_sum: number;
    health_functional_price_sum: number;
    health_functional_discount_price_sum: number;
    delivery_price_sum: number;
  };
  total_delivery_sum: number;
  total_discount_price_sum: number;
  total_price_sum: number;
}
interface ITotalPriceType {
  total_delivery_sum: number;
  total_discount_price_sum: number;
  total_price_sum: number;
}
function Basket() {
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [allProduct, setAllProduct] = useState<number[]>([]);
  // const { data: basketList } = useQuery<IBasketDataType>(
  //   'basketList',
  //   () =>
  //     axiosClient
  //       .get('/data/cart', { withCredentials: true })
  //       .then((res) => res.data.data),
  //   { refetchOnWindowFocus: false, retry: 0 },
  // );
  const [basketList, setBasketList] = useState<IBasketDataType>();

  const [totalPriceInfo, setTotalPriceInfo] = useState<ITotalPriceType>({
    total_delivery_sum: 0,
    total_discount_price_sum: 0,
    total_price_sum: 0,
  });
  useEffect(() => {
    axiosClient
      .get('/data/cart', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
        },
        withCredentials: true,
      })
      .then((res) => setBasketList(res.data.data));
  }, []);
  useEffect(() => {
    if (!basketList) return;
    if (!checkedItems || checkedItems.length == 0) {
      setTotalPriceInfo({
        total_discount_price_sum: 0,
        total_delivery_sum: 0,
        total_price_sum: 0,
      });
      return;
    }
    let queryString = '';
    basketList.carts.map((company) =>
      company.cart.map((product) => {
        if (checkedItems.includes(product.product.product_id)) {
          product.items.map(
            (option) => (queryString += `cart_ids=${option.id}&`),
          );
        }
      }),
    );
    axiosClient
      .get(`/data/cart?${queryString}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
        },
        withCredentials: true,
      })
      .then((res) => {
        setTotalPriceInfo({
          total_delivery_sum: res.data.data.total_delivery_sum,
          total_discount_price_sum: res.data.data.total_discount_price_sum,
          total_price_sum: res.data.data.total_price_sum,
        });
      });
  }, [checkedItems]);
  useEffect(() => {
    if (!basketList) return;
    const newAllProduct: number[] = [];
    basketList.carts.map((company) =>
      company.cart.map((product) => {
        newAllProduct.push(product.product.product_id);
      }),
    );
    setAllProduct([...newAllProduct]);
  }, [basketList]);

  //스크롤 위치에 따른 구매하기 버튼 fixed처리
  const buyBtnRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(true);
  useEffect(() => {
    if (!buyBtnRef.current) return;
    const io = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting || isDesktop) setIsFixed(false);
      else setIsFixed(true);
    });
    io.observe(buyBtnRef.current);
    return () => io.disconnect();
  }, [isFixed]);
  //스크롤 위치에 따른 구매하기 버튼 fixed처리
  function handleCheck(clickedProduct: IBasketProductType) {
    let list = checkedItems;
    let isInclude = false;
    list.map((productId) => {
      if (productId == clickedProduct.product.product_id) isInclude = true;
    });
    if (isInclude)
      list = list.filter(
        (productId) => productId !== clickedProduct.product.product_id,
      );
    else list.push(clickedProduct.product.product_id);
    setCheckedItems([...list]);
  }
  function handleAllCheck() {
    if (allProduct?.length == checkedItems?.length) setCheckedItems([]);
    else setCheckedItems([...allProduct]);
  }
  function handleDelete() {
    if (!basketList) return;
    if (checkedItems.length == 0) {
      alert('삭제할 상품을 선택해주세요.');
      return;
    }
    let cartIds: number[] = [];
    basketList.carts.map((company) => {
      company.cart.map((product) => {
        product.items.map((option) => {
          if (checkedItems.includes(product.product.product_id))
            cartIds.push(option.id);
        });
      });
    });
    let formData = new FormData();
    for (let i = 0; i < cartIds.length; i++)
      formData.append('cart_ids', String(cartIds[i]));
    axiosClient.delete(`/func/cart`, {
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
      },
      withCredentials: true,
    });
    let newBasketList = basketList;
    newBasketList.carts = newBasketList.carts.filter((company) => {
      company.cart = company.cart.filter((product) => {
        return !checkedItems.includes(product.product.product_id);
      });
      return company.cart.length > 0;
    });
    setCheckedItems([]);
    setBasketList({
      ...newBasketList,
      total_delivery_sum: 0,
      total_discount_price_sum: 0,
      total_price_sum: 0,
    });
    setTotalPriceInfo({
      total_delivery_sum: 0,
      total_discount_price_sum: 0,
      total_price_sum: 0,
    });
  }
  function handleBuy() {
    if (!basketList) return;
    if (!loginConfirm()) return;
    if (!checkedItems.length) {
      alert('선택된 상품이 없습니다.');
      return;
    }
    let idList: number[] = [];
    let needPrescription = false;
    basketList.carts.map((company) => {
      company.cart.map((product) => {
        product.items.map((option) => {
          if (checkedItems.includes(product.product.product_id)) {
            idList.push(option.id);
            if (product.product.is_prescription == 1) needPrescription = true;
          }
        });
      });
    });
    let queryString = JSON.stringify(idList);
    // if (needPrescription) navigate(`/selectTreatmentMethod/N/${queryString}`);
    if (needPrescription) navigate(`/questionnaire/N/${queryString}`);
    else
      navigate(`/order-addr/N/${queryString}`, {
        state: {
          onlyHealth: true,
        },
      });
  }
  function productOptionCal(product: IBasketProductType) {
    let data = { price: 0, count: 0 };
    product.items.map((option) => {
      data.price += option.discount_price * option.count;
      data.count += option.count;
    });
    return data;
  }
  return (
    <div>
      <Header />
      <div className={styles.container}>
        {isDesktop && <MyPageNavigate />}
        <h3 className={styles.title}>
          {!isDesktop && (
            <img src={leftArrow} alt="arrow" onClick={() => navigate(-1)} />
          )}
          <span>장바구니</span>
        </h3>
        <div className={styles.selectWrap}>
          <div className={styles.selectBtn} onClick={handleAllCheck}>
            <div
              className={`${styles.customCheckbox} ${
                allProduct?.length == checkedItems.length &&
                allProduct.length !== 0
                  ? styles.checked
                  : ''
              }`}
            >
              <img src={checkMark} alt="checkMark" />
            </div>
            <span>모두선택</span>
          </div>
          <div className={styles.deleteBtn} onClick={handleDelete}>
            선택삭제
          </div>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.left}>
            <ul className={styles.productListWrap}>
              {basketList &&
                basketList.carts.map((company) => (
                  <>
                    {company.cart.map((product) => (
                      <li key={product.product.product_id}>
                        <div className={styles.productContainer}>
                          <div
                            className={styles.checkBoxWrap}
                            onClick={() => handleCheck(product)}
                          >
                            <div
                              className={`${styles.customCheckbox} ${
                                checkedItems.includes(
                                  product.product.product_id,
                                )
                                  ? styles.checked
                                  : ''
                              }`}
                            >
                              <img src={checkMark} alt="checkMark" />
                            </div>
                          </div>
                          <div className={styles.productInfoWrap}>
                            <div
                              className={styles.productImageWrap}
                              onClick={() =>
                                navigate(
                                  `/productDetail/${product.product.product_id}`,
                                )
                              }
                            >
                              <img
                                src={product.product.list_image}
                                alt="thumbnail"
                              />
                            </div>
                            <div className={styles.productInfo}>
                              <h4 className={styles.productName}>
                                {product.product.title}
                              </h4>
                              {product.product.option_depth > 0 &&
                                product.items.map((option) => (
                                  <div className={styles.optionWrap}>
                                    <span className={styles.option}>
                                      {option.option1 && option.option1}
                                      {option.option2 && ' / ' + option.option2}
                                      {option.option3 && ' / ' + option.option3}
                                    </span>
                                    <span>수량 {option.count}개</span>
                                  </div>
                                ))}
                              <div className={styles.totalWrap}>
                                {(() => {
                                  let res = productOptionCal(product);
                                  return (
                                    <>
                                      <span>
                                        {res.price.toLocaleString()}원
                                      </span>
                                      <span>수량 {res.count}개</span>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                          <div className={styles.deliveryPriceWrap}>
                            {product.product_delivery_sum !== 0
                              ? product.product_delivery_sum.toLocaleString()
                              : '배송비 무료'}
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                ))}
            </ul>
          </div>
          <div className={styles.right}>
            <div className={styles.totalPriceInfoWrap}>
              <h4>총 결제 금액</h4>
              <div className={styles.productPrice}>
                <span>상품가격</span>
                <span>₩ {totalPriceInfo.total_price_sum.toLocaleString()}</span>
              </div>
              <div className={styles.discountPrice}>
                <span>할인금액</span>
                <span>
                  ₩{' '}
                  {(
                    totalPriceInfo.total_price_sum -
                    totalPriceInfo.total_discount_price_sum
                  ).toLocaleString()}
                </span>
              </div>
              <div className={styles.deliveryPrice}>
                <span>배송비</span>
                <span>
                  {totalPriceInfo.total_delivery_sum !== 0
                    ? `₩ ${totalPriceInfo.total_delivery_sum.toLocaleString()}`
                    : '무료'}
                </span>
              </div>
              <div className={styles.totalPriceWrap}>
                <span>총 결제 금액</span>
                <span>
                  {(
                    totalPriceInfo?.total_discount_price_sum +
                    totalPriceInfo?.total_delivery_sum
                  ).toLocaleString()}
                  원
                </span>
              </div>
            </div>
            <div className={styles.buyBtnArea}>
              <div
                className={`${styles.buyBtnWrap} ${
                  isFixed ? styles.fixedBtn : ''
                }`}
              >
                <div className={styles.buyBtn} onClick={handleBuy}>
                  즉시 구매 하기
                </div>
              </div>
            </div>
            <div ref={buyBtnRef}></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Basket;
